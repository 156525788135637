/*--------------------------------------------

Template Name: Metro | Responsive HTML5 Template
Template URI: https://radiustheme.com/
Description: Metro | Responsive HTML5 Template which is used for eCommerce business.
Author: Radius Theme
Author URI: https://radiustheme.com/
Version: 1.0

===================   
CSS INDEX
===================
1. Theme Default CSS 
  1.1 Section Title styles
  1.2 Button styles
  1.3 Search Box styles
  1.4 Owl Carousel Common styles
  1.5 ScrollUp styles
  1.6 Preloader styles
  1.7 Others styles
2. Header Area 
  2.1 Header Commomn Area styles
  2.2 Home1 Header Area styles
  2.3 Home2 Header Area styles 
  2.4 Home3 Header Area styles
  2.5 Home4 Header Area styles 
  2.6 Home5 Header Area styles
  2.7 Home6 Header Area styles
  2.8 Home7 Header Area styles
  2.9 Home8 Header Area styles
  2.10 Home9 Header Area style
  2.11 Home10 Header Area styles 
  2.12 Offcanvas Menu Area style
  2.13 Stick Menu Area styles
  2.14 Mobile Menu Area styles
3. Slider Area
  3.1 Slider 1 styles
  3.2 Slider 2 styles
  3.3 Slider 3 styles
  3.4 Slider 4 styles
  3.5 Slider 5 styles
  3.6 Slider 6 styles
  3.7 Slider 7 styles
4. Inner Page Bennar Area styles
5. Pagination styles
6. About Us Page Area styles
7. Services Area
  7.1 Services1 Area styles
  7.2 Services2 Area styles
  7.3 Services3 Area styles
  7.4 Services4 Area styles
  7.5 Services5 Area styles
  7.6 Services6 Area styles
  7.7 Services7 Area styles
  7.8 Services8 Area styles
  7.9 Services9 Area styles
8. Brand Area styles
9. Offer Area
  9.1 Offer1 Area styles
  9.2 Offer2 Area styles
10. Isotop Area
  10.1 My Isotop1 Area styles
  10.2 My Isotop2 Area styles
  10.3 Isotop Button styles
11. Expert Team Area styles
12. Advantage Area
  12.1 Advantage1 Area styles
  12.2 Advantage2 Area styles 
  12.3 Advantage3 Area styles
  12.4 Advantage4 Area styles
  12.5 Advantage5 Area styles
  12.6 Advantage6 Area styles
  12.7 Advantage7 Area styles
  12.8 Advantage8 Area styles
  12.9 Advantage9 Area styles
  12.10 Advantage10 Area styles
13. Best Seller Area styles
14. Blog Box Area 
  14.1 Blog Box Common Area styles
  14.2 Blog1 Box Area styles
  14.3 Blog2 Box Area styles
  14.4 Blog3 Box Area styles
15. Blog Page Area styles   
16. Single Blog Page Area styles
17. Portfolio Page Area
  17.1 Portfolio1 Page Area styles
  17.2 Portfolio2 Page Area styles
18. Featured Area 
  18.1 Featured Area styles
  18.2 Featured 2 Area styles
19. Shop Area
  19.1 Shop Common styles
  19.2 Shop Product Box1 styles
  19.3 Shop Product Box2 styles
  19.4 Shop Product Box3 styles
  19.5 Shop Product Box4 styles
  19.6 Shop Product Box5 styles 
20. Product Details Page Area
  20.1 Product Details1 Page Area styles
  20.2 Product Details2 Page Area styles
21. Sidebar Area
  21.1 Sidebar Banner Area styles
  21.2 Sidebar Search Area styles
  21.3 Sidebar Archives Area styles
  21.4 Sidebar Recent Posts Area styles
  21.5 Sidebar Best Products Area styles
  21.6 Sidebar Filter By Price Area styles
  21.7 Sidebar Product Tags Area styles
22. Login Registration Page Area styles
23. Checkout Page Area styles
24. Cart Page Area styles
25. Order History Page Area styles
26. Order Details Page Area styles
27. My Account Page Area styles 
28. Contact Page Area styles 
29. 404 Error Page Area styles
30. Footer Area styles 
31. Modal Dialog Area styles 
32. Switch Styling Area styles
33. Metro Banner Area styles
34. Countdown Area styles
35. Section Space Area styles

===================   
End CSS INDEX
===================
--------------------------------------------*/
/*=====================================
1. Theme Default CSS  
=======================================*/
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuhs.ttf)
      format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf)
      format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUuhs.ttf)
      format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhs.ttf)
      format("truetype");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/raleway/v17/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEorCIPrQ.ttf)
    format("truetype");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/raleway/v17/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrQ.ttf)
    format("truetype");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/raleway/v17/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoorCIPrQ.ttf)
    format("truetype");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/raleway/v17/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpbCIPrQ.ttf)
    format("truetype");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/raleway/v17/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pbCIPrQ.ttf)
    format("truetype");
}
html,
body {
  height: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  vertical-align: baseline;
  background: #ffffff;
  color: #333333;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  line-height: 1.5;
  font-weight: 600;
  margin: 0 0 20px 0;
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 20px;
}
p {
  margin: 0 0 24px;
}
a {
  text-decoration: none;
}
a:active,
a:hover,
a:focus {
  text-decoration: none;
  outline: 0 none;
}
.btn:active,
.btn:hover,
.btn:focus {
  outline: 0 none;
}
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}
.align-items-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.d-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.border-radius45 {
  border-radius: 45px;
}
img {
  max-width: 100%;
  height: auto;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.clear:after {
  clear: both;
  content: "";
  display: block;
}
.position-static {
  position: static;
}
.sidebar-section-margin {
  margin-bottom: 40px;
}
.inner-section-space-top {
  padding: 30px 0 0 0;
}
@media only screen and (max-width: 991px) {
  .hidden-after-desk {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .hidden-after-tab {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .hidden-before-tab {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .padding-top-0-after-desk {
    padding-top: 0 !important;
  }
}
.solid-divider {
  position: relative;
}
.solid-divider:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background: #b6b6b6;
  left: 0;
  bottom: 0;
}
.solid-underline {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.solid-underline:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background: #b6b6b6;
  left: 0;
  bottom: 0;
}
.solid-underline-comments {
  position: relative;
  padding-bottom: 38px;
  margin-bottom: 46px;
}
.solid-underline-comments:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background: #b6b6b6;
  left: 0;
  bottom: 0;
}
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-15 {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}
.wrapper-area {
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  header {
    display: none;
  }
}
/*----------------------------------------
1.1 Section Title styles
----------------------------------------*/
.section-title {
  text-align: center;
}
.section-title h2 {
  margin-bottom: 30px;
  letter-spacing: 2px;
  color: #212121;
  text-transform: uppercase;
  display: inline-block;
}
@media only screen and (max-width: 479px) {
  .section-title h2 {
    font-size: 24px;
    letter-spacing: 1px;
  }
}
.section-title .title-bar-left {
  position: relative;
}
@media only screen and (max-width: 479px) {
  .section-title .title-bar-left {
    display: none;
  }
}
.section-title .title-bar-left:before {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b6b6b6;
  border-top: 1px solid #b6b6b6;
  height: 4px;
  background: transparent;
  width: 236px;
  top: 0;
  right: 20px;
  z-index: 1;
}
.section-title .title-bar-right {
  position: relative;
}
@media only screen and (max-width: 479px) {
  .section-title .title-bar-right {
    display: none;
  }
}
.section-title .title-bar-right:before {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b6b6b6;
  border-top: 1px solid #b6b6b6;
  height: 4px;
  background: transparent;
  width: 236px;
  top: 0;
  left: 20px;
  z-index: 1;
}
.title-carousel {
  font-size: 24px;
  letter-spacing: 1px;
  color: #212121;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 30px;
}
@media only screen and (max-width: 479px) {
  .title-carousel {
    text-align: center;
  }
}
.title-carousel:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b6b6b6;
  border-top: 1px solid #b6b6b6;
  height: 4px;
  background: transparent;
  width: 62%;
  right: 9%;
  top: 45%;
  z-index: 1;
}
@media only screen and (max-width: 1199px) {
  .title-carousel:after {
    display: none;
  }
}
.title-carousel2 {
  font-size: 24px;
  color: #212121;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 45px;
}
.title-carousel2:after {
  content: "";
  position: absolute;
  height: 4px;
  background: transparent;
  width: 44px;
  bottom: -15px;
  left: 0;
  z-index: 1;
  background-color: #111111;
}
.title-carousel3 {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 45px;
}
.title-carousel3:after {
  content: "";
  position: absolute;
  height: 4px;
  background: transparent;
  width: 44px;
  bottom: -15px;
  left: 0;
  z-index: 1;
  background-color: #111111;
}
.title-carousel4 {
  font-size: 24px;
  color: #212121;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 45px;
  margin-top: -10px;
}
.title-carousel4:after {
  content: "";
  position: absolute;
  height: 4px;
  background: transparent;
  width: 44px;
  bottom: -15px;
  left: 0;
  z-index: 1;
  background-color: #111111;
}
.title-carousel5 {
  font-size: 24px;
  color: #212121;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 45px;
  margin-top: -10px;
  font-weight: 700;
}
.title-carousel5:after {
  content: "";
  position: absolute;
  height: 4px;
  background: transparent;
  width: 44px;
  bottom: -15px;
  left: 0;
  z-index: 1;
}
.title-carousel6 {
  font-size: 24px;
  color: #212121;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 45px;
  margin-top: -10px;
  font-weight: 700;
  top: 6px;
}
.title-carousel6:after {
  content: "";
  position: absolute;
  height: 4px;
  background: transparent;
  width: 44px;
  bottom: -15px;
  left: 0;
  z-index: 1;
}
.bar-bg-malachite:after {
  background-color: #00c853;
}
.bar-bg-blaze-orange:after {
  background-color: #ff6600;
}
.bar-bg-dodger-blue:after {
  background-color: #2962ff;
}
.bar-bg-coral-red:after {
  background-color: #f93940;
}
.bar-bg-bright-sun2:after {
  background-color: #ff6600;
}
.bar-bg-amber:after {
  background-color: #ffc400;
}
.bar-bg-buddha-gold:after {
  background-color: #cf9900;
}
.title-sidebar {
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 22px;
  color: #212121;
  margin-bottom: 30px;
}
.cart-area-title {
  font-size: 24px;
  color: #4154f1;
  letter-spacing: 1px;
  text-transform: uppercase;
}
/*----------------------------------------
1.2 Button styles
----------------------------------------*/
.btn-shop-now {
  position: relative;
  background: #111111;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 2px;
  display: block;
  padding: 6px 20px;
  border: 1px solid #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now i {
  padding-left: 10px;
  font-size: 14px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now:hover {
  color: #111111;
  background: transparent;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-light {
  position: relative;
  background: #ffffff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #212121;
  letter-spacing: 2px;
  display: block;
  padding: 10px 20px;
  border: 1px solid #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-light i {
  padding-left: 10px;
  font-size: 14px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-light:hover {
  color: #ffffff;
  background: transparent;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-light2 {
  position: relative;
  background: #ffffff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #212121;
  letter-spacing: 2px;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-light2 i {
  padding-left: 10px;
  font-size: 14px;
}
.btn-shop-now-light2:hover {
  color: #ffffff;
  background: transparent;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-light3 {
  position: relative;
  background: #f93940;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 2px;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #f93940;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-light3 i {
  padding-left: 10px;
  font-size: 14px;
}
.btn-shop-now-light3:hover {
  color: #f93940;
  background: transparent;
}
.btn-shop-now-light4 {
  position: relative;
  background: #304ffe;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 2px;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #304ffe;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  border-radius: 45px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-light4 i {
  padding-left: 10px;
  font-size: 14px;
}
.btn-shop-now-light4:hover {
  color: #304ffe;
  background: transparent;
}
.btn-shop-now-light5 {
  position: relative;
  background: transparent;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #304ffe;
  letter-spacing: 2px;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #304ffe;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  border-radius: 45px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-light5 i {
  padding-left: 10px;
  font-size: 14px;
}
.btn-shop-now-light5:hover {
  color: #ffffff;
  background: #304ffe;
}
.btn-shop-now-light6 {
  position: relative;
  background: #ff6600;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  padding: 8px 20px;
  border: 1px solid #ff6600;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-light6 i {
  padding-left: 10px;
  font-size: 14px;
}
.btn-shop-now-light6:hover {
  color: #ff6600;
  background: transparent;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-fill-slider {
  background: #111111;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff !important;
  letter-spacing: 4px;
  display: inline-block;
  padding: 12px 45px;
  border: 1px solid #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 1199px) {
  .btn-shop-now-fill-slider {
    padding: 10px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .btn-shop-now-fill-slider {
    padding: 8px 25px;
  }
}
.btn-shop-now-fill-slider:hover {
  background: #070707;
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-ghost-slider {
  background: transparent !important;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 4px;
  display: inline-block;
  padding: 13px 40px;
  border: 2px solid #ffffff !important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-ghost-slider:hover {
  background: #ffffff !important;
  color: #212121 !important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-shop-now-fill {
  background: #111111;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 4px;
  display: inline-block;
  padding: 10px 30px;
  border: 1px solid #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 1199px) {
  .btn-shop-now-fill {
    padding: 10px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .btn-shop-now-fill {
    padding: 8px 25px;
  }
}
.btn-shop-now-fill:hover {
  background: transparent;
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-add-to-cart-fill {
  background: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #111111;
  letter-spacing: 3px;
  display: inline-block;
  padding: 12px 30px;
  border: 1px solid #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-add-to-cart-fill:hover {
  color: #ffffff;
  background: #111111;
  border: 1px solid #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-add-to-cart-fill-box4 {
  background: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #111111;
  letter-spacing: 2px;
  display: inline-block;
  padding: 12px 30px;
  border: 1px solid #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 767px) {
  .btn-add-to-cart-fill-box4 {
    padding: 5px 12px;
    letter-spacing: 1px;
  }
}
@media only screen and (max-width: 320px) {
  .btn-add-to-cart-fill-box4 {
    padding: 1px 8px 0;
    letter-spacing: 0;
    font-size: 10px;
  }
}
.btn-add-to-cart-fill-box4:hover {
  color: #ffffff;
  background: #111111;
  border: 1px solid #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-add-to-cart-fill-box3 {
  background: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #111111;
  letter-spacing: 2px;
  display: inline-block;
  padding: 12px 30px;
  border: 1px solid #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .btn-add-to-cart-fill-box3 {
    padding: 5px 12px;
    letter-spacing: 1px;
  }
}
.btn-add-to-cart-fill-box3:hover {
  color: #ffffff;
  background: #111111;
  border: 1px solid #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-add-to-cart-fill-black {
  background: #111111;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 3px;
  display: inline-block;
  padding: 12px 30px;
  border: 1px solid #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-add-to-cart-fill-black:hover {
  color: #4154f1;
  background: #ffffff;
  border: 1px solid #4154f1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-already-user {
  background: #e7e7ee;
  font-size: 14px;
  /* text-transform: uppercase; */
  font-weight: 600;
  color: #161616;
  letter-spacing: 2px;
  display: inline-block;
  padding: 12px 40px;
  border: 1px solid #e7e7ee;
  transition: all 0.3s ease-out;
  border-radius: 10px;
  line-height: 1;
}
.btn-already-user:hover {
  background: #dedee0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 10px;
  line-height: 1;
}
.btn-send-message {
  background: #4154f1;
  font-size: 14px;
  /* text-transform: uppercase; */
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 2px;
  display: inline-block;
  padding: 12px 40px;
  border: 1px solid #4154f1;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 10px;
  line-height: 1;
}
.btn-send-message:hover {
  color: #4154f1;
  background: transparent;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 10px;
  line-height: 1;
}
.btn-side-nav {
  margin-top: 20px;
  background: #111111;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 2px;
  display: inline-block;
  padding: 7px 30px;
  border: 1px solid #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.btn-side-nav:hover {
  color: #ffffff;
  background: #070707;
  border: 1px solid #070707;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.btn-services-shop-now {
  border: 1px solid #111111;
  background: #111111;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 2px;
  display: inline-block;
  padding: 8px 30px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
@media only screen and (max-width: 479px) {
  .btn-services-shop-now {
    padding: 8px 20px;
  }
}
@media only screen and (max-width: 320px) {
  .btn-services-shop-now {
    padding: 5px 10px;
    font-size: 12px;
  }
}
.btn-services-shop-now:hover {
  color: #111111;
  background: transparent;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.btn-apply-coupon {
  background: #111111;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 2px;
  display: inline-block;
  padding: 12px 20px;
  border: 1px solid #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
@media only screen and (max-width: 320px) {
  .btn-apply-coupon {
    font-size: 12px;
  }
}
.btn-apply-coupon:hover {
  color: #111111;
  background: transparent;
  border: 1px solid #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.btn-checkout {
  background: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #070707;
  letter-spacing: 2px;
  display: inline-block;
  padding: 5px 25px;
  border: 1px solid #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-checkout i {
  margin-right: 10px;
}
.btn-checkout:hover {
  color: #ffffff;
  background: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-error {
  background: #111111;
  padding: 12px 33px;
  color: #ffffff;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 30px;
  border: 1px solid #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.btn-error:hover {
  background: #f1f1f1;
  color: #070707;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
/*-------------------------------------
1.3 Search Box styles
--------------------------------------*/
.stylish-input-group {
  border: 1px solid #b6b6b6;
  padding: 10px;
}
.stylish-input-group .form-control {
  border: none;
  box-shadow: 0 0 0;
  border-radius: 0;
  background: transparent;
  text-transform: uppercase;
  color: #111111;
}
.stylish-input-group .input-group-addon {
  padding: 6px;
  background: transparent !important;
  border: none;
  border-radius: 0;
}
.stylish-input-group .input-group-addon button {
  border: 0;
  background: transparent;
}
.stylish-input-group .input-group-addon button span {
  color: #727272;
}
.header-search {
  line-height: 0;
}
.header-search .search-input {
  position: absolute;
  top: -15px;
  right: 40px;
  z-index: 10;
  height: 50px;
  width: 604px;
  background: rgba(17, 17, 17, 0.95);
  border: 1px solid #111111;
  padding: 5px 30px 8px;
  color: #ffffff;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  border-radius: 45px;
  display: none;
}
.header-search input.search-input:focus {
  outline-width: 0;
  outline: none;
}
.header-search input::-webkit-input-placeholder,
.header-search textarea::-webkit-input-placeholder {
  color: #ffffff;
}
.header-search input::-moz-placeholder,
.header-search textarea::-moz-placeholder {
  color: #ffffff;
}
.header-search input:-moz-placeholder,
.header-search textarea:-moz-placeholder {
  color: #ffffff;
}
.header-search input:-ms-input-placeholder,
.header-search textarea:-ms-input-placeholder {
  color: #ffffff;
}
/*----------------------------------------
1.4 Owl Carousel Common styles
----------------------------------------*/
.owl-controls {
  margin-top: 0px !important;
}
.owl-controls .owl-nav {
  position: absolute;
  top: -74px;
  z-index: 2;
  right: 0;
}
@media only screen and (max-width: 479px) {
  .owl-controls .owl-nav {
    top: inherit;
    right: inherit;
    position: inherit;
  }
}
.owl-controls .owl-nav div {
  line-height: 1.2;
  height: 30px !important;
  width: 35px !important;
  border: 1px solid #111111;
  background: #111111 !important;
  border-radius: 0 !important;
  opacity: 1 !important;
  font-size: 18px !important;
  padding: 2px 10px !important;
  margin: 0 2px !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.owl-controls .owl-nav div i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.owl-controls .owl-nav div:hover {
  background: transparent !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.owl-controls .owl-nav div:hover i {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
@media only screen and (max-width: 479px) {
  .owl-controls .owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    left: -15%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 479px) {
  .owl-controls .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    right: -15%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 991px) {
  .owl-controls .owl-pagination {
    width: 80px;
    display: inline-flex;
    overflow: hidden;
  }
}
.owl-controls .owl-pagination span {
  background: #c2c2c2 !important;
}
.coral-red .owl-controls .owl-nav div {
  border: 1px solid #f93940;
  background: #f93940 !important;
}
.coral-red .owl-controls .owl-nav div i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.coral-red .owl-controls .owl-nav div:hover {
  background: transparent !important;
}
.coral-red .owl-controls .owl-nav div:hover i {
  color: #f93940;
}
.nav-control-round .owl-controls {
  margin-top: 0px !important;
}
.nav-control-round .owl-controls .owl-nav {
  margin-top: 0px !important;
  position: absolute;
  top: -74px;
  z-index: 2;
  right: 0;
}
@media only screen and (max-width: 479px) {
  .nav-control-round .owl-controls .owl-nav {
    top: inherit;
    right: inherit;
    position: inherit;
  }
}
.nav-control-round .owl-controls .owl-nav div {
  line-height: 38px;
  height: 42px !important;
  width: 42px !important;
  border: 1px solid #111111;
  background: #111111 !important;
  -webkit-border-radius: 45px !important;
  -moz-border-radius: 45px !important;
  -ms-border-radius: 45px !important;
  -o-border-radius: 45px !important;
  border-radius: 45px !important;
  opacity: 1 !important;
  font-size: 18px !important;
  padding: 0 !important;
  margin: 0 2px !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round .owl-controls .owl-nav div i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round .owl-controls .owl-nav div:hover {
  border: 1px solid #b6b6b6;
  background: transparent !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round .owl-controls .owl-nav div:hover i {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
@media only screen and (max-width: 479px) {
  .nav-control-round .owl-controls .owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 479px) {
  .nav-control-round .owl-controls .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 991px) {
  .nav-control-round .owl-controls .owl-pagination {
    width: 80px;
    display: inline-flex;
    overflow: hidden;
  }
}
.nav-control-round .owl-controls .owl-pagination span {
  background: #c2c2c2 !important;
}
.metro-carousel:hover .owl-prev {
  left: 0;
}
.metro-carousel:hover .owl-next {
  right: 0;
}
.nav-control-round-middle.nav-bg-amber .owl-controls .owl-nav div,
.nav-control-round.nav-bg-amber .owl-controls .owl-nav div {
  border: 1px solid #ffc400 !important;
  background-color: #ffc400 !important;
}
.nav-control-round-middle.nav-bg-amber .owl-controls .owl-nav div i,
.nav-control-round.nav-bg-amber .owl-controls .owl-nav div i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round-middle.nav-bg-amber .owl-controls .owl-nav div:hover,
.nav-control-round.nav-bg-amber .owl-controls .owl-nav div:hover {
  border: 1px solid #b6b6b6 !important;
  background: transparent !important;
}
.nav-control-round-middle.nav-bg-amber .owl-controls .owl-nav div:hover i,
.nav-control-round.nav-bg-amber .owl-controls .owl-nav div:hover i {
  color: #212121 !important;
}
.nav-control-round-middle.nav-blaze-orange .owl-controls .owl-nav div,
.nav-control-round.nav-blaze-orange .owl-controls .owl-nav div {
  border: 1px solid #ff6600 !important;
  background-color: #ff6600 !important;
}
.nav-control-round-middle.nav-blaze-orange .owl-controls .owl-nav div i,
.nav-control-round.nav-blaze-orange .owl-controls .owl-nav div i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round-middle.nav-blaze-orange .owl-controls .owl-nav div:hover,
.nav-control-round.nav-blaze-orange .owl-controls .owl-nav div:hover {
  border: 1px solid #b6b6b6 !important;
  background: transparent !important;
}
.nav-control-round-middle.nav-blaze-orange .owl-controls .owl-nav div:hover i,
.nav-control-round.nav-blaze-orange .owl-controls .owl-nav div:hover i {
  color: #212121 !important;
}
.nav-control-round-middle.nav-bg-dodger-blue .owl-controls .owl-nav div,
.nav-control-round.nav-bg-dodger-blue .owl-controls .owl-nav div {
  border: 1px solid #2962ff !important;
  background-color: #2962ff !important;
}
.nav-control-round-middle.nav-bg-dodger-blue .owl-controls .owl-nav div i,
.nav-control-round.nav-bg-dodger-blue .owl-controls .owl-nav div i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round-middle.nav-bg-dodger-blue .owl-controls .owl-nav div:hover,
.nav-control-round.nav-bg-dodger-blue .owl-controls .owl-nav div:hover {
  border: 1px solid #b6b6b6 !important;
  background: transparent !important;
}
.nav-control-round-middle.nav-bg-dodger-blue .owl-controls .owl-nav div:hover i,
.nav-control-round.nav-bg-dodger-blue .owl-controls .owl-nav div:hover i {
  color: #212121 !important;
}
.nav-control-round-middle.nav-bg-buddha-gold .owl-controls .owl-nav div,
.nav-control-round.nav-bg-buddha-gold .owl-controls .owl-nav div {
  border: 1px solid #cf9900 !important;
  background-color: #cf9900 !important;
}
.nav-control-round-middle.nav-bg-buddha-gold .owl-controls .owl-nav div i,
.nav-control-round.nav-bg-buddha-gold .owl-controls .owl-nav div i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round-middle.nav-bg-buddha-gold .owl-controls .owl-nav div:hover,
.nav-control-round.nav-bg-buddha-gold .owl-controls .owl-nav div:hover {
  border: 1px solid #b6b6b6 !important;
  background: transparent !important;
}
.nav-control-round-middle.nav-bg-buddha-gold .owl-controls .owl-nav div:hover i,
.nav-control-round.nav-bg-buddha-gold .owl-controls .owl-nav div:hover i {
  color: #212121 !important;
}
.nav-control-round-middle.nav-bg-coral-red .owl-controls .owl-nav div,
.nav-control-round.nav-bg-coral-red .owl-controls .owl-nav div {
  border: 1px solid #f93940 !important;
  background-color: #f93940 !important;
}
.nav-control-round-middle.nav-bg-coral-red .owl-controls .owl-nav div i,
.nav-control-round.nav-bg-coral-red .owl-controls .owl-nav div i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round-middle.nav-bg-coral-red .owl-controls .owl-nav div:hover,
.nav-control-round.nav-bg-coral-red .owl-controls .owl-nav div:hover {
  border: 1px solid #b6b6b6 !important;
  background: transparent !important;
}
.nav-control-round-middle.nav-bg-coral-red .owl-controls .owl-nav div:hover i,
.nav-control-round.nav-bg-coral-red .owl-controls .owl-nav div:hover i {
  color: #212121 !important;
}
.nav-control-round.nav-bg-malachite .owl-controls .owl-nav div {
  border: 1px solid #00c853 !important;
  background-color: #00c853 !important;
}
.nav-control-round.nav-bg-malachite .owl-controls .owl-nav div i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round.nav-bg-malachite .owl-controls .owl-nav div:hover {
  border: 1px solid #b6b6b6 !important;
  background: transparent !important;
}
.nav-control-round.nav-bg-malachite .owl-controls .owl-nav div:hover i {
  color: #212121 !important;
}
.dot-control-layout1 .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}
.dot-control-layout1 .owl-dots .owl-dot {
  border: 1px solid #b6b6b6;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  margin: 0 3px;
}
.dot-control-layout1 .owl-dots .owl-dot span {
  border: 4px solid #ffffff;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.dot-control-layout1 .owl-dots .owl-dot:hover span {
  background-color: #ffffff;
}
.dot-control-layout1 .owl-dots .owl-dot.active span {
  background-color: #2962ff;
}
.nav-control-round-middle .owl-controls {
  margin-top: 0px !important;
}
.nav-control-round-middle .owl-controls .owl-nav {
  position: inherit;
  margin-top: 0px !important;
}
.nav-control-round-middle .owl-controls .owl-nav div {
  line-height: 38px;
  height: 42px !important;
  width: 42px !important;
  border: 1px solid #111111;
  background: #111111 !important;
  -webkit-border-radius: 45px !important;
  -moz-border-radius: 45px !important;
  -ms-border-radius: 45px !important;
  -o-border-radius: 45px !important;
  border-radius: 45px !important;
  opacity: 1 !important;
  font-size: 18px !important;
  padding: 0 !important;
  margin: 0 2px !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round-middle .owl-controls .owl-nav div i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round-middle .owl-controls .owl-nav div:hover {
  border: 1px solid #b6b6b6;
  background: transparent !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round-middle .owl-controls .owl-nav div:hover i {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.nav-control-round-middle .owl-controls .owl-nav .owl-prev {
  position: absolute;
  left: -45px;
  top: 50%;
  z-index: 3;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.nav-control-round-middle .owl-controls .owl-nav .owl-next {
  position: absolute;
  right: -45px;
  top: 50%;
  z-index: 3;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (max-width: 991px) {
  .nav-control-round-middle .owl-controls .owl-pagination {
    width: 80px;
    display: inline-flex;
    overflow: hidden;
  }
}
.nav-control-round-middle .owl-controls .owl-pagination span {
  background: #c2c2c2 !important;
}
.nav-control-round-middle:hover .owl-controls .owl-nav .owl-prev {
  left: 0px;
}
.nav-control-round-middle:hover .owl-controls .owl-nav .owl-next {
  right: 0px;
}
/*-------------------------------------
1.5 ScrollUp styles
--------------------------------------*/
#scrollUp {
  background-color: rgba(17, 17, 17, 0.5);
  bottom: 15px;
  color: #ffffff !important;
  display: block;
  font-size: 25px;
  height: 40px;
  line-height: 0;
  position: fixed;
  right: 20px;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.5s cubic-bezier(0, 0, 0, 1) 0s;
  width: 50px;
  z-index: 99999;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#scrollUp i {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#scrollUp i {
  display: block;
  padding-top: 7px;
}
#scrollUp:hover,
#scrollUp:focus {
  /* background-color: rgba(103, 103, 103, 0.8) */
  background-color: rgba(17, 17, 17, 0.5);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#scrollUp:hover i,
#scrollUp:focus i {
  color: #070707;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
/*-------------------------------------
1.6 Preloader styles
--------------------------------------*/
#preloader {
  background: #ffffff url("../img/preloader.gif") no-repeat scroll center center;
  height: 100%;
  left: 0;
  overflow: visible;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;
}
/*-------------------------------------
1.7 Others styles
--------------------------------------*/
.row.no-gutters.full-width {
  margin-right: -15px;
  margin-left: -15px;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.margin-b-30 {
  margin-bottom: 30px;
}
.margin-b-50 {
  margin-bottom: 50px;
}
.margin-l-30 {
  margin-left: 30px;
}
.margin-t-30 {
  margin-top: 30px !important;
}
@media only screen and (max-width: 479px) {
  .col-mb-12 {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .overlay-light-mobile {
    position: relative;
  }
  .overlay-light-mobile:after {
    content: "";
    background: rgba(255, 255, 255, 0.7);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .overlay-dark-mobile {
    position: relative;
  }
  .overlay-dark-mobile:after {
    content: "";
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .media.media-none-sm .pull-left {
    text-align: center;
    float: inherit !important;
  }
}
@media only screen and (max-width: 479px) {
  .media.media-none-mb {
    text-align: center;
  }
  .media.media-none-mb .pull-left {
    text-align: center;
    float: inherit !important;
  }
  .media.media-none-mb .pull-left img {
    margin: 0 auto;
  }
}
.bg-light {
  background-color: #ffffff;
}
.bg-buddha-gold {
  background-color: #cf9900;
}
.bg-accent {
  background-color: #f1f1f1;
}
.bg-coral-red {
  background-color: #f93940;
}
.bg-amber {
  background-color: #ffc400;
}
.bg-malachite {
  background-color: #00c853;
}
.bg-dodger-blue {
  background-color: #2962ff;
}
.bg-tusk {
  background-color: #f0f4c3;
}
.bg-bright-sun {
  background-color: #ffd740;
}
.bg-blaze-orange {
  background-color: #ff6600 !important;
}
.color-light {
  color: #ffffff !important;
}
/*=====================================
2. Header Area 
=====================================*/
/*-------------------------------------
2.1 Header Commomn Area styles 
--------------------------------------*/
.header-top .header-top-inner-top {
  padding: 5px 0;
}
.header-bottom .logo-area {
  display: none;
}
@media only screen and (max-width: 991px) {
  .header-contact {
    text-align: center;
  }
}
.header-contact ul li {
  display: inline-block;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 2px;
}
.header-contact ul li i {
  padding-right: 5px;
  font-size: 14px;
}
.header-contact ul li a {
  font-size: 14px;
  text-transform: lowercase;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #a1a1a1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-contact ul li:last-child a {
  border-right: 0;
}
.account-wishlist {
  text-align: right;
}
@media only screen and (max-width: 991px) {
  .account-wishlist {
    text-align: center;
    margin-top: 5px;
  }
}
.account-wishlist ul li {
  display: inline-block;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 2px;
}
.account-wishlist ul li i {
  padding-right: 5px;
  font-size: 14px;
}
.account-wishlist ul li a {
  font-size: 14px;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #a1a1a1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.account-wishlist ul li:last-child a {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.main-menu-area nav ul .active > a {
  color: #212121;
}
.main-menu-area nav ul > li {
  display: inline-block;
  position: relative;
  padding: 20px 0 18px 0;
}
.main-menu-area nav ul > li > a {
  border-right: 1px solid #b6b6b6; /* border-right: 1px solid #fff; */
  padding-right: 25px;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 15px;
  color: #212121;
  margin-right: 25px;
  line-height: 1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  font-weight: 524;
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-menu-area nav ul > li > a {
    margin-right: 10px;
    padding-right: 10px;
  }
}
.main-menu-area nav ul > li > a:hover {
  color: #fffefe;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  text-decoration: underline;
}
.main-menu-area nav ul > li:last-child a {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.main-menu-area nav ul > li ul {
  border: 1px solid #f1f1f1;
  text-align: left;
  opacity: 0;
  position: absolute;
  top: 100%;
  transform: scaleY(0);
  -ms-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform-origin: 0 0 0;
  width: 180px;
  z-index: 99999;
  background: #ffffff;
  padding: 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-menu-area nav ul > li ul > li {
  padding: 0;
  display: block;
  border-bottom: 1px solid #f1f1f1;
  background: transparent;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-menu-area nav ul > li ul > li > a {
  margin-right: 0;
  border-right: 0 !important;
  padding: 15px 10px;
  display: block;
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-menu-area nav ul > li ul > li:last-child {
  border-bottom: none;
}
.main-menu-area nav ul > li ul > li:hover,
.main-menu-area nav ul > li ul > li .active {
  background: #4154f1;
  color: #ff0000;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-menu-area nav ul > li:hover ul {
  opacity: 1;
  transform: scaleY(1);
  -ms-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  visibility: visible;
}
.main-menu-area nav ul > li .mega-menu-area {
  padding: 18px 11px;
  width: 1107px;
}
.main-menu-area nav ul > li .mega-menu-area > li {
  width: 173px;
  border-bottom: none;
  display: inline-block;
  margin-right: 3px;
}
.main-menu-area nav ul > li .mega-menu-area > li:hover {
  background: transparent !important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-menu-area nav ul > li .mega-menu-area > li:hover a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-menu-area nav ul > li .mega-menu-area > li > a {
  padding: 12px 0 !important;
  display: block;
  border-bottom: 1px solid #b6b6b6;
  margin-right: 0 !important;
}
.main-menu-area nav ul > li .mega-menu-area > li > a:hover {
  color: #111111;
  background: #f1f1f1;
  padding-left: 10px !important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-menu-area nav ul > li .mega-menu-area > li > a:last-child {
  border-bottom: none;
}
.main-menu-area nav ul > li .mega-menu-area > li:last-child {
  margin-right: 0;
}
.main-menu-area nav ul > li .mega-menu-area > li a.active {
  color: #111111;
  background: #f1f1f1;
  padding-left: 10px !important;
}
.main-menu-area nav ul > li .mega-menu-area2 {
  padding: 18px 11px;
  width: 378px;
  left: -10px;
}
.main-menu-area nav ul > li .mega-menu-area2 > li {
  width: 173px;
  border-bottom: none;
  display: inline-block;
  margin-right: 3px;
}
.main-menu-area nav ul > li .mega-menu-area2 > li:hover {
  background: transparent !important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-menu-area nav ul > li .mega-menu-area2 > li:hover a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-menu-area nav ul > li .mega-menu-area2 > li > a {
  padding: 12px 0 !important;
  display: block;
  border-bottom: 1px solid #b6b6b6;
  margin-right: 0 !important;
}
.main-menu-area nav ul > li .mega-menu-area2 > li > a:hover {
  color: #111111;
  background: #f1f1f1;
  padding-left: 10px !important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-menu-area nav ul > li .mega-menu-area2 > li > a:last-child {
  border-bottom: none;
}
.main-menu-area nav ul > li .mega-menu-area2 > li:last-child {
  margin-right: 0;
}
.main-menu-area nav ul > li .mega-menu-area2 > li a.active {
  color: #111111;
  background: #f1f1f1;
  padding-left: 10px !important;
}
.header-cart-area {
  text-align: right;
}
.header-cart-area > li {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 30px;
  margin-right: 15px;
  top: 10px;
}
.header-cart-area > li:last-child {
  margin-right: 0;
}
.header-cart-area li:hover .cart-area ul {
  opacity: 1;
  transform: scaleY(1);
  -ms-transform: scaleY(1);
  -webkit-transform: scaleY(1);
}
.header-cart-area.header-action-items li {
  width: inherit;
  font-size: 14px;
  margin-right: 30px;
}
.header-cart-area.header-action-items li:last-child {
  margin-right: 0;
  margin-left: 30px;
}
.header-cart-area.header-action-items .contact-all-info i {
  margin-right: 15px;
}
.header-search .search-form {
  display: none;
  position: absolute;
  top: -7px;
  right: 30px;
  z-index: 10;
  color: #212121;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  width: 230px;
  background: #ffffff;
  border: 1px solid #676767;
  padding: 5px 18px 5px 10px;
  -webkit-box-shadow: 0 0 0.5px -1.5px rgba(153, 157, 163, 0.4);
  -moz-box-shadow: 0 0 0.5px -1.5px rgba(153, 157, 163, 0.4);
  box-shadow: 0 0 0.5px -1.5px rgba(153, 157, 163, 0.4);
}
.header-search a > i {
  color: #111111;
  font-size: 18px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-search a > i:hover {
  color: #727272;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-search.coral-red a > i {
  color: #f93940;
  font-size: 18px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-search.coral-red a > i:hover {
  color: #727272;
}
.header-search.buddha-gold a > i {
  color: #cf9900;
  font-size: 18px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-search.buddha-gold a > i:hover {
  color: #727272;
}
.cart-area {
  padding-bottom: 15px;
  position: relative;
}
.cart-area > a {
  color: #727272;
  font-size: 24px;
}
.cart-area > a > span {
  background: #4154f1;
  border-radius: 45px;
  color: #ffffff;
  height: 20px;
  padding: 0 6px;
  position: absolute;
  top: -10px;
  width: 20px;
  font-size: 12px;
  right: -10px;
  line-height: 20px;
}
.cart-area > ul {
  border-top: 3px solid #111111;
  padding: 15px;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.98);
  opacity: 0;
  position: absolute;
  right: 0;
  top: 43px;
  transform: scaleY(0);
  -ms-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform-origin: 0 0 0;
  width: 428px;
  z-index: 99999;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.cart-area > ul > li {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 5px;
}
.cart-area > ul > li .media {
  position: relative;
}
.cart-area > ul > li .media .cart-product-img {
  padding-right: 15px;
}
.cart-area > ul > li .media .cart-product-img a {
  display: inline-block;
}
.cart-area > ul > li .media .cart-product-img a img {
  border: 1px solid #dddddd;
  width: 80px;
  min-height: 80px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.cart-area > ul > li .media .cart-product-img a img:hover {
  opacity: 0.5;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.cart-area > ul > li .media .cart-content ul li {
  margin-right: 30px;
  display: inline-block;
}
.cart-area > ul > li .media .cart-content ul li h2 {
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 5px;
}
.cart-area > ul > li .media .cart-content ul li h2 a {
  color: #070707;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.cart-area > ul > li .media .cart-content ul li h2 a:hover {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.cart-area > ul > li .media .cart-content ul li h3 {
  font-size: 12px;
}
.cart-area > ul > li .media .cart-content ul li h3 span {
  color: #000000;
}
.cart-area > ul > li .media .cart-content ul li .trash {
  font-size: 16px;
  color: #000000;
}
.cart-area > ul > li .media .cart-content ul li .trash:hover {
  color: #fb0303;
}
.cart-area > ul > li .media .cart-content ul li:first-child {
  width: 140px;
}
.cart-area > ul > li .media .cart-content ul li:last-child {
  margin-right: 0;
}
.cart-area > ul > li:last-child {
  border-bottom: none;
}
.cart-area > ul > li .checkout {
  text-align: center;
}
.cart-area > ul > li .checkout li {
  display: inline;
}
.cart-area > ul > li > span {
  display: inline-block;
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  font-size: 12px;
  padding-right: 20px;
  text-align: right;
  width: 194px;
}
.cart-area > ul > li > span span {
  font-weight: 600;
  border-left: 1px solid #dddddd;
  width: 194px;
  display: inline-block;
  padding-right: 20px;
}
.cart-area.buddha-gold > a {
  color: #727272;
  font-size: 24px;
}
.cart-area.buddha-gold > a > span {
  background: #cf9900;
}
.cart-area.buddha-gold > ul {
  border-top: 3px solid #cf9900;
}
.cart-area.coral-red > a {
  color: #727272;
  font-size: 24px;
}
.cart-area.coral-red > a > span {
  background: #f93940;
}
.cart-area.coral-red > ul {
  border-top: 3px solid #f93940;
}
.cart-area.dodger-blue > a {
  color: #727272;
  font-size: 24px;
}
.cart-area.dodger-blue > a > span {
  background: #2962ff;
}
.cart-area.dodger-blue > ul {
  border-top: 3px solid #2962ff;
}
.cart-area.blaze-orange > a {
  color: #727272;
  font-size: 24px;
}
.cart-area.blaze-orange > a > span {
  background: #ff6600;
}
.cart-area.blaze-orange > ul {
  border-top: 3px solid #ff6600;
}
.additional-menu-area {
  position: absolute;
  top: -20px;
  right: 0;
}
.additional-menu-area:hover .side-menu-open i {
  color: #727272;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.additional-menu-area .side-menu-open i {
  cursor: pointer;
  font-size: 24px;
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.additional-menu-area .sidenav {
  position: relative;
}
.additional-menu-area .sidenav .closebtn {
  position: absolute;
  top: 0;
  left: -3px;
  font-size: 36px;
}
.additional-menu-area .sidenav .closebtn:hover {
  color: #ffffff;
}
.additional-menu-area .sidenav .sidenav-search {
  background: #ffffff;
  margin: 0 10px 10px 33px;
  margin-right: 30px;
}
.additional-menu-area .sidenav .sidenav-search .stylish-input-group {
  padding: 0 10px;
  border: none;
}
.additional-menu-area
  .sidenav
  .sidenav-search
  .stylish-input-group
  .form-control {
  color: #111111;
}
.additional-menu-area
  .sidenav
  .sidenav-search
  .stylish-input-group
  button
  span {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.additional-menu-area
  .sidenav
  .sidenav-search
  .stylish-input-group
  button:hover
  span {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.additional-menu-area .sidenav .sidenav-login-registration {
  border-bottom: 1px solid #ffffff;
  text-align: left;
  margin-bottom: 40px;
  padding-bottom: 5px;
  padding-left: 22px;
}
.additional-menu-area .sidenav .sidenav-login-registration li a {
  color: #ffffff;
}
.additional-menu-area .sidenav .sidenav-login-registration li:last-child {
  margin-left: 0;
  margin-right: 30px;
}
.additional-menu-area
  .sidenav
  .sidenav-login-registration
  .login-registration-field {
  border: none;
  background: #ffffff;
  padding: 20px 15px 30px;
}
.additional-menu-area
  .sidenav
  .sidenav-login-registration
  .login-registration-field
  label {
  font-size: 14px;
  color: #212121;
  font-weight: 400;
  margin-top: 10px;
}
.additional-menu-area
  .sidenav
  .sidenav-login-registration
  .login-registration-field
  input {
  border: 1px solid #111111;
  color: #212121;
  font-weight: 600;
}
.additional-menu-area .sidenav .ctg-name-title {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 500;
  margin-left: 10px;
}
.additional-menu-area .sidenav .sidenav-nav li {
  margin-bottom: 0;
}
.additional-menu-area .sidenav .sidenav-nav li a {
  text-align: left;
  font-size: 18px;
  color: #f1f1f1;
}
.additional-menu-area .sidenav .sidenav-nav li a:hover {
  color: #ffffff;
  background: #111111;
}
.additional-menu-area .sidenav .sidenav-nav li a i {
  margin-right: 15px;
}
.additional-menu-area .sidenav .sidenav-nav li:last-child {
  margin-left: 0;
}
.additional-menu-area .sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  background-color: #070707;
  overflow-x: hidden;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  padding-top: 60px;
  color: #ffffff;
}
.additional-menu-area .sidenav a {
  padding: 8px 8px 8px 10px;
  text-decoration: none;
  color: #818181;
  display: block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.category-menu-title {
  background: #111111;
  padding: 14px 10px 14px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 4px;
  margin-bottom: 0;
  margin-top: 4px;
}
.category-menu-title:hover {
  cursor: pointer;
}
@media (min-width: 768px) and (max-width: 991px) {
  .category-menu-title {
    letter-spacing: 1px;
  }
}
.category-menu-title a {
  color: #ffffff;
}
.category-menu-title a i {
  margin: -2px 10px 0 7px;
  font-size: 24px;
  float: left;
}
@media (min-width: 768px) and (max-width: 991px) {
  .category-menu-title a i {
    margin: 0 10px 0;
    font-size: 21px;
  }
}
.category-menu-title2 {
  background: #111111;
  padding: 14px 10px 14px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 4px;
  margin-bottom: 0;
}
.category-menu-title2:hover {
  cursor: pointer;
}
@media (min-width: 768px) and (max-width: 991px) {
  .category-menu-title2 {
    letter-spacing: 1px;
  }
}
.category-menu-title2 a {
  color: #ffffff;
}
.category-menu-title2 a i {
  margin: -2px 10px 0 7px;
  font-size: 24px;
  float: left;
}
@media (min-width: 768px) and (max-width: 991px) {
  .category-menu-title2 a i {
    margin: 0 10px 0;
    font-size: 21px;
  }
}
@media only screen and (max-width: 1199px) {
  .category-menu-area ul {
    -webkit-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  }
}
.category-menu-area ul li {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 1px solid #b6b6b6;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.category-menu-area ul li:last-child {
  border-bottom: 0;
}
.category-menu-area ul li a {
  display: block;
  padding: 8px 0 7px 2px;
  color: #333333;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style3 .category-menu-area ul li a {
  padding: 8px 0 7px 5px !important;
}
.category-menu-area ul li a > i:before {
  padding-right: 17px;
  font-size: 20px;
  float: left;
  margin-left: 15px !important;
}
@media only screen and (max-width: 1199px) {
  .category-menu-area ul li a > i:before {
    margin-left: 5px;
  }
}
.category-menu-area ul li a span {
  position: absolute;
  right: 0;
}
.category-menu-area ul li a span > i:before {
  font-size: 10px;
  color: #b6b6b6;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.category-menu-area ul li:hover {
  background: #f1f1f1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.category-menu-area ul li:hover a {
  color: #070707;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.category-menu-area ul li:hover span i:before {
  color: #070707;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.category-menu-area ul li .dropdown-menu {
  padding: 5px;
  position: absolute;
  top: 0;
  left: 100%;
  margin: 0;
  border-radius: 0;
}
.category-menu-area ul li .dropdown-menu > li {
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.category-menu-area ul li .dropdown-menu > li > a {
  padding: 8px 15px;
  color: #333333 !important;
  font-weight: 500;
}
.category-menu-area ul li .dropdown-menu > li:hover > a {
  color: #070707 !important;
  background: #f1f1f1 !important;
}
.category-menu-area ul li:hover .dropdown-menu {
  display: block;
}
@media only screen and (min-width: 767px) {
  li.has-child-menu {
    position: relative;
  }
  li.has-child-menu > a {
    color: #ffffff;
  }
  li.has-child-menu > a:after {
    font-family: "FontAwesome";
    content: "\f105";
    float: right;
    position: relative;
    right: 5px;
  }
  li.has-child-menu > ul.thired-level {
    position: absolute;
    left: 177px;
    top: 0;
    opacity: 0 !important;
    transform: scaleY(0) !important;
    transform-origin: 0 0 0;
    transition: all 0.5s ease 0s;
  }
  li.has-child-menu > ul.thired-level li a {
    color: #212121;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  li.has-child-menu > ul.thired-level li:hover a {
    color: #212121;
  }
  li.has-child-menu:hover ul.thired-level {
    opacity: 1 !important;
    transform: scaleY(1) !important;
    visibility: visible;
  }
}
/*-------------------------------------
2.2 Home1 Header Area styles 
---------------------------------------*/
.header-area-style1 .header-top .header-top-inner-top {
  background: #f1f1f1;
}
.header-area-style1 .header-top .header-top-inner-top ul li i {
  color: #111111;
}
.header-area-style1 .header-top .header-top-inner-top ul li a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style1 .header-top .header-top-inner-top ul li:hover a {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style1 .header-top .header-top-inner-bottom {
  padding: 30px 0 18px;
  position: relative;
}
.header-area-style1 .header-top .header-top-inner-bottom .search-area {
  position: relative;
  z-index: 8;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group {
  width: 102%;
  margin: 0 auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .form-control {
  height: 42px;
  color: #212121;
  border-radius: 0;
  box-shadow: none;
  border: none;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group {
  display: flex !important;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn {
  border-radius: 0;
  margin-left: -1px;
  padding: 0 15px;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro {
  margin-top: 10px;
  margin-right: 15px;
  background: transparent;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  i {
  display: block;
  padding: 5px;
  line-height: 0;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  span {
  float: left;
  margin-right: 15px;
  color: #111111;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu {
  margin-top: -1px;
  padding: 20px 0 0 0;
  min-width: 250px;
  right: 0;
  border-radius: 0;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li {
  margin-bottom: 10px;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a {
  text-align: left;
  font-size: 15px;
  color: #212121;
  display: flex;
  padding: 5px 10px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover {
  color: #070707;
  background: #f1f1f1;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover
  i {
  color: #070707;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a
  i {
  font-weight: 600;
  margin-right: 15px;
  color: #212121;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search {
  padding: 10px 12px;
  border-radius: 0;
  background: #f1f1f1;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search
  span {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover {
  background: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style1
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover
  span {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style1 .header-bottom .header-cart-area {
  display: none;
}
.header-area-style1 .header-bottom nav ul > li .mega-menu-area {
  left: -551px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style1 .header-bottom nav ul > li .mega-menu-area {
    width: 578px;
    left: -330px;
    padding: 18px 0 18px 34px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style1 .header-bottom nav ul > li .mega-menu-area > li > a {
    border-bottom: none;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style1 .header-bottom .main-menu-area nav > ul > li {
    padding: 20px 0 19px 0;
  }
}
.open-on-tab {
  display: none;
}
@media only screen and (max-width: 1199px) {
  .open-on-tab {
    display: block;
  }
}
@media only screen and (max-width: 1199px) {
  .open-on-tab .category-menu-area-inner {
    opacity: 0;
    position: absolute;
    z-index: 8;
    background: #ffffff;
    width: 213px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}
@media only screen and (max-width: 991px) {
  .open-on-tab .category-menu-area-inner {
    width: 220px;
  }
}
@media only screen and (max-width: 1199px) {
  .open-on-tab:hover .category-menu-area-inner {
    opacity: 1;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}
@media only screen and (max-width: 1199px) {
  .close-on-tab {
    display: none;
  }
}
/*-------------------------------------
2.3 Home2 Header Area styles 
---------------------------------------*/
.header-area-style2 .header-top {
  display: block;
  /* padding: 10px 0; */
  background: transparent;
  border-bottom: 1px solid #b6b6b6;
}
.header-area-style2 .header-top .account-wishlist {
  margin-top: 25px;
  text-align: right;
}
.header-area-style2 .header-top .account-wishlist li a {
  color: #212121;
}
.header-area-style2 .header-top .account-wishlist li a i {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style2 .header-top .account-wishlist li:hover a {
  color: #070707;
}
.header-area-style2 .header-top .account-wishlist li:hover a i {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style2 .header-top .logo-area {
  text-align: center;
}
.header-area-style2 .header-top .logo-area img {
  display: inherit;
}
.header-area-style2 .header-top .header-cart-area .cart-area {
  padding-bottom: 0;
}
.header-area-style2 .header-top .header-cart-area .cart-area ul {
  top: 53px;
}
.header-area-style2 .header-bottom {
  /* text-align: center; */
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  background-color: #4154f1;
}
.header-area-style2 .header-bottom .main-menu-area nav > ul > li {
  padding: 20px 0;
}
.header-area-style2 .header-bottom .logo-area2 {
  display: none;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style2 .header-bottom nav ul > li .mega-menu-area {
  left: -720px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style2 .header-bottom nav ul > li .mega-menu-area {
    width: 578px;
    left: -412px;
    padding: 18px 0 18px 34px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style2 .header-bottom nav ul > li .mega-menu-area > li > a {
    border-bottom: none;
  }
}
/*-------------------------------------
2.4 Home3 Header Area styles 
---------------------------------------*/
.header-area-style3 .header-top .header-top-inner-top {
  background: transparent;
  border-bottom: 1px solid #b6b6b6;
}
.header-area-style3 .header-top .header-top-inner-top .header-contact ul li a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3 .header-top .header-top-inner-top .header-contact ul li i {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3
  .header-top
  .header-top-inner-top
  .header-contact
  ul
  li:hover
  a {
  color: #070707;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3
  .header-top
  .header-top-inner-top
  .header-contact
  ul
  li:hover
  i {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li
  a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li
  i {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li:hover
  a {
  color: #070707;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li:hover
  i {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3 .header-top .header-top-inner-bottom {
  padding: 30px 0 18px;
  position: relative;
}
.header-area-style3 .header-top .header-top-inner-bottom .search-area {
  position: relative;
  z-index: 8;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group {
  width: 102%;
  margin: 0 auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .form-control {
  height: 42px;
  color: #111111;
  border-radius: 0;
  box-shadow: none;
  border: none;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group {
  display: flex !important;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn {
  border-radius: 0;
  margin-left: -1px;
  padding: 0 15px;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro {
  margin-top: 10px;
  margin-right: 15px;
  background: transparent;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  i {
  display: block;
  padding: 5px;
  line-height: 0;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  span {
  float: left;
  margin-right: 15px;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu {
  margin-top: -1px;
  padding: 20px 0 0 0;
  min-width: 250px;
  right: 0;
  border-radius: 0;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li {
  margin-bottom: 10px;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a {
  text-align: left;
  font-size: 15px;
  color: #212121;
  display: flex;
  padding: 5px 10px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover {
  color: #070707;
  background: #f1f1f1;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a
  i {
  margin-right: 15px;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search {
  padding: 10px 12px;
  border-radius: 0;
  background: #f1f1f1;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search
  span {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover {
  background: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover
  span {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3 .header-bottom .category-menu-area {
  position: relative;
}
.header-area-style3
  .header-bottom
  .category-menu-area
  .category-menu-area-inner {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 10;
  top: 48px;
  background: #ffffff;
  width: 263px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
@media only screen and (max-width: 1199px) {
  .header-area-style3
    .header-bottom
    .category-menu-area
    .category-menu-area-inner {
    width: 212px;
  }
}
@media only screen and (max-width: 991px) {
  .header-area-style3
    .header-bottom
    .category-menu-area
    .category-menu-area-inner {
    width: 220px;
  }
}
.header-area-style3
  .header-bottom
  .category-menu-area:hover
  .category-menu-area-inner {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style3 .header-bottom .header-cart-area {
  display: none;
}
.header-area-style3 .header-bottom nav ul > li .mega-menu-area {
  left: -551px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style3 .header-bottom nav ul > li .mega-menu-area {
    width: 578px;
    left: -330px;
    padding: 18px 0 18px 34px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style3 .header-bottom nav ul > li .mega-menu-area > li > a {
    border-bottom: none;
  }
}
/*-------------------------------------
2.5 Home4 Header Area styles 
---------------------------------------*/
.header-area-style4 {
  padding-bottom: 30px;
}
/*-------------------------------------
2.6 Home5 Header Area styles 
---------------------------------------*/
.header-area-style5 .header-top {
  border-bottom: 1px solid #dcdcdc;
}
.header-area-style5 .header-top .header-top-inner-bottom {
  padding: 30px 0;
  position: relative;
}
@media (min-width: 768px) and (max-width: 991px) {
  .header-area-style5 .header-top .header-top-inner-bottom {
    padding: 15px 0;
  }
}
.header-area-style5 .header-top .header-top-inner-bottom .search-area {
  position: relative;
  z-index: 8;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group {
  width: 102%;
  margin: 0 auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .form-control {
  height: 42px;
  color: #212121;
  border-radius: 0;
  box-shadow: none;
  border: none;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group {
  display: flex !important;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn {
  border-radius: 0;
  margin-left: -1px;
  padding: 0 15px;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro {
  margin-top: 10px;
  margin-right: 15px;
  background: transparent;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  i {
  display: block;
  padding: 5px;
  line-height: 0;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  span {
  float: left;
  margin-right: 15px;
  color: #111111;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu {
  margin-top: -1px;
  padding: 20px 0 0 0;
  min-width: 250px;
  right: 0;
  border-radius: 0;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li {
  margin-bottom: 10px;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a {
  text-align: left;
  font-size: 18px;
  color: #212121;
  display: flex;
  padding: 5px 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover {
  color: #070707;
  background: #f1f1f1;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover
  i {
  color: #070707;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a
  i {
  font-weight: 600;
  margin-right: 15px;
  color: #212121;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search {
  padding: 10px 12px;
  border-radius: 0;
  background: #f1f1f1;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search
  span {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover {
  background: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover
  span {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style5 .header-top .header-top-inner-bottom .header-action-items {
  margin-top: 5px;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .header-action-items
  li {
  top: 0;
}
.header-area-style5
  .header-top
  .header-top-inner-bottom
  .header-action-items
  li
  .additional-menu-area {
  top: -16px;
}
.header-area-style5 .header-bottom li.header-search {
  padding-right: 15px;
  border-right: 1px solid #dcdcdc;
  margin-right: 10px;
}
.header-area-style5 .header-bottom li.header-search .search-input {
  right: 40px;
}
.header-area-style5 .header-bottom .logo-area {
  margin-right: 50px;
}
.header-area-style5 .header-bottom nav ul > li .mega-menu-area {
  left: -300px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style5 .header-bottom nav ul > li .mega-menu-area {
    width: 578px;
    left: -330px;
    padding: 18px 0 18px 34px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style5 .header-bottom nav ul > li .mega-menu-area > li > a {
    border-bottom: none;
  }
}
.header-area-style5 .header-bottom .main-menu-area nav > ul > li a:hover {
  color: #f93940;
}
.header-area-style5 .header-cart-area.header-action-items li {
  width: inherit;
  font-size: 14px;
  margin-right: 30px;
}
.header-area-style5 .header-cart-area.header-action-items li:last-child {
  margin-right: 0;
  margin-left: 30px;
}
.header-area-style5 .header-cart-area.header-action-items .contact-all-info i {
  color: #f93940;
  margin-right: 15px;
}
.header-area-style5.stick nav ul > li .mega-menu-area {
  left: -480px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style5.stick nav ul > li .mega-menu-area {
    width: 578px;
    left: -400px;
    padding: 18px 0 18px 34px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style5.stick nav ul > li .mega-menu-area > li > a {
    border-bottom: none;
  }
}
@media only screen and (max-width: 991px) {
  .header-area-style5 .header-cart-area.header-action-items li {
    margin-right: 5px;
  }
  .header-area-style5 .header-cart-area.header-action-items li i {
    margin-right: 5px;
  }
}
@media only screen and (max-width: 991px) {
  .header-area-style5 .header-bottom nav ul > li .mega-menu-area {
    left: -218px;
  }
}
/*-------------------------------------
2.7 Home6 Header Area styles 
---------------------------------------*/
.header-area-style6 .header-top {
  border-bottom: 1px solid #dcdcdc;
}
.header-area-style6 .header-top .header-top-inner-bottom {
  padding: 20px 0;
  position: relative;
}
.header-area-style6
  .header-top
  .header-top-inner-bottom
  .header-cart-area.header-action-items {
  text-align: inherit;
}
.header-area-style6
  .header-top
  .header-top-inner-bottom
  .header-cart-area.header-action-items
  li {
  margin-right: 20px;
}
.header-area-style6
  .header-top
  .header-top-inner-bottom
  .header-cart-area.header-action-items
  li
  i {
  color: #cf9900;
}
.header-area-style6
  .header-top
  .header-top-inner-bottom
  .header-cart-area.header-action-items
  li:last-child {
  margin-left: 0;
  margin-right: 0;
}
.header-area-style6 .header-top .header-top-inner-bottom .header-action-items {
  margin-top: 5px;
}
.header-area-style6
  .header-top
  .header-top-inner-bottom
  .header-action-items
  li {
  top: 0;
}
.header-area-style6
  .header-top
  .header-top-inner-bottom
  .header-action-items
  li
  .additional-menu-area {
  top: -16px;
}
.header-area-style6 .header-bottom {
  -webkit-box-shadow: 0px 1px 0px 0px rgba(108, 108, 108, 0.75);
  -moz-box-shadow: 0px 1px 0px 0px rgba(108, 108, 108, 0.75);
  box-shadow: 0px 1px 0px 0px rgba(108, 108, 108, 0.75);
}
.header-area-style6 .header-bottom .logo-area {
  display: block;
  margin-top: 10px;
}
.header-area-style6 .header-bottom li.header-search {
  padding-right: 15px;
  border-right: 1px solid #dcdcdc;
  margin-right: 10px;
}
.header-area-style6 .header-bottom .logo-area {
  margin-right: 50px;
}
.header-area-style6 .header-bottom nav ul > li .mega-menu-area {
  left: -551px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style6 .header-bottom nav ul > li .mega-menu-area {
    width: 578px;
    left: -330px;
    padding: 18px 0 18px 34px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style6 .header-bottom nav ul > li .mega-menu-area > li > a {
    border-bottom: none;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style6 .header-bottom .main-menu-area nav > ul > li {
    padding: 20px 0 19px 0;
  }
}
.header-area-style6 .header-bottom .main-menu-area nav > ul > li a:hover {
  color: #cf9900;
}
.header-area-style6.stick .header-bottom {
  border-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .header-area-style6
    .header-top
    .header-top-inner-bottom
    .header-cart-area.header-action-items
    li {
    margin-right: 10px;
  }
  .header-area-style6
    .header-top
    .header-top-inner-bottom
    .header-cart-area.header-action-items
    li
    i {
    margin-right: 5px;
  }
}
/*-------------------------------------
2.8 Home7 Header Area styles 
---------------------------------------*/
.header-area-style7 .header-top .header-top-inner-top {
  background: transparent;
  border-bottom: 1px solid #cfd8dc;
}
.header-area-style7 .header-top .header-top-inner-top ul li {
  letter-spacing: 0;
}
.header-area-style7 .header-top .header-top-inner-top ul li i {
  color: #111111;
}
.header-area-style7 .header-top .header-top-inner-top ul li a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style7 .header-top .header-top-inner-top ul li:hover a {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style7 .header-top .header-top-inner-bottom {
  padding: 30px 0 18px;
  position: relative;
}
.header-area-style7 .header-top .header-top-inner-bottom .search-area {
  position: relative;
  z-index: 8;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group {
  width: 102%;
  margin: 0 auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .form-control {
  height: 42px;
  color: #212121;
  border-radius: 0;
  box-shadow: none;
  border: none;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group {
  display: flex !important;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn {
  border-radius: 0;
  margin-left: -1px;
  padding: 0 15px;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro {
  margin-top: 10px;
  margin-right: 15px;
  background: transparent;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  i {
  display: block;
  padding: 5px;
  line-height: 0;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  span {
  float: left;
  margin-right: 15px;
  color: #111111;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu {
  margin-top: -1px;
  padding: 20px 0 0 0;
  min-width: 250px;
  right: 0;
  border-radius: 0;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li {
  margin-bottom: 10px;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a {
  text-align: left;
  font-size: 18px;
  color: #212121;
  display: flex;
  padding: 5px 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover {
  color: #070707;
  background: #f1f1f1;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover
  i {
  color: #070707;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a
  i {
  font-weight: 600;
  margin-right: 15px;
  color: #212121;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search {
  padding: 10px 20px;
  border-radius: 0;
  background: #2962ff;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search
  span {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover {
  background: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style7
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover
  span {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style7 .header-bottom .header-cart-area {
  display: none;
}
.header-area-style7 .header-bottom nav ul > li .mega-menu-area {
  left: -551px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style7 .header-bottom nav ul > li .mega-menu-area {
    width: 578px;
    left: -330px;
    padding: 18px 0 18px 34px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style7 .header-bottom nav ul > li .mega-menu-area > li > a {
    border-bottom: none;
  }
}
.header-area-style7 .header-bottom .main-menu-area nav > ul > li {
  padding: 20px 0 25px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style7 .header-bottom .main-menu-area nav > ul > li {
    padding: 20px 0 19px 0;
  }
}
.header-area-style7 .header-bottom .main-menu-area nav > ul > li i {
  margin-right: 5px;
  color: #afafaf;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style7 .header-bottom .main-menu-area nav > ul > li a:hover {
  color: #2962ff;
}
.header-area-style7 .header-bottom .main-menu-area nav > ul > li a:hover i {
  color: #2962ff;
}
/*-------------------------------------
2.9 Home8 Header Area styles 
---------------------------------------*/
.header-area-style8 .header-top .header-top-inner-top {
  padding: 0;
  background: transparent;
  border-bottom: 1px solid #cfd8dc;
}
.header-area-style8 .header-top .header-top-inner-top .header-contact ul li {
  border-right: 1px solid #cfd8dc;
  letter-spacing: 0;
  padding: 0 10px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .header-area-style8 .header-top .header-top-inner-top .header-contact ul li {
    padding: 0 3px;
  }
}
.header-area-style8 .header-top .header-top-inner-top .header-contact ul li i {
  color: #111111;
}
.header-area-style8 .header-top .header-top-inner-top .header-contact ul li a {
  text-transform: initial;
  margin-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 0;
  display: inline-block;
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style8
  .header-top
  .header-top-inner-top
  .header-contact
  ul
  li:hover
  a {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style8 .header-top .header-top-inner-top .account-wishlist {
  margin-top: 0;
}
.header-area-style8 .header-top .header-top-inner-top .account-wishlist ul li {
  border-left: 1px solid #cfd8dc;
  letter-spacing: 0;
  padding: 0 10px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .header-area-style8
    .header-top
    .header-top-inner-top
    .account-wishlist
    ul
    li {
    padding: 0 8px;
  }
}
.header-area-style8
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li:first-child {
  border-left: 0;
}
.header-area-style8
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li:first-child
  i {
  margin-right: 10px;
}
.header-area-style8
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li
  i {
  color: #111111;
  padding: 0;
}
.header-area-style8
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li
  a {
  padding: 10px 5px;
  margin-right: 0;
  border-right: 0;
  display: inline-block;
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style8
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li:hover
  a {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style8 .header-top .header-top-inner-bottom {
  padding: 20px 0;
  position: relative;
}
.header-area-style8 .header-top .header-top-inner-bottom .logo-area {
  margin-top: 5px;
}
.header-area-style8 .header-top .header-top-inner-bottom .search-area {
  position: relative;
  z-index: 8;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group {
  width: 102%;
  margin: 0 auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .form-control {
  height: 42px;
  color: #212121;
  border-radius: 0;
  box-shadow: none;
  border: none;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group {
  display: flex !important;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn {
  border-radius: 0;
  margin-left: -1px;
  padding: 0 15px;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro {
  margin-top: 10px;
  margin-right: 15px;
  background: transparent;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  i {
  display: block;
  padding: 5px;
  line-height: 0;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  span {
  float: left;
  margin-right: 15px;
  color: #111111;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu {
  margin-top: -1px;
  padding: 20px 0 0 0;
  min-width: 250px;
  right: 0;
  border-radius: 0;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li {
  margin-bottom: 10px;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a {
  text-align: left;
  font-size: 18px;
  color: #212121;
  display: flex;
  padding: 5px 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover {
  color: #070707;
  background: #f1f1f1;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover
  i {
  color: #070707;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a
  i {
  font-weight: 600;
  margin-right: 15px;
  color: #212121;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search {
  padding: 10px 20px;
  border-radius: 0;
  background: #2962ff;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search
  span {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover {
  background: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style8
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover
  span {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style8 .header-bottom {
  background-color: #2962ff;
}
.header-area-style8 .header-bottom .logo-area {
  display: none;
}
.header-area-style8 .header-bottom .header-cart-area {
  display: none;
}
.header-area-style8 .header-bottom nav ul > li .mega-menu-area {
  left: -551px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style8 .header-bottom nav ul > li .mega-menu-area {
    width: 578px;
    left: -330px;
    padding: 18px 0 18px 34px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style8 .header-bottom nav ul > li .mega-menu-area > li > a {
    border-bottom: none;
  }
}
.header-area-style8 .header-bottom .main-menu-area nav > ul > li {
  padding: 20px 0;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -ms-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style8 .header-bottom .main-menu-area nav > ul > li {
    padding: 20px 0 19px 0;
  }
}
.header-area-style8 .header-bottom .main-menu-area nav > ul > li i {
  margin-right: 5px;
  color: #afafaf;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style8 .header-bottom .main-menu-area nav > ul > li > a {
  color: #ffffff;
}
.header-area-style8 .header-bottom .main-menu-area nav > ul > li > a:hover {
  color: #212121;
}
.header-area-style8 .header-bottom .main-menu-area nav > ul > li > a:hover i {
  color: #212121;
}
.header-area-style8 .header-bottom .main-menu-area nav > ul > li > a ul li a {
  color: #212121;
}
.header-area-style8.stick .header-bottom {
  border-bottom: 0;
  background-color: rgba(41, 98, 255, 0.95);
}
.header-area-style8.stick .header-bottom .logo-area {
  margin-top: 12px;
  display: inline-block;
  float: left;
  margin-right: 30px;
}
/*-------------------------------------
2.10 Home9 Header Area styles 
---------------------------------------*/
.header-area-style9 .header-top .header-top-inner-top {
  padding: 0;
  background: transparent;
  border-bottom: 1px solid #cfd8dc;
}
.header-area-style9 .header-top .header-top-inner-top .header-contact ul li {
  border-right: 1px solid #cfd8dc;
  letter-spacing: 0;
  padding: 0 10px;
}
.header-area-style9 .header-top .header-top-inner-top .header-contact ul li i {
  color: #50b81d;
}
.header-area-style9 .header-top .header-top-inner-top .header-contact ul li a {
  text-transform: initial;
  margin-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 0;
  display: inline-block;
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style9
  .header-top
  .header-top-inner-top
  .header-contact
  ul
  li:hover
  a {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style9 .header-top .header-top-inner-top .account-wishlist ul li {
  border-left: 1px solid #cfd8dc;
  letter-spacing: 0;
  padding: 0 10px;
}
.header-area-style9
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li:first-child {
  border-left: 0;
}
.header-area-style9
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li:first-child
  i {
  margin-right: 10px;
}
.header-area-style9
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li:last-child {
  border-right: 1px solid #cfd8dc;
}
.header-area-style9
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li
  i {
  color: #50b81d;
  padding: 0;
}
.header-area-style9
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li
  a {
  padding: 10px 5px;
  margin-right: 0;
  border-right: 0;
  display: inline-block;
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style9
  .header-top
  .header-top-inner-top
  .account-wishlist
  ul
  li:hover
  a {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.header-area-style9 .header-top .header-top-inner-bottom {
  position: relative;
}
.header-area-style9 .header-top .header-top-inner-bottom .search-area {
  position: relative;
  z-index: 8;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group {
  width: 102%;
  margin: 0 auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .form-control {
  height: 42px;
  color: #212121;
  border-radius: 0;
  box-shadow: none;
  border: none;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group {
  display: flex !important;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn {
  border-radius: 0;
  margin-left: -1px;
  padding: 0 15px;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro {
  margin-top: 10px;
  margin-right: 15px;
  background: transparent;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  i {
  display: block;
  padding: 5px;
  line-height: 0;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  span {
  float: left;
  margin-right: 15px;
  color: #111111;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu {
  margin-top: -1px;
  padding: 20px 0 0 0;
  min-width: 250px;
  right: 0;
  border-radius: 0;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li {
  margin-bottom: 10px;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a {
  text-align: left;
  font-size: 18px;
  color: #212121;
  display: flex;
  padding: 5px 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover {
  color: #070707;
  background: #f1f1f1;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover
  i {
  color: #070707;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style9
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a
  i {
  font-weight: 600;
  margin-right: 15px;
  color: #212121;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style9 .header-bottom .logo-area {
  display: block;
  margin-top: 12px;
}
.header-area-style9 .header-bottom nav ul > li .mega-menu-area {
  left: -551px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style9 .header-bottom nav ul > li .mega-menu-area {
    width: 578px;
    left: -330px;
    padding: 18px 0 18px 34px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style9 .header-bottom nav ul > li .mega-menu-area > li > a {
    border-bottom: none;
  }
}
.header-area-style9 .header-bottom .main-menu-area nav > ul > li {
  padding: 30px 0 !important;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -ms-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
.header-area-style9 .header-bottom .main-menu-area nav > ul > li > a {
  color: #212121;
}
.header-area-style9 .header-bottom .main-menu-area nav > ul > li > a:hover {
  color: #50b81d;
}
.header-area-style9 .header-bottom .main-menu-area nav > ul > li > a ul li a {
  color: #212121;
}
.header-area-style9 .header-bottom .cart-area {
  padding: 10px 0 27px;
}
.header-area-style9 .header-bottom .cart-area > ul {
  top: 65px;
  padding: 15px;
}
.header-area-style9 .header-bottom .cart-area a span {
  top: 0;
  background-color: #50b81d;
}
.header-area-style9 .header-bottom .header-cart-area > li {
  border-right: 1px solid #b6b6b6;
  padding-right: 10px;
  margin-right: 0;
}
.header-area-style9 .header-bottom .header-cart-area > li:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.header-area-style9.stick .header-bottom .logo-area {
  margin-top: 12px;
  display: inline-block;
  float: left;
  margin-right: 30px;
}
/*-------------------------------------
2.11 Home10 Header Area styles 
---------------------------------------*/
.header-area-style10 .header-top {
  border-bottom: 1px solid #dcdcdc;
}
.header-area-style10 .header-top .header-top-inner-bottom {
  padding: 20px 0;
  position: relative;
}
.header-area-style10 .header-top .header-top-inner-bottom .search-area {
  position: relative;
  z-index: 8;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group {
  width: 102%;
  margin: 0 auto;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .form-control {
  height: 42px;
  color: #212121;
  border-radius: 0;
  box-shadow: none;
  border: none;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group {
  display: flex !important;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn {
  border-radius: 0;
  margin-left: -1px;
  padding: 0 15px;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro {
  margin-top: 10px;
  margin-right: 15px;
  background: transparent;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  i {
  display: block;
  padding: 5px;
  line-height: 0;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .btn-metro
  span {
  float: left;
  margin-right: 15px;
  color: #111111;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu {
  margin-top: -1px;
  padding: 20px 0 0 0;
  min-width: 250px;
  right: 0;
  border-radius: 0;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li {
  margin-bottom: 10px;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a {
  text-align: left;
  font-size: 18px;
  color: #212121;
  display: flex;
  padding: 5px 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover {
  color: #070707;
  background: #f1f1f1;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a:hover
  i {
  color: #070707;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .dropdown-lg
  .dropdown-menu
  .sidenav-nav
  li
  a
  i {
  font-weight: 600;
  margin-right: 15px;
  color: #212121;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search {
  padding: 10px 12px;
  border-radius: 0;
  background: #f1f1f1;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search
  span {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover {
  background: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .search-area
  .input-group
  .input-group-btn
  .btn-group
  .btn-metro-search:hover
  span {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-area-style10 .header-top .header-top-inner-bottom .header-action-items {
  margin-top: 10px;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .header-action-items
  li.contact-all-info {
  top: 0;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .header-action-items
  li.contact-all-info
  .additional-menu-area {
  top: -16px;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .header-action-items
  li.contact-all-info
  i {
  color: #ff6600;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .header-action-items
  li
  .additional-menu-area
  .side-menu-open
  i {
  color: #ff6600;
}
.header-area-style10
  .header-top
  .header-top-inner-bottom
  .header-action-items
  li
  .additional-menu-area {
  top: -26px;
}
.header-area-style10 .header-bottom .main-menu-area nav ul > li > a {
  border-right: 0;
  padding-right: 0;
}
.header-area-style10 .header-bottom li.header-search {
  padding-right: 15px;
  border-right: 1px solid #dcdcdc;
  margin-right: 10px;
}
.header-area-style10 .header-bottom li.header-search a i {
  color: #ff6600;
}
.header-area-style10 .header-bottom .cart-area > a > span {
  background-color: #ff6600;
}
.header-area-style10 .header-bottom .logo-area {
  margin-right: 50px;
}
.header-area-style10 .header-bottom nav ul > li .mega-menu-area {
  left: -551px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style10 .header-bottom nav ul > li .mega-menu-area {
    width: 578px;
    left: -330px;
    padding: 18px 0 18px 34px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .header-area-style10 .header-bottom nav ul > li .mega-menu-area > li > a {
    border-bottom: none;
  }
}
.header-area-style10 .header-bottom .main-menu-area nav > ul > li {
  padding: 28px 0 25px;
}
.header-area-style10 .header-bottom .main-menu-area nav > ul > li a:hover {
  color: #ff6600;
}
.header-area-style10 .header-bottom .header-cart-area {
  margin-top: 10px;
}
.header-area-style10 .header-bottom .header-cart-area .cart-area {
  padding-bottom: 19px;
}
.header-area-style10 .header-bottom .header-cart-area .cart-area ul {
  top: 48px;
}
.header-area-style10
  .header-bottom
  .header-cart-area
  .header-search
  .search-input {
  right: 35px;
}
.header-area-style10.stick .header-cart-area {
  margin-top: 0;
}
.header-area-style10.stick .header-bottom .logo-area {
  margin-top: 3px;
}
@media only screen and (max-width: 991px) {
  .header-area-style10 .header-cart-area.header-action-items li {
    margin-right: 10px;
  }
  .header-area-style10 .header-cart-area.header-action-items li i {
    margin-right: 5px;
  }
}
@media only screen and (max-width: 991px) {
  .header-area-style10 .header-bottom nav ul > li .mega-menu-area {
    left: -218px;
  }
}
/*-------------------------------------
2.12 Offcanvas Menu Area styles 
---------------------------------------*/
.nav-side-menu {
  padding: 40px 0 56px;
  letter-spacing: 2px;
  font-size: 15px;
  background: #f1f1f1;
  top: 0px;
  width: 100%;
  height: 100%;
  color: #111111;
  font-weight: 400;
}
.nav-side-menu .logo-area {
  line-height: 50px;
  display: block;
  padding-left: 15px;
  padding-bottom: 10px;
  font-size: 14px;
}
.nav-side-menu .logo-area a {
  display: inline-block;
}
.nav-side-menu .menu-list ul {
  list-style: none;
  margin: 0px;
  cursor: pointer;
}
.nav-side-menu .menu-list ul .collapsed .arrow:before {
  font-family: FontAwesome;
  content: "\f078";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  float: right;
}
.nav-side-menu .menu-list ul > li:hover {
  background-color: #f1f1f1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.nav-side-menu .menu-list ul > li:hover > a {
  color: #070707;
}
.nav-side-menu .menu-list ul > li > a {
  padding: 10px;
  display: block;
  text-decoration: none;
  color: #212121;
}
.nav-side-menu ul:not(collapsed) .arrow:before {
  font-family: FontAwesome;
  content: "\f053";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  float: right;
}
.nav-side-menu ul .sub-menu li {
  background-color: #ffffff;
  border: none;
  line-height: 28px;
  margin-left: 0px;
  padding-left: 40px;
}
.nav-side-menu ul .sub-menu li a {
  display: inline-block;
  padding: 10px 0;
}
.nav-side-menu ul .sub-menu li:hover {
  background-color: #f1f1f1;
}
.nav-side-menu ul .sub-menu li:hover a {
  color: #070707;
}
@media only screen and (min-width: 767px) {
  .nav-side-menu .menu-list .menu-content {
    display: block;
  }
}
/*-------------------------------------
2.13 Stick Menu Area styles
---------------------------------------*/
.stick .header-bottom {
  position: fixed;
  top: 0px;
  z-index: 9;
  width: 100%;
  margin: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: 2px solid #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.stick .header-bottom .logo-area {
  display: inline-block;
  float: left;
  margin-top: 10px;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -ms-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
.stick .header-bottom .category-menu-title {
  display: none;
}
.stick .header-bottom .category-menu-area {
  display: none;
}
.stick .header-bottom .main-menu-area nav > ul > li {
  padding: 20px 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.stick .header-bottom .home2-sticky-area nav > ul > li {
  padding: 20px 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (min-width: 768px) and (max-width: 991px) {
  .stick .header-bottom .home2-sticky-area nav > ul > li a {
    margin-right: 9px;
    padding-right: 9px;
  }
}
/*-------------------------------------
2.14 Mobile Menu Area styles
---------------------------------------*/
.mobile-menu-area {
  display: none;
}
.mean-container .mean-bar {
  border-bottom: 1px solid #b6b6b6;
  float: none;
}
@media only screen and (max-width: 767px) {
  .mean-container .mean-bar a {
    padding: 10px 16px;
    display: inline-block;
  }
}
@media only screen and (max-width: 767px) {
  .mean-container .mean-bar .meanmenu-reveal {
    margin-top: 5px;
  }
}
.mean-container .mean-bar:after {
  content: "";
  clear: both;
  display: block;
}
.mean-container .mean-nav ul li a {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.mean-container .mean-nav ul li a:hover {
  color: #070707;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.mean-container .mean-nav > ul:after {
  content: "";
  display: block;
  clear: both;
}
.mean-container .mean-nav > ul {
  overflow-y: scroll;
}
.mean-container .mean-bar,
.mean-container .mean-nav {
  background: transparent !important;
}
.mean-container .mean-bar {
  padding: 0 !important;
  position: fixed !important;
}
.mean-container .mean-bar a.logo-mobile-menu {
  width: 100%;
  background-color: rgba(241, 241, 241, 0.95);
  box-sizing: border-box;
}
.mean-container a.meanmenu-reveal {
  float: none !important;
  position: absolute;
}
.mean-bar + div.wrapper-area {
  padding-top: 52px;
}
/*=========================================
3. Slider Area
==========================================*/
/*----------------------------------------
3.1 Slider 1 styles
----------------------------------------*/
.slider-area {
  position: relative;
}
.slider-area .header-cart-area {
  position: absolute;
  z-index: 10;
  top: 10px;
}
@media only screen and (max-width: 991px) {
  .slider-area .header-cart-area {
    top: 5px;
  }
}
@media only screen and (max-width: 991px) {
  .slider-area .header-cart-area > li {
    width: 20px;
  }
}
.slider-area .header-cart-area .search-form {
  right: 30px;
  left: inherit;
}
@media only screen and (max-width: 1199px) {
  .main-slider1 {
    padding-top: 0;
  }
}
.main-slider1 .slider-1 h2 {
  letter-spacing: 8px;
  text-transform: uppercase;
  margin-bottom: 0;
  left: 15%;
  position: relative;
  font-size: 14px;
  color: #212121;
}
@media only screen and (max-width: 479px) {
  .main-slider1 .slider-1 h2 {
    display: none;
  }
}
.main-slider1 .slider-1 .title1 {
  left: 15%;
  position: relative;
  font-size: 48px;
  margin-bottom: 0;
  font-weight: 600;
  color: #212121;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider1 .slider-1 .title1 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider1 .slider-1 .title1 {
    left: 5%;
    font-size: 30px;
  }
}
.main-slider1 .slider-1 h3 {
  left: 15%;
  position: relative;
  font-size: 24px;
  letter-spacing: 4px;
  color: #212121;
  text-transform: uppercase;
  margin-bottom: 10px;
}
@media only screen and (max-width: 479px) {
  .main-slider1 .slider-1 h3 {
    left: 5%;
    font-size: 18px;
  }
}
.main-slider1 .slider-1 p {
  left: 15%;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  max-width: 436px;
  border-top: 1px solid #bbbbbb;
  padding: 20px 0 0;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1199px) {
  .main-slider1 .slider-1 p {
    display: none;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider1 .slider-1 p {
    left: 5%;
  }
}
.main-slider1 .slider-1 a {
  left: 15%;
  position: relative;
  display: inline-block !important;
}
@media only screen and (max-width: 1199px) {
  .main-slider1 .slider-1 a {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider1 .slider-1 a {
    display: none !important;
  }
}
.main-slider1 .layer-1 img {
  left: 5%;
  width: 20%;
}
.main-slider1 .layer-2 img {
  left: 37%;
  width: 20%;
}
.main-slider1 .layer-3 img {
  left: 66%;
  width: 33%;
}
.main-slider1 .layer-1-1 img {
  left: 60%;
  width: 43%;
}
.main-slider1 .nivo-caption {
  z-index: 1;
}
.main-slider1 .nivo-controlNav {
  display: none;
}
@media only screen and (max-width: 767px) {
  .main-slider1 .preview-2 .nivo-directionNav a.nivo-nextNav::before {
    height: 45px;
    width: 45px;
    line-height: 45px;
    font-size: 18px;
  }
  .main-slider1 .preview-2 .nivo-directionNav a.nivo-prevNav::before {
    height: 45px;
    width: 45px;
    line-height: 45px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider1 .preview-2 .nivo-directionNav a.nivo-nextNav::before {
    height: 40px;
    width: 40px;
    line-height: 40px;
    top: -12px;
    font-size: 16px;
    right: -60px;
  }
  .main-slider1 .preview-2 .nivo-directionNav a.nivo-prevNav::before {
    height: 40px;
    width: 40px;
    line-height: 40px;
    top: -12px;
    font-size: 16px;
    left: -12px;
  }
}
.main-slider1 .nivo-slice {
  display: block;
  position: absolute;
  z-index: 5;
  height: 100%;
  top: 0;
  opacity: 0 !important;
}
.main-slider1 .preview-2 .nivo-directionNav a.nivo-prevNav:hover::before {
  background: #111111;
  color: #ffffff;
}
.main-slider1 .preview-2 .nivo-directionNav a.nivo-nextNav:hover::before {
  background: #111111;
  color: #ffffff;
}
/*----------------------------------------
3.2 Slider 2 styles
----------------------------------------*/
.main-slider2 .nivoSlider .nivo-caption {
  z-index: 9;
  opacity: 1;
  padding: 0;
}
.main-slider2 .preview-1 .nivo-controlNav {
  position: absolute;
}
.main-slider2 .preview-1 .nivo-controlNav a {
  display: none;
}
.main-slider2 .preview-1 .nivo-directionNav a {
  padding-top: 14px;
  height: 50px;
  text-indent: inherit;
  text-transform: uppercase;
  color: #f1f1f1;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
}
.main-slider2 .preview-1 .nivo-directionNav a:hover {
  color: #111111;
}
.main-slider2 .preview-1 a.nivo-prevNav {
  background: url(./lib/custom-slider/img/left-arrow.png);
  background-position: left;
  width: 66px;
  text-align: right;
  background-repeat: no-repeat;
}
.main-slider2 .preview-1 a.nivo-nextNav {
  padding-right: 25px;
  background: url(lib/custom-slider/img/right-arrow.png);
  background-position: right;
  width: 66px;
  text-align: left;
  background-repeat: no-repeat;
}
.main-slider2 .slider-1 h2 {
  font-weight: 300;
  color: #212121;
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 60px;
  left: 10%;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider2 .slider-1 h2 {
    font-size: 42px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-slider2 .slider-1 h2 {
    font-size: 30px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .main-slider2 .slider-1 h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider2 .slider-1 h2 {
    font-size: 18px;
  }
}
.main-slider2 .slider-1 h2 span {
  font-weight: 600;
  color: #212121;
}
.main-slider2 .slider-1 p {
  left: 10%;
  position: relative;
  color: #212121;
}
@media only screen and (max-width: 1199px) {
  .main-slider2 .slider-1 p {
    padding-right: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider2 .slider-1 p {
    display: none;
  }
}
.main-slider2 .slider-1 a {
  margin-top: 30px;
  left: 10%;
  position: relative;
  display: inline-block !important;
  color: #070707;
  font-weight: 600;
}
@media (min-width: 480px) and (max-width: 767px) {
  .main-slider2 .slider-1 a {
    margin-top: 10px;
    padding: 10px 30px;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider2 .slider-1 a {
    display: none !important;
  }
}
.main-slider2 .slider-3 h2 {
  right: 10%;
  position: relative;
  font-weight: 300;
  color: #212121;
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 60px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider2 .slider-3 h2 {
    font-size: 42px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-slider2 .slider-3 h2 {
    font-size: 30px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .main-slider2 .slider-3 h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider2 .slider-3 h2 {
    font-size: 18px;
  }
}
.main-slider2 .slider-3 h2 span {
  font-weight: 600;
}
.main-slider2 .slider-3 p {
  right: 10%;
  position: relative;
  color: #212121;
}
@media only screen and (max-width: 1199px) {
  .main-slider2 .slider-3 p {
    padding-left: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider2 .slider-3 p {
    display: none;
  }
}
.main-slider2 .slider-3 a {
  margin-top: 30px;
  right: 10%;
  position: relative;
  display: inline-block !important;
  color: #070707;
  font-weight: 600;
}
@media (min-width: 480px) and (max-width: 767px) {
  .main-slider2 .slider-3 a {
    margin-top: 10px;
    padding: 10px 30px;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider2 .slider-3 a {
    display: none !important;
  }
}
/*----------------------------------------
3.3 Slider 3 styles
----------------------------------------*/
.main-slider3 .nivoSlider .nivo-caption {
  z-index: 9;
  opacity: 1;
  padding: 0;
}
.bend.niceties.preview-1 {
  position: relative;
}
.main-slider3 .preview-1 .nivo-controlNav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
}
@media only screen and (max-width: 479px) {
  .main-slider3 .preview-1 .nivo-controlNav {
    bottom: -15px;
  }
}
.bend.niceties.preview-1 {
  z-index: 1;
}
.main-slider3 .preview-1 .nivo-directionNav a {
  display: none;
}
.main-slider3 .slider-1 span {
  display: block;
  right: 10%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 20px;
  font-size: 18px;
  color: #212121;
  font-weight: 600;
}
@media only screen and (max-width: 1199px) {
  .main-slider3 .slider-1 span {
    right: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider3 .slider-1 span {
    letter-spacing: 4px;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider3 .slider-1 span {
    font-size: 16px;
  }
}
.main-slider3 .slider-1 h2 {
  right: 10%;
  position: relative;
  color: #212121;
  text-transform: uppercase;
  font-size: 60px;
}
.main-slider3 .slider-1 h2 span {
  font-weight: 600;
}
@media only screen and (max-width: 1199px) {
  .main-slider3 .slider-1 h2 {
    right: 5%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider3 .slider-1 h2 {
    font-size: 32px;
  }
}
@media (min-width: 480px) and (max-width: 991px) {
  .main-slider3 .slider-1 h2 {
    font-size: 28px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider3 .slider-1 h2 {
    display: none;
  }
}
.main-slider3 .slider-1 p {
  padding-left: 30px;
  right: 10%;
  position: relative;
  color: #212121;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
@media only screen and (max-width: 1199px) {
  .main-slider3 .slider-1 p {
    right: 5%;
    padding-left: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider3 .slider-1 p {
    display: none;
  }
}
.main-slider3 .slider-1 a {
  margin-top: 30px;
  right: 10%;
  position: relative;
  display: inline-block !important;
  color: #070707;
  font-weight: 600;
}
@media only screen and (max-width: 1199px) {
  .main-slider3 .slider-1 a {
    right: 5%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider3 .slider-1 a {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider3 .slider-1 a {
    display: none !important;
  }
}
.main-slider3 .slider-2 h2 {
  color: #212121;
  text-transform: uppercase;
  font-size: 60px;
  left: 10%;
  position: relative;
}
.main-slider3 .slider-2 h2 span {
  font-weight: 600;
}
@media only screen and (max-width: 1199px) {
  .main-slider3 .slider-2 h2 {
    left: 5%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider3 .slider-2 h2 {
    font-size: 42px;
  }
}
@media (min-width: 480px) and (max-width: 991px) {
  .main-slider3 .slider-2 h2 {
    font-size: 30px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider3 .slider-2 h2 {
    font-size: 20px;
  }
}
.main-slider3 .slider-2 p {
  left: 10%;
  position: relative;
  color: #212121;
}
@media only screen and (max-width: 1199px) {
  .main-slider3 .slider-2 p {
    left: 5%;
    padding-right: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider3 .slider-2 p {
    display: none;
  }
}
.main-slider3 .slider-2 a {
  margin-top: 30px;
  left: 10%;
  position: relative;
  display: inline-block !important;
  color: #070707;
  font-weight: 600;
}
@media only screen and (max-width: 1199px) {
  .main-slider3 .slider-2 a {
    left: 5%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider3 .slider-2 a {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider3 .slider-2 a {
    display: none !important;
  }
}
/*----------------------------------------
3.4 Slider 4 styles
----------------------------------------*/
.main-slider4 .preview-1 .nivo-controlNav {
  position: absolute;
  left: 6%;
}
@media only screen and (max-width: 1199px) {
  .main-slider4 .preview-1 .nivo-controlNav {
    bottom: -10px;
  }
}
.main-slider4 .preview-1 .nivo-directionNav a {
  display: none;
}
.main-slider4 .slider-1 span {
  display: block;
  left: 5%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 15px;
  font-size: 18px;
  color: #212121;
  font-weight: 600;
}
@media only screen and (max-width: 1199px) {
  .main-slider4 .slider-1 span {
    font-size: 16px;
    letter-spacing: 8px;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider4 .slider-1 span {
    letter-spacing: 4px;
  }
}
.main-slider4 .slider-1 h2 {
  border-bottom: 1px solid #b6b6b6;
  letter-spacing: 12px;
  display: inline-block;
  left: 5%;
  position: relative;
  font-weight: 600;
  color: #212121;
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 60px;
}
@media only screen and (max-width: 1199px) {
  .main-slider4 .slider-1 h2 {
    font-size: 36px;
    letter-spacing: 4px;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider4 .slider-1 h2 {
    font-size: 24px;
    margin-top: 10px;
    letter-spacing: 2px;
  }
}
.main-slider4 .slider-1 p {
  left: 5%;
  position: relative;
  color: #212121;
  padding-top: 0;
  padding-bottom: 0;
}
@media only screen and (max-width: 1199px) {
  .main-slider4 .slider-1 p {
    padding-right: 55%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider4 .slider-1 p {
    display: none !important;
  }
}
.main-slider4 .slider-1 a {
  margin-top: 30px;
  left: 5%;
  position: relative;
  display: inline-block !important;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .main-slider4 .slider-1 a {
    display: none !important;
  }
}
.main-slider4 .slider-2 h2 {
  font-weight: 300;
  color: #212121;
  line-height: 1.3;
  text-transform: capitalize;
  font-size: 60px;
  left: 20%;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider4 .slider-2 h2 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider4 .slider-2 h2 {
    font-size: 24px;
  }
}
.main-slider4 .slider-2 h2 span {
  color: #212121;
  text-transform: uppercase;
  font-weight: 600;
}
.main-slider4 .slider-2 p {
  left: 20%;
  position: relative;
  color: #212121;
}
@media only screen and (max-width: 1199px) {
  .main-slider4 .slider-2 p {
    display: none;
  }
}
.main-slider4 .slider-2 a {
  left: 20%;
  position: relative;
  display: inline-block !important;
  font-weight: 600;
  margin-top: 30px;
}
@media only screen and (max-width: 991px) {
  .main-slider4 .slider-2 a {
    margin-top: 10px;
  }
}
.main-slider4 .slider-3 span {
  display: block;
  right: 5%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 15px;
  font-size: 18px;
  color: #212121;
  font-weight: 600;
}
@media only screen and (max-width: 1199px) {
  .main-slider4 .slider-3 span {
    margin-bottom: 10px;
    letter-spacing: 10px;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider4 .slider-3 span {
    letter-spacing: 2px;
  }
}
.main-slider4 .slider-3 h2 {
  border-bottom: 1px solid #b6b6b6;
  letter-spacing: 12px;
  display: inline-block;
  right: 5%;
  position: relative;
  font-weight: 600;
  color: #212121;
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 60px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider4 .slider-3 h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider4 .slider-3 h2 {
    display: none;
  }
}
.main-slider4 .slider-3 p {
  right: 5%;
  position: relative;
  color: #212121;
  padding-bottom: 0;
  padding-top: 0;
}
@media only screen and (max-width: 1199px) {
  .main-slider4 .slider-3 p {
    padding-left: 52%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider4 .slider-3 p {
    display: none;
  }
}
.main-slider4 .slider-3 a {
  right: 5%;
  position: relative;
  display: inline-block !important;
  font-weight: 600;
  margin-top: 30px;
}
@media only screen and (max-width: 991px) {
  .main-slider4 .slider-3 a {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-area-section-hidden {
    display: none;
  }
}
/*----------------------------------------
3.5 Slider 5 styles
----------------------------------------*/
.main-slider5 .nivoSlider .nivo-caption {
  z-index: 9;
  opacity: 1;
  padding: 0;
}
.main-slider5 .preview-1 .nivo-controlNav {
  position: absolute;
}
.main-slider5 .preview-1 .nivo-controlNav a {
  display: none;
}
.main-slider5 .preview-1 .nivo-directionNav a {
  padding-top: 14px;
  height: 50px;
  text-indent: inherit;
  text-transform: uppercase;
  color: #f1f1f1;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
}
.main-slider5 .preview-1 .nivo-directionNav a:hover {
  color: #111111;
}
.main-slider5 .preview-1 a.nivo-prevNav {
  background: url(lib/custom-slider/img/left-arrow.png);
  background-position: left;
  width: 66px;
  text-align: right;
  background-repeat: no-repeat;
}
.main-slider5 .preview-1 a.nivo-nextNav {
  padding-right: 25px;
  background: url(lib/custom-slider/img/right-arrow.png);
  background-position: right;
  width: 66px;
  text-align: left;
  background-repeat: no-repeat;
}
.main-slider5 .slider-1 h2 {
  font-weight: 300;
  color: #ffffff;
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 60px;
  left: calc(50% - 585px + 15px);
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider5 .slider-1 h2 {
    font-size: 42px;
    left: 10%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-slider5 .slider-1 h2 {
    font-size: 30px;
    left: 5%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .main-slider5 .slider-1 h2 {
    font-size: 24px;
    right: 0;
    left: 0;
    text-align: center;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider5 .slider-1 h2 {
    font-size: 18px;
    right: 0;
    left: 0;
    text-align: center;
  }
}
.main-slider5 .slider-1 h2 span {
  font-weight: 600;
}
.main-slider5 .slider-1 p {
  left: calc(50% - 585px + 15px);
  position: relative;
  color: #ffffff;
  width: 20%;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1199px) {
  .main-slider5 .slider-1 p {
    width: 25%;
    left: 10%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider5 .slider-1 p {
    display: none;
  }
}
.main-slider5 .slider-1 a {
  margin-top: 10px;
  left: calc(50% - 585px + 15px);
  position: relative;
  display: inline-block !important;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider5 .slider-1 a {
    left: 10%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-slider5 .slider-1 a {
    left: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider5 .slider-1 a {
    display: none !important;
  }
}
.main-slider5 .slider-2 h2 {
  right: calc(50% - 585px + 15px);
  position: relative;
  font-weight: 300;
  color: #ffffff;
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 60px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider5 .slider-2 h2 {
    font-size: 42px;
    right: 10%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-slider5 .slider-2 h2 {
    font-size: 30px;
    right: 5%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .main-slider5 .slider-2 h2 {
    font-size: 24px;
    right: 0;
    left: 0;
    text-align: center;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider5 .slider-2 h2 {
    font-size: 18px;
    right: 0;
    left: 0;
    text-align: center;
  }
}
.main-slider5 .slider-2 h2 span {
  font-weight: 600;
}
.main-slider5 .slider-2 p {
  right: calc(50% - 585px + 15px);
  position: relative;
  color: #ffffff;
  padding-left: 80%;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1199px) {
  .main-slider5 .slider-2 p {
    padding-left: 60%;
    right: 10%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider5 .slider-2 p {
    display: none;
  }
}
.main-slider5 .slider-2 a {
  margin-top: 10px;
  right: calc(50% - 585px + 15px);
  position: relative;
  display: inline-block !important;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider5 .slider-2 a {
    right: 10%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-slider5 .slider-2 a {
    right: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider5 .slider-2 a {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider5 .slider-content {
    text-align: center;
  }
}
/*----------------------------------------
3.6 Slider 6 styles
----------------------------------------*/
.main-slider6 .nivoSlider .nivo-caption {
  z-index: 9;
  opacity: 1;
  padding: 0;
}
.main-slider6 .preview-1 .nivo-caption a {
  border-bottom: 0;
}
.main-slider6 .preview-1 .nivo-controlNav {
  position: absolute;
}
.main-slider6 .preview-1 .nivo-controlNav a {
  display: none;
}
.main-slider6 .preview-1 .nivo-directionNav a {
  padding-top: 14px;
  height: 50px;
  text-indent: inherit;
  text-transform: uppercase;
  color: #cf9900;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
}
.main-slider6 .preview-1 .nivo-directionNav a:hover {
  color: #111111;
}
.main-slider6 .preview-1 a.nivo-prevNav {
  background: url(lib/custom-slider/img/left-arrow.png);
  background-position: left;
  width: 66px;
  text-align: right;
  background-repeat: no-repeat;
}
.main-slider6 .preview-1 a.nivo-nextNav {
  padding-right: 25px;
  background: url(lib/custom-slider/img/right-arrow.png);
  background-position: right;
  width: 66px;
  text-align: left;
  background-repeat: no-repeat;
}
.main-slider6 .slider-1 h2 {
  font-weight: 300;
  color: #212121;
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 60px;
  left: calc(50% - 585px + 15px);
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider6 .slider-1 h2 {
    font-size: 42px;
    left: 10%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-slider6 .slider-1 h2 {
    font-size: 36px;
    left: 5%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .main-slider6 .slider-1 h2 {
    font-size: 30px;
    text-align: center;
    left: 0;
    right: 0;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider6 .slider-1 h2 {
    font-size: 24px;
    text-align: center;
    left: 0;
    right: 0;
  }
}
.main-slider6 .slider-1 h2 span {
  font-weight: 600;
  color: #cf9900;
}
.main-slider6 .slider-1 p {
  left: calc(50% - 585px + 15px);
  position: relative;
  color: #212121;
  width: 25%;
}
@media only screen and (max-width: 1199px) {
  .main-slider6 .slider-1 p {
    width: 25%;
    left: 10%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider6 .slider-1 p {
    display: none;
  }
}
.main-slider6 .slider-1 a {
  margin-top: 30px;
  left: calc(50% - 585px + 15px);
  position: relative;
  display: inline-block !important;
  background: #111111;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  padding: 10px 30px;
  border: 1px solid #111111 !important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 1199px) {
  .main-slider6 .slider-1 a {
    padding: 10px 30px;
    left: 10%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider6 .slider-1 a {
    padding: 8px 25px;
    left: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider6 .slider-1 a {
    display: none !important;
  }
}
.main-slider6 .slider-1 a:hover {
  border: 1px solid #cf9900 !important;
  background: transparent;
  color: #cf9900 !important;
}
.main-slider6 .slider-2 h2 {
  right: calc(50% - 585px + 15px);
  position: relative;
  font-weight: 300;
  color: #212121;
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 60px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider6 .slider-2 h2 {
    font-size: 42px;
    right: 10%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-slider6 .slider-2 h2 {
    font-size: 36px;
    right: 5%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .main-slider6 .slider-2 h2 {
    font-size: 30px;
    right: 0;
    left: 0;
    text-align: center;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider6 .slider-2 h2 {
    font-size: 24px;
    right: 0;
    left: 0;
    text-align: center;
  }
}
.main-slider6 .slider-2 h2 span {
  font-weight: 600;
  color: #cf9900;
}
.main-slider6 .slider-2 p {
  right: calc(50% - 585px + 15px);
  position: relative;
  color: #212121;
  padding-left: 80%;
}
@media only screen and (max-width: 1199px) {
  .main-slider6 .slider-2 p {
    padding-left: 70%;
    right: 10%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider6 .slider-2 p {
    display: none;
  }
}
.main-slider6 .slider-2 a {
  margin-top: 30px;
  right: calc(50% - 585px + 15px);
  position: relative;
  display: inline-block !important;
  background: #111111;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  padding: 10px 30px;
  border: 1px solid #111111 !important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 1199px) {
  .main-slider6 .slider-2 a {
    padding: 10px 30px;
    right: 10%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider6 .slider-2 a {
    padding: 8px 25px;
    right: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider6 .slider-2 a {
    display: none !important;
  }
}
.main-slider6 .slider-2 a:hover {
  border: 1px solid #cf9900 !important;
  background: transparent;
  color: #cf9900 !important;
}
/*----------------------------------------
3.7 Slider 7 styles
----------------------------------------*/
.main-slider7 .nivoSlider .nivo-caption {
  z-index: 9;
  opacity: 1;
  padding: 0;
}
.main-slider7 .preview-1 .nivo-controlNav {
  position: absolute;
}
.main-slider7 .preview-1 .nivo-controlNav a {
  display: none;
}
.main-slider7 .preview-1 .nivo-directionNav a {
  padding-top: 14px;
  height: 50px;
  text-indent: inherit;
  text-transform: uppercase;
  color: #212121;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
}
.main-slider7 .preview-1 .nivo-directionNav a:hover {
  color: #ffffff;
}
.main-slider7 .preview-1 a.nivo-prevNav {
  background: url(lib/custom-slider/img/left-arrow.png);
  background-position: left;
  width: 66px;
  text-align: right;
  background-repeat: no-repeat;
}
.main-slider7 .preview-1 a.nivo-nextNav {
  padding-right: 25px;
  background: url(lib/custom-slider/img/right-arrow.png);
  background-position: right;
  width: 66px;
  text-align: left;
  background-repeat: no-repeat;
}
.main-slider7 h2 {
  font-weight: 700;
  color: #212121;
  line-height: 1;
  text-transform: uppercase;
  font-size: 60px;
  position: relative;
}
.main-slider7 h2 span {
  color: #ffffff;
  font-size: 30px;
}
.main-slider7 .slider-1 h2 {
  left: calc(50% - 585px + 15px);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider7 .slider-1 h2 {
    font-size: 42px;
    left: 10%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-slider7 .slider-1 h2 {
    font-size: 36px;
    left: 5%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .main-slider7 .slider-1 h2 {
    font-size: 30px;
    text-align: center;
    left: 0;
    right: 0;
    color: #ffffff;
    line-height: 1.2;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider7 .slider-1 h2 {
    font-size: 24px;
    text-align: center;
    left: 0;
    right: 0;
    color: #ffffff;
    line-height: 1.1;
  }
  .main-slider7 .slider-1 h2 span {
    font-size: 24px;
  }
}
.main-slider7 .slider-2 h2 {
  right: calc(50% - 585px + 15px);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-slider7 .slider-2 h2 {
    font-size: 42px;
    right: 10%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-slider7 .slider-2 h2 {
    font-size: 36px;
    right: 5%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .main-slider7 .slider-2 h2 {
    font-size: 30px;
    right: 0;
    left: 0;
    text-align: center;
    color: #ffffff;
    line-height: 1.2;
  }
}
@media only screen and (max-width: 479px) {
  .main-slider7 .slider-2 h2 {
    font-size: 24px;
    right: 0;
    left: 0;
    text-align: center;
    color: #ffffff;
    line-height: 1.1;
  }
  .main-slider7 .slider-2 h2 span {
    font-size: 24px;
  }
}
.main-slider7 .slider-1 p {
  left: calc(50% - 585px + 15px);
  position: relative;
  color: #212121;
  width: 20%;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1199px) {
  .main-slider7 .slider-1 p {
    display: none;
  }
}
.main-slider7 a.item-btn {
  margin-top: 30px;
  position: relative;
  display: inline-block;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 6px 0px rgba(223, 197, 81, 0.75);
  -moz-box-shadow: 0px 1px 6px 0px rgba(223, 197, 81, 0.75);
  box-shadow: 0px 1px 6px 0px rgba(223, 197, 81, 0.75);
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  border-radius: 45px;
  color: #212121;
  text-align: center;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.main-slider7 a.item-btn i {
  margin-left: 10px;
}
.main-slider7 a.item-btn:hover {
  background-color: transparent;
}
.main-slider7 .slider-1 a.item-btn {
  left: calc(50% - 585px + 15px);
}
@media only screen and (max-width: 1199px) {
  .main-slider7 .slider-1 a.item-btn {
    left: 10%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider7 .slider-1 a.item-btn {
    left: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider7 .slider-1 a.item-btn {
    display: none !important;
  }
}
.main-slider7 .slider-2 a.item-btn {
  right: calc(50% - 585px + 15px);
}
@media only screen and (max-width: 1199px) {
  .main-slider7 .slider-2 a.item-btn {
    right: 10%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider7 .slider-2 a.item-btn {
    right: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider7 .slider-2 a.item-btn {
    display: none !important;
  }
}
.main-slider7 .slider-2 p {
  right: calc(50% - 585px + 15px);
  position: relative;
  color: #212121;
  padding-left: 80%;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1199px) {
  .main-slider7 .slider-2 p {
    display: none;
  }
}
.main-slider7 .item-price {
  position: relative;
  margin-bottom: 40px;
}
.main-slider7 .item-price li {
  display: inline-block;
  margin-right: 10px;
}
.main-slider7 .item-price li:last-child {
  margin-right: 0;
}
.main-slider7 .item-price li.prev-price {
  font-size: 20px;
  text-decoration: line-through;
  color: #b0941a;
}
.main-slider7 .item-price li.current-price {
  font-size: 36px;
  color: #212121;
  font-weight: 600;
}
.main-slider7 .slider-1 .item-price {
  left: calc(50% - 585px + 15px);
}
@media only screen and (max-width: 1199px) {
  .main-slider7 .slider-1 .item-price {
    left: 10%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider7 .slider-1 .item-price {
    left: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider7 .slider-1 .item-price {
    display: none !important;
  }
}
.main-slider7 .slider-2 .item-price {
  right: calc(50% - 585px + 15px);
}
@media only screen and (max-width: 1199px) {
  .main-slider7 .slider-2 .item-price {
    right: 10%;
  }
}
@media only screen and (max-width: 991px) {
  .main-slider7 .slider-2 .item-price {
    right: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .main-slider7 .slider-2 .item-price {
    display: none !important;
  }
}
/*=========================================
4. Inner Page Bennar Area styles
==========================================*/
.inner-page-banner-area {
  background-color: #f1f1f1;
  /* background-size: cover; */
  /* background-position: center;
  background-repeat: no-repeat; */
  text-align: center;
  position: relative;
  min-height: 237px;
}
@media only screen and (max-width: 479px) {
  .inner-page-banner-area {
    min-height: 150px;
  }
}
.inner-page-banner-area:after {
  position: absolute;
  content: "";
  background-color: #f1f1f1;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.inner-page-banner-area .breadcrumb-area {
  position: absolute;
  z-index: 2;
  top: 50px;
  left: 0;
  right: 0;
}
.inner-page-banner-area .breadcrumb-area h1 {
  letter-spacing: 4px;
  font-size: 30px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #212121;
}
@media (min-width: 321px) and (max-width: 479px) {
  .inner-page-banner-area .breadcrumb-area h1 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 320px) {
  .inner-page-banner-area .breadcrumb-area h1 {
    font-size: 20px;
  }
}
.inner-page-banner-area .breadcrumb-area ul li {
  display: inline-block;
  color: #337ab7;
}
.inner-page-banner-area .breadcrumb-area ul li a {
  color: #337ab7;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.inner-page-banner-area .breadcrumb-area ul li a:hover {
  color: #337ab7;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}


.inner-page-banner-area .custom-banner-area i {
  color: #eebe2c !important;
}
/*=========================================
5. Pagination styles
==========================================*/
.mypagination {
  text-align: center;
  margin-top: 25px;
}
@media only screen and (max-width: 991px) {
  .mypagination {
    margin-top: 0;
  }
}
.mypagination li {
  display: inline-block;
  margin: 0 1px;
}
.mypagination li a {
  width: 42px;
  font-size: 16px;
  color: #111111;
  height: 40px;
  background: transparent;
  display: block;
  line-height: 36px;
  text-align: center;
  border: 1px solid #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.mypagination li a:hover {
  background: #111111;
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
/*======================================
6. About Us Page Area styles
========================================*/
.about-us-page-area {
  padding: 45px 0 25px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about-us-page-area {
    padding: 45px 0 25px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .about-us-page-area {
    padding: 45px 0 25px;
  }
}
@media only screen and (max-width: 479px) {
  .about-us-page-area {
    padding: 35px 0 25px;
  }
}
.about-us-page-area .about-us-page-left {
  position: relative;
  display: inline-block;
}
@media only screen and (max-width: 479px) {
  .about-us-page-area .about-us-page-left {
    margin-bottom: 20px;
  }
}
.about-us-page-area .about-us-page-left:after {
  position: absolute;
  content: "";
  background: transparent;
  height: 100%;
  width: 100%;
  border: 6px solid rgba(204, 204, 204, 0.8);
  bottom: 15px;
  right: 15px;
}
.about-us-page-area .about-us-page-right h2 {
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #111111;
}
@media only screen and (max-width: 479px) {
  .about-us-page-area .about-us-page-right h2 {
    font-size: 30px;
  }
}
/*=========================================
7. Services Area
==========================================*/
/*-------------------------------------
7.1 Services1 Area styles
-------------------------------------*/
.services1-area {
  padding-top: 30px;
}
@media only screen and (max-width: 479px) {
  .services1-area {
    padding-top: 0;
  }
}
.services1-area .services-area-box {
  padding: 20px 8px 0 20px;
  background: #f1f1f1;
}
@media only screen and (max-width: 1440px) {
  .services1-area .services-area-box {
    padding: 8px 8px 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .services1-area .services-area-box {
    text-align: center;
    margin: 10px auto;
  }
}
@media (min-width: 479px) and (max-width: 767px) {
  .services1-area .services-area-box {
    margin: 10px auto;
  }
}
@media only screen and (max-width: 479px) {
  .services1-area .services-area-box {
    margin: 10px auto;
  }
}
@media only screen and (max-width: 1440px) {
  .services1-area .services-area-box .media {
    text-align: center;
  }
}
@media only screen and (max-width: 1440px) {
  .services1-area .services-area-box .media a {
    float: none !important;
  }
}
@media only screen and (max-width: 1440px) {
  .services1-area .services-area-box .media a img {
    display: inherit;
  }
}
.services1-area .services-area-box .media-body {
  padding-top: 20px;
}
.services1-area .services-area-box .media-body span {
  font-weight: 600;
  color: #212121;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.services1-area .services-area-box .media-body h3 {
  letter-spacing: 4px;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 0px;
}
@media only screen and (max-width: 1199px) {
  .services1-area .services-area-box .media-body h3 {
    font-size: 22px;
  }
}
.services1-area .services-area-box .media-body h3 a {
  color: #333333;
}
.services1-area .services-area-box .media-body p {
  letter-spacing: 9px;
  text-transform: uppercase;
  color: #212121;
  font-weight: 500;
}
@media (min-width: 768px) and (max-width: 991px) {
  .services1-area .services-area-box .media-body p {
    letter-spacing: 7px;
  }
}
.services1-area .services-area-box .media-body > a {
  float: right;
  margin: 0 3px 10px;
}
@media only screen and (max-width: 1440px) {
  .services1-area .services-area-box .media-body > a {
    display: inline-block;
  }
}
.services1-area .services-area-box .btn-shop-now {
  padding: 10px 20px;
}
@media (min-width: 480px) and (max-width: 767px) {
  .services1-area .col-xs-12 {
    width: 50%;
  }
}
/*-------------------------------------
7.2 Services2 Area styles
-------------------------------------*/
.services2-area {
  text-align: center;
}
.services2-area .row > div {
  padding: 0;
}
.services2-area .services2-box {
  display: inline-block;
  position: relative;
}
@media only screen and (max-width: 479px) {
  .services2-area .services2-box {
    margin-bottom: 15px;
  }
}
.services2-area .services2-box .services2-box-content {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 90px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (min-width: 768px) and (max-width: 1199px) {
  .services2-area .services2-box .services2-box-content {
    left: 0;
    right: 0;
  }
}
@media only screen and (max-width: 479px) {
  .services2-area .services2-box .services2-box-content {
    left: 0;
    right: 0;
  }
}
.services2-area .services2-box .services2-box-content span {
  padding-left: 76px;
  position: relative;
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .services2-area .services2-box .services2-box-content span {
    padding-left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .services2-area .services2-box .services2-box-content span {
    padding-left: 0;
  }
}
.services2-area .services2-box .services2-box-content span:before {
  position: absolute;
  top: 11px;
  left: 0;
  content: "";
  background: #070707;
  height: 2px;
  width: 60px;
  z-index: 2;
}
@media only screen and (max-width: 479px) {
  .services2-area .services2-box .services2-box-content span:before {
    display: none;
  }
}
.services2-area .services2-box .services2-box-content h2 {
  text-transform: uppercase;
  letter-spacing: 2px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .services2-area .services2-box .services2-box-content h2 {
    font-size: 22px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .services2-area .services2-box .services2-box-content h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 479px) {
  .services2-area .services2-box .services2-box-content h2 {
    font-size: 24px;
  }
}
.services2-area .services2-box .services2-box-content h2 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.services2-area .services2-box .services2-box-content h2 a:hover {
  color: #070707;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
@media (min-width: 768px) and (max-width: 991px) {
  .services2-area .services2-box .services2-box-content .btn-services-shop-now {
    padding: 5px 25px;
  }
}
/*-------------------------------------
7.3 Services3 Area styles
-------------------------------------*/
.services3-area {
  padding-top: 30px;
}
.services3-area .services-area-box {
  background: #f1f1f1;
}
@media (min-width: 992px) and (max-width: 1440px) {
  .services3-area .services-area-box {
    text-align: center;
  }
}
@media only screen and (max-width: 479px) {
  .services3-area .services-area-box {
    text-align: center;
  }
}
@media only screen and (max-width: 991px) {
  .services3-area .services-area-box {
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1440px) {
  .services3-area .services-area-box .media a {
    float: inherit !important;
  }
}
@media only screen and (max-width: 479px) {
  .services3-area .services-area-box .media a {
    float: inherit !important;
  }
}
@media (min-width: 992px) and (max-width: 1440px) {
  .services3-area .services-area-box .media a img {
    display: inline-block;
  }
}
@media only screen and (max-width: 479px) {
  .services3-area .services-area-box .media a img {
    display: inline-block;
  }
}
.services3-area .services-area-box .media .media-body {
  padding: 50px 0 10px 30px;
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .services3-area .services-area-box .media .media-body {
    padding: 30px 20px 10px 20px;
  }
}
@media (min-width: 480px) and (max-width: 1199px) {
  .services3-area .services-area-box .media .media-body {
    padding: 50px 0 10px 0;
  }
}
@media only screen and (max-width: 991px) {
  .services3-area .services-area-box .media .media-body {
    padding: 15px 30px 10px 30px;
  }
}
.services3-area .services-area-box .media .media-body span {
  font-weight: 600;
  color: #212121;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.services3-area .services-area-box .media .media-body h3 {
  letter-spacing: 4px;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.services3-area .services-area-box .media .media-body h3 a {
  color: #333333;
}
.services3-area .services-area-box .media .media-body p {
  letter-spacing: 13px;
  text-transform: uppercase;
  color: #212121;
  font-weight: 500;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .services3-area .services-area-box .media .media-body p {
    letter-spacing: 4px;
  }
}
.services3-area .services-area-box .media .media-body > a {
  padding: 10px 20px;
  float: right;
  margin: 0 10px 10px;
}
@media only screen and (max-width: 479px) {
  .services3-area .services-area-box .media .media-body > a {
    display: inline-block;
  }
}
/*-------------------------------------
7.4 Services4 Area styles
-------------------------------------*/
.services4-area {
  margin-bottom: 30px;
}
.services4-area .services-box-layout1 {
  margin-bottom: 30px;
}
/*-------------------------------------
7.5 Services5 Area styles
-------------------------------------*/
.services5-area .services-area-box {
  padding: 20px 8px 20px 20px;
}
@media only screen and (max-width: 1440px) {
  .services5-area .services-area-box {
    padding: 8px 8px 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .services5-area .services-area-box {
    text-align: center;
    margin: 10px auto;
  }
}
@media (min-width: 479px) and (max-width: 767px) {
  .services5-area .services-area-box {
    margin: 10px auto;
  }
}
@media only screen and (max-width: 479px) {
  .services5-area .services-area-box {
    margin: 10px auto;
  }
}
@media only screen and (max-width: 1440px) {
  .services5-area .services-area-box .media {
    text-align: center;
  }
}
@media only screen and (max-width: 1440px) {
  .services5-area .services-area-box .media a {
    float: none !important;
  }
}
@media only screen and (max-width: 1440px) {
  .services5-area .services-area-box .media a img {
    display: inherit;
  }
}
.services5-area .services-area-box .media-body {
  padding-top: 20px;
}
.services5-area .services-area-box .media-body h3 {
  font-size: 36px;
  text-transform: uppercase;
  margin-bottom: 0px;
}
@media only screen and (max-width: 1199px) {
  .services5-area .services-area-box .media-body h3 {
    font-size: 22px;
  }
}
.services5-area .services-area-box .media-body h3 a {
  color: #333333;
}
.services5-area .services-area-box .media-body h3 a span {
  color: #cf9900;
  font-weight: 700;
}
.services5-area .services-area-box .item-btn {
  display: inline-block;
  padding: 10px 20px;
}
.services5-area .col-xs-12:nth-child(1n) {
  background-color: #eff2d5;
}
.services5-area .col-xs-12:nth-child(2n) {
  background-color: #edefe9;
}
.services5-area .col-xs-12:nth-child(3n) {
  background-color: #f2ebd5;
}
/*-------------------------------------
7.6 Services6 Area styles
-------------------------------------*/
.services6-area .services-box {
  padding: 20px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .services6-area .services-box .media {
    margin-left: 0;
  }
}
.services6-area .services-box .media-body {
  padding-left: 10px;
}
.services6-area .services-box .prev-price {
  margin-top: 100px;
  font-size: 20px;
  color: #ffffff;
  text-decoration: line-through;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .services6-area .services-box .prev-price {
    margin-top: 0;
  }
}
.services6-area .services-box .price {
  font-size: 36px;
  color: #ffffff;
  margin-bottom: 50px;
}
.services6-area .services-box h3 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}
.services6-area .services-box h3 a {
  color: #ffffff;
}
.services6-area .services-box p {
  color: #ffffff;
}
/*-------------------------------------
7.7 Services7 Area styles
-------------------------------------*/
.services7-area .services-area-box {
  padding: 20px 8px 0 20px;
}
@media only screen and (max-width: 1440px) {
  .services7-area .services-area-box {
    padding: 8px 8px 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .services7-area .services-area-box {
    text-align: center;
    margin: 10px auto;
  }
}
@media (min-width: 479px) and (max-width: 767px) {
  .services7-area .services-area-box {
    margin: 10px auto;
  }
}
@media only screen and (max-width: 479px) {
  .services7-area .services-area-box {
    margin: 10px auto;
  }
}
@media only screen and (max-width: 1440px) {
  .services7-area .services-area-box .media {
    text-align: center;
  }
}
@media only screen and (max-width: 1440px) {
  .services7-area .services-area-box .media a {
    float: none !important;
  }
}
@media only screen and (max-width: 1440px) {
  .services7-area .services-area-box .media a img {
    display: inherit;
  }
}
.services7-area .services-area-box .media-body {
  padding-top: 20px;
}
.services7-area .services-area-box .media-body h3 {
  font-size: 36px;
  text-transform: uppercase;
  margin-bottom: 0px;
}
@media only screen and (max-width: 1199px) {
  .services7-area .services-area-box .media-body h3 {
    font-size: 22px;
  }
}
.services7-area .services-area-box .media-body h3 a {
  color: #333333;
}
.services7-area .services-area-box .media-body h3 a span {
  color: #ffc400;
  font-weight: 700;
}
.services7-area .services-area-box .item-btn {
  display: inline-block;
  padding: 10px 20px;
}
@media (min-width: 480px) and (max-width: 767px) {
  .services7-area .col-xs-12 {
    width: 50%;
  }
}
.services7-area .col-xs-12:nth-child(1n) {
  background-color: #455a64;
}
.services7-area .col-xs-12:nth-child(2n) {
  background-color: #ffc400;
}
.services7-area .col-xs-12:nth-child(3n) {
  background-color: #1a46c0;
}
/*-------------------------------------
7.8 Services8 Area styles
-------------------------------------*/
.services8-area .services-area-box {
  padding: 20px 8px 0 20px;
}
@media only screen and (max-width: 1440px) {
  .services8-area .services-area-box {
    padding: 8px 8px 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .services8-area .services-area-box {
    text-align: center;
    margin: 10px auto;
  }
}
.services8-area .services-area-box .media {
  padding: 30px;
}
@media only screen and (max-width: 1440px) {
  .services8-area .services-area-box .media {
    text-align: center;
  }
}
@media only screen and (max-width: 1440px) {
  .services8-area .services-area-box .media a {
    float: none !important;
  }
}
@media only screen and (max-width: 1440px) {
  .services8-area .services-area-box .media a img {
    display: inherit;
  }
}
.services8-area .services-area-box .media-body {
  padding-left: 15px;
  padding-top: 20px;
}
@media only screen and (max-width: 767px) {
  .services8-area .services-area-box .media-body {
    padding-left: 0;
  }
}
.services8-area .services-area-box .media-body h3 {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: 700;
}
@media only screen and (max-width: 1199px) {
  .services8-area .services-area-box .media-body h3 {
    font-size: 22px;
  }
}
.services8-area .services-area-box .media-body h3 a {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.services8-area .services-area-box .media-body h3 a:hover {
  color: #111111;
}
.services8-area .services-area-box .media-body h4 {
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
}
.services8-area .services-area-box .media-body p {
  color: #ffffff;
}
.services8-area .services-area-box .item-btn {
  display: inline-block;
  padding: 10px 20px;
}
.services8-area .col-xs-12:nth-child(1n) {
  background-color: #455a64;
}
.services8-area .col-xs-12:nth-child(2n) {
  background-color: #ffc400;
}
.services8-area .col-xs-12:nth-child(3n) {
  background-color: #1a46c0;
}
/*-------------------------------------
7.9 Services9 Area styles
-------------------------------------*/
.services9-area {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .services9-area {
    position: inherit;
    padding: 50px 0 30px;
  }
}
@media only screen and (max-width: 479px) {
  .services9-area {
    position: inherit;
    padding: 40px 0 20px;
  }
}
.services9-area .services9-wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -130px;
  z-index: 8;
  padding-right: 15px;
  padding-left: 15px;
}
@media only screen and (max-width: 767px) {
  .services9-area .services9-wrap {
    position: inherit;
  }
}
.services9-area .services9-wrap .service-box {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .services9-area .services9-wrap .service-box {
    margin-bottom: 30px;
  }
}
.services9-area .services9-wrap .service-box img {
  width: 100%;
}
.services9-area .services9-wrap .service-box .item-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 65px;
  width: 100%;
  padding-left: 30px;
  background-color: rgba(33, 33, 33, 0.9);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 767px) {
  .services9-area .services9-wrap .service-box .item-content {
    height: auto;
    padding: 15px 20px;
  }
}
@media only screen and (max-width: 479px) {
  .services9-area .services9-wrap .service-box .item-content {
    padding: 10px 15px;
  }
}
.services9-area .services9-wrap .service-box .item-content h3 {
  font-weight: 700;
  line-height: 65px;
  text-transform: uppercase;
  font-size: 24px;
}
@media only screen and (max-width: 767px) {
  .services9-area .services9-wrap .service-box .item-content h3 {
    line-height: 1.5;
    font-size: 20px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 479px) {
  .services9-area .services9-wrap .service-box .item-content h3 {
    font-size: 18px;
  }
}
.services9-area .services9-wrap .service-box .item-content h3 a {
  color: #ffffff;
}
.services9-area .services9-wrap .service-box .item-content h3 a span {
  font-weight: 300;
}
.services9-area .services9-wrap .service-box:hover .item-content {
  background-color: rgba(0, 200, 83, 0.9);
}
/*======================================
8. Brand Area styles
========================================*/
.brand-area {
  padding: 80px 0;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .brand-area {
    padding: 70px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .brand-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 479px) {
  .brand-area {
    padding: 50px 0;
  }
}
.brand-area .brand-area-box a img {
  width: 100%;
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 1199px) {
  .brand-area .brand-area-box a img {
    width: auto;
  }
}
.brand-area .brand-area-box a img:hover {
  -webkit-filter: grayscale(0);
  filter: none;
}
.brand-area .owl-nav {
  position: inherit;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.brand-area .owl-nav .owl-prev {
  position: absolute;
  left: -30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  background: #111111 !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.brand-area .owl-nav .owl-next {
  position: absolute;
  right: -30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  background: #111111 !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.brand-area:hover .owl-nav {
  opacity: 1;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.brand-area:hover .owl-nav .owl-prev {
  left: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.brand-area:hover .owl-nav .owl-next {
  right: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
/*======================================
9. Offer Area
========================================*/
/*-------------------------------------
9.1 Offer1 Area styles
-------------------------------------*/
.offer-area1 {
  position: relative;
  background: #f1f1f1;
}
.offer-area1 .brand-area-box-l {
  padding-top: 94px;
}
@media only screen and (max-width: 991px) {
  .offer-area1 .brand-area-box-l {
    padding-top: 80px;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .offer-area1 .brand-area-box-l {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 479px) {
  .offer-area1 .brand-area-box-l {
    padding-top: 60px;
  }
}

.offer-area1 .brand-area-box-l .post-ad-heading{
  font-size: 60px;
  letter-spacing: 2px;
  color: #212121;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 1.3;
}
.offer-area1 .brand-area-box-l span {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 7px;
  color: #212121;
  text-transform: uppercase;
}
@media only screen and (max-width: 320px) {
  .offer-area1 .brand-area-box-l span {
    font-size: 16px;
  }
}
.offer-area1 .brand-area-box-l h1 {
  font-size: 60px;
  letter-spacing: 2px;
  color: #212121;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 1.3;
}
@media only screen and (max-width: 767px) {
  .offer-area1 .brand-area-box-l h1 {
    font-size: 56px;
  }
}
@media only screen and (max-width: 479px) {
  .offer-area1 .brand-area-box-l h1 {
    font-size: 50px;
  }
}
@media only screen and (max-width: 320px) {
  .offer-area1 .brand-area-box-l h1 {
    font-size: 40px;
  }
}
.offer-area1 .brand-area-box-l p {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 7px;
  color: #212121;
  text-transform: uppercase;
}
.offer-area1 .brand-area-box-l a {
  margin-top: 28px;
}
.offer-area1 .countdown {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 40%;
  text-align: center;
  z-index: 1;
}
@media only screen and (max-width: 991px) {
  .offer-area1 .countdown {
    top: 38%;
  }
}
@media only screen and (max-width: 767px) {
  .offer-area1 .countdown {
    top: 37%;
  }
}
@media only screen and (max-width: 479px) {
  .offer-area1 .countdown {
    top: 40%;
  }
}
@media only screen and (max-width: 320px) {
  .offer-area1 .countdown {
    top: 43%;
  }
}
.offer-area1 .countdown .countdown-section {
  padding-top: 7px;
  height: 80px;
  width: 86px;
  background: #111111;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
@media only screen and (max-width: 479px) {
  .offer-area1 .countdown .countdown-section {
    width: 70px;
  }
}
.offer-area1 .countdown .countdown-section:last-child {
  margin-right: 0;
}
.offer-area1 .countdown .countdown-section h3 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 0;
}
.offer-area1 .countdown .countdown-section p {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
}
@media only screen and (max-width: 991px) {
  .offer-area1 .brand-area-box-r {
    margin-top: 140px;
    text-align: center;
  }
}
.offer-area1 .brand-area-box-r a img {
  float: right;
}
@media only screen and (max-width: 991px) {
  .offer-area1 .brand-area-box-r a img {
    float: inherit;
  }
}
/*-------------------------------------
9.2 Offer2 Area styles
-------------------------------------*/
.offer-area2 {
  position: relative;
  background: #f1f1f1;
}
.offer-area2 .brand-area-box-l {
  padding-top: 105px;
  padding-left: 60px;
}
@media only screen and (max-width: 1199px) {
  .offer-area2 .brand-area-box-l {
    padding-top: 80px;
    text-align: center;
    padding-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .offer-area2 .brand-area-box-l {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 479px) {
  .offer-area2 .brand-area-box-l {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 320px) {
  .offer-area2 .brand-area-box-l {
    padding: 50px 5px 0 5px;
  }
}
.offer-area2 .brand-area-box-l span {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 7px;
  color: #212121;
  text-transform: uppercase;
}
@media only screen and (max-width: 320px) {
  .offer-area2 .brand-area-box-l span {
    font-size: 16px;
    letter-spacing: 4px;
    margin-bottom: 15px;
    display: block;
  }
}
.offer-area2 .brand-area-box-l h1 {
  font-size: 60px;
  letter-spacing: 2px;
  color: #212121;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 1.3;
}
@media only screen and (max-width: 767px) {
  .offer-area2 .brand-area-box-l h1 {
    font-size: 56px;
  }
}
@media only screen and (max-width: 479px) {
  .offer-area2 .brand-area-box-l h1 {
    font-size: 50px;
  }
}
@media only screen and (max-width: 320px) {
  .offer-area2 .brand-area-box-l h1 {
    font-size: 40px;
    margin-bottom: 15px;
  }
}
.offer-area2 .brand-area-box-l p {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 7px;
  color: #212121;
  text-transform: uppercase;
  margin-bottom: 35px;
}
.offer-area2 .countdown {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 40%;
  text-align: center;
  z-index: 1;
}
@media only screen and (max-width: 1199px) {
  .offer-area2 .countdown {
    top: 37%;
  }
}
@media only screen and (max-width: 991px) {
  .offer-area2 .countdown {
    top: 38%;
  }
}
@media only screen and (max-width: 767px) {
  .offer-area2 .countdown {
    top: 37%;
  }
}
@media only screen and (max-width: 479px) {
  .offer-area2 .countdown {
    top: 40%;
  }
}
@media only screen and (max-width: 320px) {
  .offer-area2 .countdown {
    top: 43%;
  }
}
.offer-area2 .countdown .countdown-section {
  padding-top: 7px;
  height: 80px;
  width: 86px;
  background: #111111;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
@media only screen and (max-width: 479px) {
  .offer-area2 .countdown .countdown-section {
    width: 68px;
  }
}
.offer-area2 .countdown .countdown-section:last-child {
  margin-right: 0;
}
.offer-area2 .countdown .countdown-section h3 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 0;
}
.offer-area2 .countdown .countdown-section p {
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 13px;
}
@media only screen and (max-width: 1199px) {
  .offer-area2 .brand-area-box-r {
    margin-top: 140px;
    text-align: center;
  }
}
.offer-area2 .brand-area-box-r a img {
  float: right;
}
@media only screen and (max-width: 1199px) {
  .offer-area2 .brand-area-box-r a img {
    float: inherit;
  }
}
/*======================================
10. Isotop Area
========================================*/
/*-------------------------------------
10.1 My Isotop1 Area styles
-------------------------------------*/
.product-area {
  padding: 30px 0 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-area {
    padding: 30px 0 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .product-area {
    padding: 20px 0 30px;
  }
}
@media only screen and (max-width: 479px) {
  .product-area {
    padding: 20px 0 20px;
  }
}
/*-------------------------------------
10.2 My Isotop2 Area styles
-------------------------------------*/
.product2-area {
  padding: 87px 0 64px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .product2-area {
    padding: 57px 0 44px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .product2-area {
    padding: 47px 0 34px;
  }
}
@media only screen and (max-width: 479px) {
  .product2-area {
    padding: 37px 0 24px;
  }
}
/*-------------------------------------
10.3 Isotop Button styles
-------------------------------------*/
.myisotop1 {
  position: relative;
  margin-bottom: 40px;
}
@media only screen and (max-width: 991px) {
  .myisotop1 {
    text-align: center;
  }
}
.myisotop1 a {
  font-weight: 600;
  font-size: 22px;
  color: #727272;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-right: 3px solid #727272;
  margin-right: 25px;
  padding-right: 25px;
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .myisotop1 a {
    letter-spacing: 1px;
    margin-right: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 479px) {
  .myisotop1 a {
    margin-right: 10px;
    padding-right: 10px;
  }
}
.myisotop1 a:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.myisotop1 .current {
  color: #4154f1;
  border-right: 3px solid #4154f1;
}
.myisotop1:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b6b6b6;
  border-top: 1px solid #b6b6b6;
  height: 4px;
  background: transparent;
  width: 45%;
  right: 0;
  top: 35%;
  z-index: 1;
}
@media only screen and (max-width: 1199px) {
  .myisotop1:after {
    display: none;
  }
}
.myisotop2 {
  text-align: center;
  margin-bottom: 40px;
}
.myisotop2:after {
  display: none;
}
.myisotop2 a {
  background: transparent;
  border: 1px solid #b6b6b6;
  color: #111111;
  font-weight: 600;
  padding: 7px 40px;
  margin: 0 1px;
  letter-spacing: 2px;
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.myisotop2 a:hover {
  border: 1px solid #111111;
  background: #111111;
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 767px) {
  .myisotop2 a {
    padding: 7px 34px;
  }
}
@media only screen and (max-width: 479px) {
  .myisotop2 a {
    margin-bottom: 5px !important;
  }
}
.myisotop2 .current {
  border: 1px solid #111111;
  background: #111111;
  color: #ffffff;
}
.myisotop3 {
  position: relative;
  margin-bottom: 40px;
}
@media only screen and (max-width: 991px) {
  .myisotop3 {
    text-align: center;
  }
}
.myisotop3 a {
  font-weight: 600;
  font-size: 22px;
  color: #727272;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-right: 3px solid #727272;
  margin-right: 25px;
  padding-right: 25px;
  display: inline-block;
}
@media only screen and (max-width: 991px) {
  .myisotop3 a {
    letter-spacing: 1px;
    margin-right: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .myisotop3 a {
    letter-spacing: 1px;
    margin-right: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 479px) {
  .myisotop3 a {
    margin-right: 10px;
    padding-right: 10px;
  }
}
.myisotop3 a:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.myisotop3 .current {
  color: #111111;
  border-right: 3px solid #111111;
}
.myisotop3:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b6b6b6;
  border-top: 1px solid #b6b6b6;
  height: 4px;
  background: transparent;
  width: 45%;
  right: 0;
  top: 35%;
  z-index: 1;
}
@media only screen and (max-width: 1199px) {
  .myisotop3:after {
    display: none;
  }
}
.myisotop4 {
  margin-bottom: 40px;
}
@media only screen and (max-width: 991px) {
  .myisotop4 {
    text-align: center;
  }
}
.myisotop4 a {
  font-weight: 600;
  font-size: 22px;
  color: #727272;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-right: 3px solid #727272;
  margin-right: 25px;
  padding-right: 25px;
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .myisotop4 a {
    letter-spacing: 1px;
    margin-right: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 479px) {
  .myisotop4 a {
    margin-right: 10px;
    padding-right: 10px;
  }
}
.myisotop4 a:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.myisotop4 .current {
  color: #111111;
  border-right: 3px solid #111111;
}
.myisotop5 {
  margin-bottom: 40px;
}
@media only screen and (max-width: 991px) {
  .myisotop5 {
    text-align: center;
  }
}
.myisotop5 a {
  font-weight: 600;
  font-size: 22px;
  color: #727272;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-right: 3px solid #727272;
  margin-right: 25px;
  padding-right: 25px;
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .myisotop5 a {
    letter-spacing: 1px;
    margin-right: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 479px) {
  .myisotop5 a {
    margin-right: 10px;
    padding-right: 10px;
  }
}
.myisotop5 a:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.myisotop5 .current {
  color: #111111;
  border-right: 3px solid #111111;
}
.myisotop6 {
  margin-bottom: 50px;
}
@media only screen and (max-width: 991px) {
  .myisotop6 {
    text-align: center;
  }
}
.myisotop6 a {
  font-size: 24px;
  color: #727272;
  text-transform: uppercase;
  margin-right: 25px;
  display: inline-block;
  position: relative;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .myisotop6 a {
    font-size: 18px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .myisotop6 a {
    font-size: 16px;
  }
}
.myisotop6 a:after {
  opacity: 0;
  visibility: hidden;
  content: "";
  background-color: #00c853;
  height: 4px;
  width: 0;
  bottom: -20px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.myisotop6 a:last-child {
  margin-right: 0;
}
.myisotop6 .current {
  color: #111111;
}
.myisotop6 .current:after {
  opacity: 1;
  width: 100%;
  visibility: visible;
}
.myisotop7 {
  text-align: center;
  margin-bottom: 50px;
}
@media only screen and (max-width: 991px) {
  .myisotop7 {
    text-align: center;
  }
}
.myisotop7 a {
  font-size: 16px;
  color: #212121;
  text-transform: capitalize;
  margin-right: 5px;
  margin-bottom: 8px;
  display: inline-block;
  position: relative;
  padding: 8px 30px;
  font-weight: 600;
  border: 1px solid #b6b6b6;
}
.myisotop7 a:last-child {
  margin-right: 0;
}
.myisotop7 .current {
  border: 1px solid #cf9900;
  background-color: #cf9900;
  color: #ffffff;
}
.myisotop7 .current:after {
  opacity: 1;
  width: 100%;
  visibility: visible;
}
.myisotop8 {
  margin-bottom: 43px;
}
@media only screen and (max-width: 991px) {
  .myisotop8 {
    text-align: center;
  }
}
.myisotop8 a {
  font-size: 24px;
  color: #727272;
  text-transform: uppercase;
  margin-right: 25px;
  display: inline-block;
  position: relative;
  font-weight: 600;
}
@media only screen and (max-width: 479px) {
  .myisotop8 a {
    font-size: 18px;
    margin-right: 5px;
  }
}
.myisotop8 a:after {
  opacity: 0;
  visibility: hidden;
  content: "";
  background-color: #00c853;
  height: 4px;
  width: 0;
  bottom: -20px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.myisotop8 a:last-child {
  margin-right: 0;
}
.myisotop8 .current {
  color: #111111;
}
.myisotop8 .current:after {
  opacity: 1;
  width: 100%;
  visibility: visible;
}
.myisotop9 {
  margin-bottom: 50px;
}
@media only screen and (max-width: 991px) {
  .myisotop9 {
    text-align: center;
  }
}
.myisotop9 a {
  font-size: 24px;
  color: #727272;
  text-transform: uppercase;
  margin-right: 25px;
  display: inline-block;
  position: relative;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .myisotop9 a {
    font-size: 18px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .myisotop9 a {
    font-size: 16px;
  }
}
.myisotop9 a:after {
  opacity: 0;
  visibility: hidden;
  content: "";
  background-color: #f93940;
  height: 4px;
  width: 0;
  bottom: -20px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.myisotop9 a:last-child {
  margin-right: 0;
}
.myisotop9 .current {
  color: #111111;
}
.myisotop9 .current:after {
  opacity: 1;
  width: 100%;
  visibility: visible;
}
.myisotop10 {
  margin-bottom: 43px;
}
@media only screen and (max-width: 991px) {
  .myisotop10 {
    text-align: center;
  }
}
.myisotop10 a {
  font-size: 24px;
  color: #727272;
  text-transform: uppercase;
  margin-right: 25px;
  display: inline-block;
  position: relative;
  font-weight: 600;
}
@media only screen and (max-width: 479px) {
  .myisotop10 a {
    font-size: 18px;
    margin-right: 5px;
  }
}
.myisotop10 a:after {
  opacity: 0;
  visibility: hidden;
  content: "";
  background-color: #2962ff;
  height: 4px;
  width: 0;
  bottom: -20px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.myisotop10 a:last-child {
  margin-right: 0;
}
.myisotop10 .current {
  color: #111111;
}
.myisotop10 .current:after {
  opacity: 1;
  width: 100%;
  visibility: visible;
}
.myisotop11 {
  margin-bottom: 50px;
}
@media only screen and (max-width: 991px) {
  .myisotop11 {
    text-align: center;
  }
}
.myisotop11 a {
  font-size: 24px;
  color: #727272;
  text-transform: uppercase;
  margin-right: 25px;
  display: inline-block;
  position: relative;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .myisotop11 a {
    font-size: 18px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .myisotop11 a {
    font-size: 16px;
  }
}
.myisotop11 a:after {
  opacity: 0;
  visibility: hidden;
  content: "";
  background-color: #ff6600;
  height: 4px;
  width: 0;
  bottom: -20px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.myisotop11 a:last-child {
  margin-right: 0;
}
.myisotop11 .current {
  color: #111111;
}
.myisotop11 .current:after {
  opacity: 1;
  width: 100%;
  visibility: visible;
}
/*======================================
11. Expert Team Area styles
========================================*/
.expert-team-area {
  padding: 88px 0 70px;
  background: #f1f1f1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .expert-team-area {
    padding: 68px 0 50px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .expert-team-area {
    padding: 58px 0 40px;
  }
  .expert-team-area .col-xs-12 {
    width: 50%;
  }
}
@media only screen and (max-width: 479px) {
  .expert-team-area {
    padding: 48px 0 30px;
  }
}
.expert-team-area .expert-team-box {
  text-align: center;
}
.expert-team-area .expert-team-box .expert-team-social {
  opacity: 0;
  -moz-transform: scale(1) translateY(180px);
  -webkit-transform: scale(1) translateY(180px);
  -o-transform: scale(1) translateY(180px);
  -ms-transform: scale(1) translateY(180px);
  transform: scale(1) translateY(180px);
  position: absolute;
  top: 32%;
  z-index: 2;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.expert-team-area .expert-team-box .expert-team-social li {
  display: inline-block;
  margin: 0 1px;
}
.expert-team-area .expert-team-box .expert-team-social li a {
  height: 40px;
  width: 40px;
  background: transparent;
  border-radius: 45px;
  display: block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.expert-team-area .expert-team-box .expert-team-social li a:hover {
  background-color: rgba(33, 33, 33, 0.7);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.expert-team-area .expert-team-box .expert-team-social li a:hover i {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.expert-team-area .expert-team-box .expert-team-social li a i {
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.expert-team-area .expert-team-box .team-img-holder {
  margin-bottom: 18px;
  position: relative;
}
@media only screen and (max-width: 479px) {
  .expert-team-area .expert-team-box .team-img-holder {
    display: inline-block;
  }
}
.expert-team-area .expert-team-box .team-img-holder:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(7, 7, 7, 0.8);
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.expert-team-area .expert-team-box .team-img-holder a img {
  width: 100%;
}
.expert-team-area .expert-team-box .team-content-holder h3 {
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 5px;
}
@media only screen and (max-width: 479px) {
  .expert-team-area .expert-team-box .team-content-holder h3 {
    font-size: 20px;
  }
}
.expert-team-area .expert-team-box .team-content-holder h3 a {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.expert-team-area .expert-team-box .team-content-holder h3 a:hover {
  color: #727272;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.expert-team-area .expert-team-box .team-content-holder p {
  text-transform: uppercase;
  letter-spacing: 2px;
}
.expert-team-area .expert-team-box:hover .expert-team-social {
  opacity: 1;
  -moz-transform: scale(1) translateY(0px);
  -webkit-transform: scale(1) translateY(0px);
  -o-transform: scale(1) translateY(0px);
  -ms-transform: scale(1) translateY(0px);
  transform: scale(1) translateY(0px);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.expert-team-area .expert-team-box:hover .team-img-holder:after {
  opacity: 1;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
/*======================================
12. Advantage Area
========================================*/
/*-------------------------------------
12.1 Advantage1 Area styles
-------------------------------------*/
.advantage1-area {
  padding: 80px 0;
  background: #f1f1f1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage1-area {
    padding: 60px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .advantage1-area {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 479px) {
  .advantage1-area {
    padding: 40px 0;
  }
}
.advantage1-area .advantage-area-box {
  background: #ffffff;
  padding: 37px 0 12px;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.advantage1-area .advantage-area-box a {
  padding: 10px 22px 10px 0;
  border-right: 1px solid #b6b6b6;
  margin-right: 22px;
}
.advantage1-area .advantage-area-box a i:before {
  font-size: 38px;
  color: #111111;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .advantage1-area .advantage-area-box {
    height: 150px;
    padding: 20px 0 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage1-area .advantage-area-box {
    text-align: center;
    height: 212px;
  }
  .advantage1-area .advantage-area-box .media {
    overflow: inherit;
  }
  .advantage1-area .advantage-area-box .media a {
    float: none !important;
    margin-right: 0;
    border-right: none;
    padding: 0 22px 10px 0;
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .advantage1-area .advantage-area-box {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage1-area .advantage-area-box {
    padding: 25px 0 0;
  }
}
.advantage1-area .advantage-area-box .media-body h3 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 3px;
}
.advantage1-area .advantage-area-box .media-body p {
  letter-spacing: 4px;
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}
@media only screen and (max-width: 1199px) {
  .advantage1-area .advantage-area-box .media-body {
    padding-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage1-area .advantage-area-box .media-body {
    padding-right: 0;
  }
}
.advantage1-area .advantage-area-box:hover {
  -webkit-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
/*---------------------------------------
12.2 Advantage2 Area styles 
---------------------------------------*/
.advantage2-area {
  padding: 80px 0;
  background: transparent;
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage2-area {
    padding: 60px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .advantage2-area {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 479px) {
  .advantage2-area {
    padding: 40px 0;
  }
}
.advantage2-area .advantage-area-box {
  background: #f1f1f1;
  padding: 37px 0 12px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .advantage2-area .advantage-area-box {
    height: 150px;
    padding: 20px 0 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage2-area .advantage-area-box {
    text-align: center;
    height: 212px;
  }
  .advantage2-area .advantage-area-box .media {
    overflow: inherit;
  }
  .advantage2-area .advantage-area-box .media a {
    float: none !important;
    margin-right: 0;
    border-right: none;
    padding: 0 22px 10px 0;
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  .advantage2-area .advantage-area-box {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage2-area .advantage-area-box {
    padding: 25px 0 0;
  }
}
.advantage2-area .advantage-area-box a {
  padding: 10px 22px 10px 0;
  border-right: 1px solid #b6b6b6;
  margin-right: 22px;
}
.advantage2-area .advantage-area-box a i:before {
  font-size: 38px;
  color: #111111;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.advantage2-area .advantage-area-box .media-body h3 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 3px;
}
.advantage2-area .advantage-area-box .media-body p {
  letter-spacing: 4px;
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}
@media only screen and (max-width: 1199px) {
  .advantage2-area .advantage-area-box .media-body {
    padding-right: 10px;
  }
}
/*---------------------------------------
12.3 Advantage3 Area styles
---------------------------------------*/
.advantage3-area {
  padding: 80px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage3-area {
    padding: 60px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .advantage3-area {
    padding: 50px 0 20px;
  }
}
@media only screen and (max-width: 479px) {
  .advantage3-area {
    padding: 40px 0 10px;
  }
}
.advantage3-area .advantage-area-box {
  border: 1px solid #4154f1;
  padding: 40px 0 15px;
  text-align: center;
  position: relative;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .advantage3-area .advantage-area-box {
    height: 145px;
  }
}
@media only screen and (max-width: 767px) {
  .advantage3-area .advantage-area-box {
    margin-bottom: 30px;
  }
}
.advantage3-area .advantage-area-box .advantage-area-box-icon {
  position: absolute;
  top: -14px;
  left: 0;
  right: 0;
  z-index: 2;
}
.advantage3-area .advantage-area-box .advantage-area-box-icon a i:before {
  background: #ffffff;
  padding: 0 20px;
  border-radius: 50px;
  font-size: 38px;
  color: #4154f1;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.advantage3-area .advantage-area-box .advantage-area-box-content h3 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #4154f1;
  margin-bottom: 3px;
}
.advantage3-area .advantage-area-box .advantage-area-box-content p {
  letter-spacing: 3px;
  color: #333333;
  font-size: 13px;
}
/*---------------------------------------
12.4 Advantage4 Area styles
---------------------------------------*/
.advantage4-area {
  background: transparent;
}
.advantage4-area .advantage-area-box {
  background: #f1f1f1;
  padding: 37px 0 12px;
}
@media only screen and (max-width: 1199px) {
  .advantage4-area .advantage-area-box {
    padding: 20px 0 0 0;
    margin-bottom: 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .advantage4-area .advantage-area-box {
    height: 195px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage4-area .advantage-area-box {
    text-align: center;
    height: 212px;
  }
  .advantage4-area .advantage-area-box .media {
    overflow: inherit;
  }
  .advantage4-area .advantage-area-box .media a {
    float: none !important;
    margin-right: 0;
    border-right: none;
    padding: 0 22px 10px 0;
    display: block;
  }
}
.advantage4-area .advantage-area-box a {
  padding: 10px 22px 10px 0;
  border-right: 1px solid #b6b6b6;
  margin-right: 22px;
}
.advantage4-area .advantage-area-box a i:before {
  font-size: 38px;
  color: #111111;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.advantage4-area .advantage-area-box .media-body h3 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 3px;
}
.advantage4-area .advantage-area-box .media-body p {
  letter-spacing: 3px;
  color: #333333;
  font-size: 13px;
}
/*---------------------------------------
12.5 Advantage5 Area styles
---------------------------------------*/
.advantage5-area {
  padding: 80px 0;
  background: #f1f1f1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage5-area {
    padding: 60px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .advantage5-area {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 479px) {
  .advantage5-area {
    padding: 40px 0;
  }
}
.advantage5-area .advantage-area-box {
  background: #212121;
  padding: 37px 0 12px;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.advantage5-area .advantage-area-box a {
  padding: 10px 22px 10px 0;
  margin-right: 22px;
  border-right: 1px solid #b6b6b6;
}
.advantage5-area .advantage-area-box a i:before {
  font-size: 38px;
  color: #cf9900;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .advantage5-area .advantage-area-box {
    height: 150px;
    padding: 20px 0 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage5-area .advantage-area-box {
    text-align: center;
    height: 212px;
  }
  .advantage5-area .advantage-area-box .media {
    overflow: inherit;
  }
  .advantage5-area .advantage-area-box .media a {
    float: none !important;
    margin-right: 0;
    border-right: #b6b6b6;
    padding: 0 22px 10px 0;
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .advantage5-area .advantage-area-box {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage5-area .advantage-area-box {
    padding: 25px 0 0;
  }
}
.advantage5-area .advantage-area-box .media-body h3 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #ffffff;
  margin-bottom: 3px;
}
.advantage5-area .advantage-area-box .media-body p {
  letter-spacing: 4px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
}
@media only screen and (max-width: 1199px) {
  .advantage5-area .advantage-area-box .media-body {
    padding-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage5-area .advantage-area-box .media-body {
    padding-right: 0;
  }
}
.advantage5-area .advantage-area-box:hover {
  background-color: #cf9900;
  -webkit-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
}
.advantage5-area .advantage-area-box:hover a i:before {
  color: #ffffff;
}
/*-------------------------------------
12.6 Advantage6 Area styles
-------------------------------------*/
.advantage6-area {
  padding: 80px 0;
  background: #f1f1f1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage6-area {
    padding: 60px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .advantage6-area {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 479px) {
  .advantage6-area {
    padding: 40px 0;
  }
}
.advantage6-area .advantage-area-box {
  background: #ffffff;
  padding: 37px 5px 12px;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.advantage6-area .advantage-area-box a {
  padding: 10px 22px 10px 0;
  border-right: 1px solid #b6b6b6;
  margin-right: 22px;
}
.advantage6-area .advantage-area-box a i:before {
  font-size: 38px;
  color: #f93940;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .advantage6-area .advantage-area-box {
    min-height: 175px;
    padding: 20px 0 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage6-area .advantage-area-box {
    text-align: center;
    min-height: 265px;
  }
  .advantage6-area .advantage-area-box .media {
    overflow: inherit;
  }
  .advantage6-area .advantage-area-box .media a {
    float: none !important;
    margin-right: 0;
    border-right: none;
    padding: 0 22px 10px 0;
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .advantage6-area .advantage-area-box {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage6-area .advantage-area-box {
    padding: 25px 0 0;
  }
}
.advantage6-area .advantage-area-box .media-body h3 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 3px;
}
.advantage6-area .advantage-area-box .media-body p {
  letter-spacing: 4px;
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}
@media only screen and (max-width: 1199px) {
  .advantage6-area .advantage-area-box .media-body {
    padding-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage6-area .advantage-area-box .media-body {
    padding-right: 0;
  }
}
.advantage6-area .advantage-area-box:hover {
  -webkit-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
}
/*-------------------------------------
12.7 Advantage7 Area styles
-------------------------------------*/
.advantage7-area {
  padding: 80px 0;
  background: #f1f1f1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage7-area {
    padding: 60px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .advantage7-area {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 479px) {
  .advantage7-area {
    padding: 40px 0;
  }
}
.advantage7-area .advantage-area-box {
  padding: 37px 0 12px;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.advantage7-area .advantage-area-box a {
  padding: 10px 22px 10px 0;
  border-right: 1px solid #ffffff;
  margin-right: 22px;
}
.advantage7-area .advantage-area-box a i:before {
  font-size: 38px;
  color: #ffffff;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .advantage7-area .advantage-area-box {
    height: 150px;
    padding: 20px 0 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage7-area .advantage-area-box {
    text-align: center;
    height: 212px;
  }
  .advantage7-area .advantage-area-box .media {
    overflow: inherit;
  }
  .advantage7-area .advantage-area-box .media a {
    float: none !important;
    margin-right: 0;
    border-right: none;
    padding: 0 22px 10px 0;
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .advantage7-area .advantage-area-box {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage7-area .advantage-area-box {
    padding: 25px 0 0;
  }
}
.advantage7-area .advantage-area-box .media-body h3 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #ffffff;
  margin-bottom: 3px;
}
.advantage7-area .advantage-area-box .media-body p {
  letter-spacing: 4px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
}
@media only screen and (max-width: 1199px) {
  .advantage7-area .advantage-area-box .media-body {
    padding-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage7-area .advantage-area-box .media-body {
    padding-right: 0;
  }
}
.advantage7-area .advantage-area-box:hover {
  -webkit-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
}
/*-------------------------------------
12.8 Advantage8 Area styles
-------------------------------------*/
.advantage8-area {
  padding: 80px 0 0;
  background: #ffffff;
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage8-area {
    padding: 60px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .advantage8-area {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 479px) {
  .advantage8-area {
    padding: 40px 0;
  }
}
.advantage8-area .advantage-area-box {
  background: #ffffff;
  padding: 37px 0 12px;
  border: 1px solid #e6e6e6;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.advantage8-area .advantage-area-box a {
  padding: 10px 22px 10px 0;
  border-right: 1px solid #b6b6b6;
  margin-right: 22px;
}
.advantage8-area .advantage-area-box a i:before {
  font-size: 38px;
  color: #2962ff;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .advantage8-area .advantage-area-box {
    height: 150px;
    padding: 20px 0 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage8-area .advantage-area-box {
    text-align: center;
    height: 212px;
  }
  .advantage8-area .advantage-area-box .media {
    overflow: inherit;
  }
  .advantage8-area .advantage-area-box .media a {
    float: none !important;
    margin-right: 0;
    border-right: none;
    padding: 0 22px 10px 0;
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .advantage8-area .advantage-area-box {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage8-area .advantage-area-box {
    padding: 25px 0 0;
  }
}
.advantage8-area .advantage-area-box .media-body h3 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 3px;
}
.advantage8-area .advantage-area-box .media-body p {
  letter-spacing: 4px;
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}
@media only screen and (max-width: 1199px) {
  .advantage8-area .advantage-area-box .media-body {
    padding-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage8-area .advantage-area-box .media-body {
    padding-right: 0;
  }
}
.advantage8-area .advantage-area-box:hover {
  -webkit-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
/*-------------------------------------
12.9 Advantage9 Area styles
-------------------------------------*/
.advantage9-area {
  padding: 80px 0;
  background: #ffffff;
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage9-area {
    padding: 60px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .advantage9-area {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 479px) {
  .advantage9-area {
    padding: 40px 0;
  }
}
.advantage9-area .advantage-area-box {
  background: #ffffff;
  padding: 37px 0 12px;
  border: 1px solid #e6e6e6;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.advantage9-area .advantage-area-box a {
  padding: 10px 22px 10px 0;
  border-right: 1px solid #b6b6b6;
  margin-right: 22px;
}
.advantage9-area .advantage-area-box a i:before {
  font-size: 38px;
  color: #00c853;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .advantage9-area .advantage-area-box {
    height: 150px;
    padding: 20px 0 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage9-area .advantage-area-box {
    text-align: center;
    height: 212px;
  }
  .advantage9-area .advantage-area-box .media {
    overflow: inherit;
  }
  .advantage9-area .advantage-area-box .media a {
    float: none !important;
    margin-right: 0;
    border-right: none;
    padding: 0 22px 10px 0;
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .advantage9-area .advantage-area-box {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage9-area .advantage-area-box {
    padding: 25px 0 0;
  }
}
.advantage9-area .advantage-area-box .media-body h3 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 3px;
}
.advantage9-area .advantage-area-box .media-body p {
  letter-spacing: 4px;
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}
@media only screen and (max-width: 1199px) {
  .advantage9-area .advantage-area-box .media-body {
    padding-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage9-area .advantage-area-box .media-body {
    padding-right: 0;
  }
}
.advantage9-area .advantage-area-box:hover {
  -webkit-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
/*-------------------------------------
12.10 Advantage10 Area styles
-------------------------------------*/
.advantage10-area {
  padding: 80px 0;
  background: #f1f1f1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage10-area {
    padding: 60px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .advantage10-area {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 479px) {
  .advantage10-area {
    padding: 40px 0;
  }
}
.advantage10-area .advantage-area-box {
  background: #ffffff;
  padding: 37px 0 12px;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.advantage10-area .advantage-area-box a {
  padding: 10px 22px 10px 0;
  border-right: 1px solid #b6b6b6;
  margin-right: 22px;
}
.advantage10-area .advantage-area-box a i:before {
  font-size: 38px;
  color: #ff6600;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .advantage10-area .advantage-area-box {
    height: 150px;
    padding: 20px 0 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage10-area .advantage-area-box {
    text-align: center;
    height: 212px;
  }
  .advantage10-area .advantage-area-box .media {
    overflow: inherit;
  }
  .advantage10-area .advantage-area-box .media a {
    float: none !important;
    margin-right: 0;
    border-right: none;
    padding: 0 22px 10px 0;
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .advantage10-area .advantage-area-box {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 320px) {
  .advantage10-area .advantage-area-box {
    padding: 25px 0 0;
  }
}
.advantage10-area .advantage-area-box .media-body h3 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 3px;
}
.advantage10-area .advantage-area-box .media-body p {
  letter-spacing: 4px;
  color: #333333;
  font-size: 13px;
  font-weight: 500;
}
@media only screen and (max-width: 1199px) {
  .advantage10-area .advantage-area-box .media-body {
    padding-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .advantage10-area .advantage-area-box .media-body {
    padding-right: 0;
  }
}
.advantage10-area .advantage-area-box:hover {
  -webkit-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
/*======================================
13. Best Seller Area styles
========================================*/
.best-seller-area {
  padding: 90px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .best-seller-area {
    padding: 70px 0 80px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .best-seller-area {
    padding: 60px 0 70px;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller-area {
    padding: 50px 0 60px;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller-area .owl-nav .owl-prev {
    left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller-area .owl-nav .owl-next {
    right: 0;
  }
}
.best-seller-area .best-seller-box .media {
  margin-top: 30px;
}
.best-seller-area .best-seller-box .media:first-child {
  margin-top: 0;
}
.best-seller-area .best-seller-box .media > a {
  padding-right: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .best-seller-area .best-seller-box .media > a {
    padding-right: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller-area .best-seller-box .media > a {
    padding-right: 10px;
  }
}
.best-seller-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  h3 {
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.best-seller-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  h3
  a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  h3
  a:hover {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  span {
  color: #111111;
  font-size: 16px;
  font-weight: 600;
}
.best-seller-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  span
  span {
  margin-right: 15px;
  color: #727272;
  text-decoration: line-through;
}
.best-seller-area .best-seller-box .media .media-body .best-seller-box-cart {
  margin-top: 16px;
}
@media only screen and (max-width: 479px) {
  .best-seller-area .best-seller-box .media .media-body .best-seller-box-cart {
    margin-top: 10px;
  }
}
.best-seller-area .best-seller-box .media .media-body .best-seller-box-cart li {
  display: inline-block;
  height: 40px;
  width: 42px;
  border: 1px solid #b6b6b6;
  background: transparent;
  margin: 0 1px;
  text-align: center;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .best-seller-area
    .best-seller-box
    .media
    .media-body
    .best-seller-box-cart
    li {
    margin: 0;
  }
}
.best-seller-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li
  a {
  display: block;
}
.best-seller-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li
  a
  i {
  line-height: 40px;
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li:hover {
  border: 1px solid #111111;
  background: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li:hover
  i {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller2-area {
  padding: 87px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .best-seller2-area {
    padding: 67px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .best-seller2-area {
    padding: 57px 0;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller2-area {
    padding: 47px 0;
  }
}
.best-seller2-area .owl-dots {
  margin-top: 30px;
}
.best-seller2-area .owl-dots .active span {
  background: #111111 !important;
}
.best-seller2-area .owl-dots .owl-dot span {
  margin: 0 10px !important;
}
.best-seller2-area .owl-dots .owl-dot:hover span {
  background: #111111;
}
@media only screen and (max-width: 479px) {
  .best-seller2-area .owl-nav .owl-prev {
    left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller2-area .owl-nav .owl-next {
    right: 0;
  }
}
.best-seller3-area {
  padding: 90px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .best-seller3-area {
    padding: 70px 0 80px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .best-seller3-area {
    padding: 60px 0 70px;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller3-area {
    padding: 50px 0 60px;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller3-area .owl-nav .owl-prev {
    left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller3-area .owl-nav .owl-next {
    right: 0;
  }
}
.best-seller3-area .best-seller-box .media {
  margin-top: 30px;
}
.best-seller3-area .best-seller-box .media:first-child {
  margin-top: 0;
}
.best-seller3-area .best-seller-box .media .item-img {
  text-align: center;
  position: relative;
  margin-right: 20px;
  padding-right: 0;
}
.best-seller3-area .best-seller-box .media .item-img:after {
  opacity: 0;
  visibility: hidden;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(207, 153, 0, 0.8);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (min-width: 768px) and (max-width: 991px) {
  .best-seller3-area .best-seller-box .media .item-img {
    padding-right: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller3-area .best-seller-box .media .item-img {
    padding-right: 10px;
  }
}
.best-seller3-area .best-seller-box .media .item-img a {
  opacity: 0;
  visibility: hidden;
  line-height: 40px;
  margin: 0 auto;
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: #ffffff;
  color: #ffc400;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 2;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller3-area .best-seller-box .media .item-img a:hover {
  background-color: #212121;
  color: #ffffff;
}
.best-seller3-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  h3 {
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.best-seller3-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  h3
  a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller3-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  h3
  a:hover {
  color: #111111;
}
.best-seller3-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  span {
  color: #111111;
  font-size: 16px;
  font-weight: 600;
}
.best-seller3-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  span
  span {
  margin-right: 15px;
  color: #727272;
  text-decoration: line-through;
}
.best-seller3-area .best-seller-box .media .media-body .best-seller-box-cart {
  margin-top: 16px;
}
@media only screen and (max-width: 479px) {
  .best-seller3-area .best-seller-box .media .media-body .best-seller-box-cart {
    margin-top: 10px;
  }
}
.best-seller3-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li {
  display: inline-block;
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #b6b6b6;
  background: transparent;
  margin: 0 1px;
  text-align: center;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .best-seller3-area
    .best-seller-box
    .media
    .media-body
    .best-seller-box-cart
    li {
    margin: 0;
  }
}
.best-seller3-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li
  a {
  display: block;
}
.best-seller3-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li
  a
  i {
  line-height: 40px;
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller3-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li:hover {
  border: 1px solid #111111;
  background: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller3-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li:hover
  i {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller3-area .best-seller-box .media:hover .item-img:after {
  visibility: visible;
  opacity: 1;
}
.best-seller3-area .best-seller-box .media:hover .item-img a {
  visibility: visible;
  opacity: 1;
}
.best-seller4-area {
  padding: 90px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .best-seller4-area {
    padding: 70px 0 80px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .best-seller4-area {
    padding: 60px 0 70px;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller4-area {
    padding: 50px 0 60px;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller4-area .owl-nav .owl-prev {
    left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller4-area .owl-nav .owl-next {
    right: 0;
  }
}
.best-seller4-area .best-seller-box .media {
  padding: 15px;
  background-color: #f1f1f1;
  margin-top: 5px;
}
.best-seller4-area .best-seller-box .media:first-child {
  margin-top: 0;
}
.best-seller4-area .best-seller-box .media .item-img {
  position: relative;
}
.best-seller4-area .best-seller-box .media .item-img .item-discount {
  background-color: #f93940;
  color: #ffffff;
  text-align: center;
  height: 25px;
  width: 50px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  border-radius: 45px;
  font-size: 13px;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.best-seller4-area .best-seller-box .media .item-content {
  padding-top: 5px;
}
.best-seller4-area .best-seller-box .media .item-content h3 {
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.best-seller4-area .best-seller-box .media .item-content h3 a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller4-area .best-seller-box .media .item-content h3 a:hover {
  color: #2962ff;
}
.best-seller4-area .best-seller-box .media .item-content ul li {
  display: inline-block;
  color: #212121;
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
}
.best-seller4-area .best-seller-box .media .item-content ul li:last-child {
  margin-right: 0;
}
.best-seller4-area .best-seller-box .media .item-content ul .prev-price {
  color: #949494;
  text-decoration: line-through;
}
.best-seller5-area {
  padding: 90px 0 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .best-seller5-area {
    padding: 70px 0 80px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .best-seller5-area {
    padding: 60px 0 70px;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller5-area {
    padding: 50px 0 60px;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller5-area .owl-nav .owl-prev {
    left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller5-area .owl-nav .owl-next {
    right: 0;
  }
}
.best-seller5-area .best-seller-box .media {
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  .best-seller5-area .best-seller-box .media {
    text-align: center;
  }
}
.best-seller5-area .best-seller-box .media:first-child {
  margin-top: 0;
}
.best-seller5-area .best-seller-box .media .item-img {
  text-align: center;
  position: relative;
  margin-right: 20px;
  padding-right: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .best-seller5-area .best-seller-box .media .item-img {
    padding-right: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .best-seller5-area .best-seller-box .media .item-img {
    margin-bottom: 15px;
    margin-right: 0;
  }
}
@media only screen and (max-width: 479px) {
  .best-seller5-area .best-seller-box .media .item-img {
    padding-right: 0;
  }
}
.best-seller5-area .best-seller-box .media .item-img:after {
  opacity: 0;
  visibility: hidden;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 200, 83, 0.8);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller5-area .best-seller-box .media .item-img a {
  opacity: 0;
  visibility: hidden;
  line-height: 40px;
  margin: 0 auto;
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: #ffffff;
  color: #ffc400;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 2;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller5-area .best-seller-box .media .item-img a:hover {
  background-color: #212121;
  color: #ffffff;
}
.best-seller5-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  h3 {
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.best-seller5-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  h3
  a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller5-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  h3
  a:hover {
  color: #111111;
}
.best-seller5-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  span {
  color: #111111;
  font-size: 16px;
  font-weight: 600;
}
.best-seller5-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-content
  span
  span {
  margin-right: 15px;
  color: #727272;
  text-decoration: line-through;
}
.best-seller5-area .best-seller-box .media .media-body .best-seller-box-cart {
  margin-top: 16px;
}
@media only screen and (max-width: 479px) {
  .best-seller5-area .best-seller-box .media .media-body .best-seller-box-cart {
    margin-top: 10px;
  }
}
.best-seller5-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li {
  display: inline-block;
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #b6b6b6;
  background: transparent;
  margin: 0 1px;
  text-align: center;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .best-seller5-area
    .best-seller-box
    .media
    .media-body
    .best-seller-box-cart
    li {
    margin: 0;
  }
}
.best-seller5-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li
  a {
  display: block;
}
.best-seller5-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li
  a
  i {
  line-height: 40px;
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller5-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li:hover {
  border: 1px solid #111111;
  background: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller5-area
  .best-seller-box
  .media
  .media-body
  .best-seller-box-cart
  li:hover
  i {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.best-seller5-area .best-seller-box .media:hover .item-img:after {
  visibility: visible;
  opacity: 1;
}
.best-seller5-area .best-seller-box .media:hover .item-img a {
  visibility: visible;
  opacity: 1;
}
.best-seller-box-content.hover-color-coral-red h3 a:hover {
  color: #f93940 !important;
}
.best-seller-box-content.hover-color-amber h3 a:hover {
  color: #ffc400 !important;
}
.best-seller-box-content.hover-color-malachite h3 a:hover {
  color: #00c853 !important;
}
.best-seller-box-content.hover-color-dodger-blue h3 a:hover {
  color: #2962ff !important;
}
.best-seller-box-content.hover-color-buddha-gold h3 a:hover {
  color: #cf9900 !important;
}
.best-seller-box-content.hover-color-blaze-orange h3 a:hover {
  color: #ff6600 !important;
}
/*======================================
14. Blog Box Area 
========================================*/
/*--------------------------------------
14.1 Blog Box Common Area styles
---------------------------------------*/
.blog-box {
  position: relative;
}
.blog-box > a span {
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 60px solid #111111;
  border-left: 60px solid transparent;
  bottom: 0;
  right: 0;
  z-index: 3;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-box > a span i {
  color: #ffffff;
  position: absolute;
  right: 10px;
  bottom: -48px;
  z-index: 4;
}
.blog-box .blog-img-holder {
  position: relative;
  overflow: hidden;
}
.blog-box .blog-img-holder a img {
  backface-visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-box .blog-img-holder .post-date {
  background: #111111;
  height: 34px;
  width: 90px;
  position: absolute;
  z-index: 2;
  text-align: center;
  left: 8px;
  top: 8px;
}
.blog-box .blog-img-holder .post-date span {
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 34px;
}
.blog-box .blog-content-holder {
  padding: 20px 35px 30px;
}
@media only screen and (max-width: 767px) {
  .blog-box .blog-content-holder {
    padding: 20px 10px 30px;
  }
}
@media only screen and (max-width: 479px) {
  .blog-box .blog-content-holder {
    padding: 20px 15px 30px;
  }
}
.blog-box .blog-content-holder h3 {
  letter-spacing: 1px;
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 8px;
}
.blog-box .blog-content-holder h3 a {
  color: #333333;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-box .blog-content-holder h3 a:hover {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-box .blog-content-holder ul li {
  display: inline-block;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
  border-right: 1px solid #b6b6b6;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 1;
  letter-spacing: 3px;
  color: #111111;
}
@media only screen and (max-width: 767px) {
  .blog-box .blog-content-holder ul li {
    letter-spacing: 1px;
    font-size: 12px;
    margin-right: 5px;
    padding-right: 5px;
  }
}
.blog-box .blog-content-holder ul li:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.blog-box .blog-content-holder ul li span {
  text-transform: lowercase;
}
.blog-box .blog-content-holder p {
  color: #4d4d4d;
}
.blog-box:hover a span {
  border-bottom: 60px solid #070707;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-box:hover .blog-img-holder a img {
  -webkit-transform: scale3d(1.1, 1.1, 1);
  -moz-transform: scale3d(1.1, 1.1, 1);
  -ms-transform: scale3d(1.1, 1.1, 1);
  -o-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
/*--------------------------------------
14.2 Blog1 Box Area styles
---------------------------------------*/
.blog1-area {
  padding: 90px 0;
  background: transparent;
}
.blog1-area .blog-box {
  background: #f1f1f1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog1-area {
    padding: 70px 0 80px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .blog1-area {
    padding: 60px 0 70px;
  }
}
@media only screen and (max-width: 479px) {
  .blog1-area {
    padding: 50px 0 60px;
  }
}
@media only screen and (max-width: 479px) {
  .blog1-area .owl-nav .owl-prev {
    left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .blog1-area .owl-nav .owl-next {
    right: 0;
  }
}
/*--------------------------------------
14.3 Blog2 Box Area styles
---------------------------------------*/
.blog2-area {
  padding: 90px 0 100px;
  background: #f1f1f1;
}
.blog2-area .blog-box {
  background: #ffffff;
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog2-area {
    padding: 70px 0 80px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .blog2-area {
    padding: 60px 0 70px;
  }
}
@media only screen and (max-width: 479px) {
  .blog2-area {
    padding: 50px 0 60px;
  }
}
@media only screen and (max-width: 479px) {
  .blog2-area .owl-nav .owl-prev {
    left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .blog2-area .owl-nav .owl-next {
    right: 0;
  }
}
/*--------------------------------------
14.4 Blog3 Box Area styles
---------------------------------------*/
.blog3-area {
  padding: 86px 0 100px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog3-area {
    padding: 66px 0 70px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog3-area {
    padding: 56px 0 50px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .blog3-area {
    padding: 46px 0 70px;
  }
}
@media only screen and (max-width: 479px) {
  .blog3-area {
    padding: 36px 0 60px;
  }
}
.blog3-area .blog-box {
  position: relative;
  overflow: hidden;
}
.blog3-area .blog-box a img {
  width: 100%;
  backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog3-area .blog-box:hover a img {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  -moz-transform: scale3d(1.2, 1.2, 1);
  -ms-transform: scale3d(1.2, 1.2, 1);
  -o-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
@media only screen and (max-width: 1199px) {
  .blog3-area .blog-box {
    margin-bottom: 30px;
  }
}
.blog3-area .post-date {
  background: #111111;
  height: 34px;
  width: 90px;
  position: absolute;
  z-index: 2;
  text-align: center;
  left: 24px;
  top: 8px;
}
.blog3-area .post-date span {
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 34px;
}
.blog3-area .blog-content-holder {
  text-align: left;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
  z-index: 2;
  padding: 20px 35px;
  background: url(../img/blog/blog-mask.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.blog3-area .blog-content-holder h3 {
  letter-spacing: 1px;
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 8px;
}
.blog3-area .blog-content-holder h3 a {
  color: #ffffff;
}
.blog3-area .blog-content-holder ul li {
  display: inline;
  text-transform: capitalize;
  border-right: 1px solid #999999;
  line-height: 1;
  color: #ffffff;
}
.blog3-area .blog-content-holder ul li:last-child {
  border-right: 0;
}
.blog3-area .blog-content-holder ul li span {
  text-transform: lowercase;
}
.blog3-area .blog-content-holder p {
  color: #111111;
}
@media only screen and (max-width: 479px) {
  .blog3-area:hover .owl-nav .owl-prev {
    left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .blog3-area:hover .owl-nav .owl-next {
    right: 0;
  }
}
/*======================================
15. Blog Page Area styles
========================================*/
.blog-page-area {
  padding: 100px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog-page-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .blog-page-area {
    padding: 70px 0;
  }
}
@media only screen and (max-width: 479px) {
  .blog-page-area {
    padding: 60px 0;
  }
  .blog-page-area .col-xs-6 {
    width: 100%;
  }
}
.blog-page-box {
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
}
.blog-page-box .blog-img-holder {
  margin-bottom: 18px;
  position: relative;
  overflow: hidden;
}
.blog-page-box .blog-img-holder a img {
  backface-visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-page-box .blog-img-holder:after {
  background-color: rgba(7, 7, 7, 0.5);
  content: "";
  height: 100%;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-page-box .blog-content-holder > span {
  color: #212121;
  letter-spacing: 2px;
  text-transform: capitalize;
  display: block;
  margin-bottom: 10px;
}
.blog-page-box .blog-content-holder h3 {
  letter-spacing: 1px;
  text-transform: capitalize;
  font-size: 20px;
  margin-bottom: 8px;
}
.blog-page-box .blog-content-holder h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-page-box .blog-content-holder h3 a:hover {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-page-box .blog-content-holder ul {
  margin-bottom: 18px;
}
.blog-page-box .blog-content-holder ul li {
  display: inline-block;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
  border-right: 1px solid #111111;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 1;
  letter-spacing: 3px;
  color: #111111;
}
.blog-page-box .blog-content-holder ul li:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.blog-page-box .blog-content-holder ul li span {
  color: #111111;
  text-transform: lowercase;
}
.blog-page-box .blog-content-holder p {
  color: #212121;
}
.blog-page-box:hover a span {
  border-bottom: 60px solid #070707;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-page-box:hover .blog-img-holder a img {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  -moz-transform: scale3d(1.2, 1.2, 1);
  -ms-transform: scale3d(1.2, 1.2, 1);
  -o-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-page-box:hover .blog-img-holder:after {
  visibility: visible;
  opacity: 1;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-layout-2 {
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
}
.blog-layout-2 .blog-img-holder {
  margin-bottom: 18px;
  position: relative;
  overflow: hidden;
}
.blog-layout-2 .blog-img-holder a img {
  backface-visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-2 .blog-img-holder:after {
  background-color: rgba(7, 7, 7, 0.5);
  content: "";
  height: 100%;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-layout-2 .blog-content-holder.coral-red h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-2 .blog-content-holder.coral-red h3 a:hover {
  color: #f93940;
}
.blog-layout-2 .blog-content-holder.coral-red ul li a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-2 .blog-content-holder.coral-red ul li a:hover {
  color: #f93940;
}
.blog-layout-2 .blog-content-holder.buddha-gold h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-2 .blog-content-holder.buddha-gold h3 a:hover {
  color: #cf9900;
}
.blog-layout-2 .blog-content-holder.buddha-gold ul li a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-2 .blog-content-holder.buddha-gold ul li a:hover {
  color: #cf9900;
}
.blog-layout-2 .blog-content-holder h3 {
  letter-spacing: 1px;
  text-transform: capitalize;
  font-size: 20px;
  margin-bottom: 8px;
}
.blog-layout-2 .blog-content-holder h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-2 .blog-content-holder h3 a:hover {
  color: #f93940;
}
.blog-layout-2 .blog-content-holder ul {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #b6b6b6;
}
.blog-layout-2 .blog-content-holder ul li {
  display: inline-block;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
  border-right: 1px solid #111111;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 1;
  color: #111111;
}
.blog-layout-2 .blog-content-holder ul li a {
  color: #111111;
}
.blog-layout-2 .blog-content-holder ul li:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.blog-layout-2 .blog-content-holder ul li:first-child {
  color: #111111;
}
.blog-layout-2 .blog-content-holder p {
  color: #212121;
  margin-bottom: 0;
}
.blog-layout-2:hover .blog-img-holder a img {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  -moz-transform: scale3d(1.2, 1.2, 1);
  -ms-transform: scale3d(1.2, 1.2, 1);
  -o-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
}
.blog-layout-2:hover .blog-img-holder:after {
  visibility: visible;
  opacity: 1;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.blog-layout-3 {
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
}
.blog-layout-3 .blog-img-holder {
  margin-bottom: 18px;
  position: relative;
  overflow: hidden;
}
.blog-layout-3 .blog-img-holder a img {
  backface-visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-3 .blog-img-holder:after {
  background-color: rgba(7, 7, 7, 0.5);
  content: "";
  height: 100%;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-layout-3 .blog-img-holder .item-date {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #2962ff;
  padding: 10px 20px;
  line-height: 1.2;
  text-transform: capitalize;
  color: #ffffff;
  z-index: 6;
  text-align: center;
  font-weight: 700;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-layout-3 .blog-img-holder .item-date span {
  display: block;
}
.blog-layout-3 .blog-img-holder .item-date.malachite {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #00c853;
  padding: 10px 20px;
  line-height: 1.2;
  text-transform: capitalize;
  color: #ffffff;
  z-index: 6;
  text-align: center;
  font-weight: 700;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-layout-3 .blog-img-holder .item-date.malachite span {
  display: block;
}
.blog-layout-3 .blog-content-holder.dodger-blue h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-3 .blog-content-holder.dodger-blue h3 a:hover {
  color: #2962ff;
}
.blog-layout-3 .blog-content-holder.dodger-blue ul li a:hover {
  color: #2962ff;
}
.blog-layout-3 .blog-content-holder.malachite h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-3 .blog-content-holder.malachite h3 a:hover {
  color: #00c853;
}
.blog-layout-3 .blog-content-holder.malachite ul li a:hover {
  color: #00c853;
}
.blog-layout-3 .blog-content-holder h3 {
  letter-spacing: 1px;
  text-transform: capitalize;
  font-size: 20px;
  margin-bottom: 8px;
}
.blog-layout-3 .blog-content-holder h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-3 .blog-content-holder h3 a:hover {
  color: #111111;
}
.blog-layout-3 .blog-content-holder ul {
  margin-bottom: 20px;
}
.blog-layout-3 .blog-content-holder ul li {
  display: inline-block;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
  border-right: 1px solid #111111;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 1;
  color: #111111;
}
.blog-layout-3 .blog-content-holder ul li a {
  color: #111111;
}
.blog-layout-3 .blog-content-holder ul li:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.blog-layout-3 .blog-content-holder ul li:first-child {
  color: #111111;
}
.blog-layout-3 .blog-content-holder p {
  color: #212121;
  padding-bottom: 20px;
  border-bottom: 1px solid #b6b6b6;
  margin-bottom: 10px;
}
.blog-layout-3 .blog-content-holder ul li {
  color: #212121;
}
.blog-layout-3 .blog-content-holder ul li a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-layout-3 .blog-content-holder ul li a:hover {
  color: #111111;
}
.blog-layout-3 .blog-content-holder ul li a:hover span {
  color: #111111;
}
.blog-layout-3 .blog-content-holder ul li span {
  color: #999999;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-layout-3:hover .blog-img-holder .item-date {
  top: 0;
  left: 0;
}
.blog-layout-3:hover .blog-img-holder a img {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  -moz-transform: scale3d(1.2, 1.2, 1);
  -ms-transform: scale3d(1.2, 1.2, 1);
  -o-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
}
.blog-layout-3:hover .blog-img-holder:after {
  visibility: visible;
  opacity: 1;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.blog-layout-4 {
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
}
.blog-layout-4 .blog-img-holder {
  margin-bottom: 18px;
  position: relative;
  overflow: hidden;
}
.blog-layout-4 .blog-img-holder a img {
  backface-visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-4 .blog-img-holder:after {
  background-color: rgba(7, 7, 7, 0.5);
  content: "";
  height: 100%;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.blog-layout-4 .blog-content-holder h3 {
  letter-spacing: 1px;
  text-transform: capitalize;
  font-size: 20px;
  margin-bottom: 8px;
}
.blog-layout-4 .blog-content-holder h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-4 .blog-content-holder h3 a:hover {
  color: #ff6600;
}
.blog-layout-4 .blog-content-holder ul {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #b6b6b6;
}
.blog-layout-4 .blog-content-holder ul li {
  display: inline-block;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
  border-right: 1px solid #111111;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 1;
  color: #111111;
}
.blog-layout-4 .blog-content-holder ul li a {
  color: #111111;
}
.blog-layout-4 .blog-content-holder ul li:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.blog-layout-4 .blog-content-holder ul li:first-child {
  color: #111111;
}
.blog-layout-4 .blog-content-holder p {
  color: #212121;
  margin-bottom: 0;
}
.blog-layout-4 .blog-content-holder ul li.post-date a {
  color: #ff6600;
  font-weight: 600;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-layout-4 .blog-content-holder ul li.post-date a:hover {
  color: #111111;
}
.blog-layout-4:hover .blog-img-holder a img {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  -moz-transform: scale3d(1.2, 1.2, 1);
  -ms-transform: scale3d(1.2, 1.2, 1);
  -o-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
}
.blog-layout-4:hover .blog-img-holder:after {
  visibility: visible;
  opacity: 1;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
/*======================================
16. Single Blog Page Area styles
========================================*/
.single-blog-page-area {
  padding: 100px 0 80px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single-blog-page-area {
    padding: 80px 0 60px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .single-blog-page-area {
    padding: 70px 0 50px;
  }
}
@media only screen and (max-width: 479px) {
  .single-blog-page-area {
    padding: 60px 0 40px;
  }
}
.single-blog-page-area .single-blog-details-content {
  padding-bottom: 30px;
}
.single-blog-page-area .single-blog-details-content a img {
  width: 100%;
  margin-bottom: 25px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.single-blog-page-area .single-blog-details-content a img:hover {
  opacity: 0.5;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.single-blog-page-area .single-blog-details-content > span {
  color: #212121;
  letter-spacing: 2px;
  text-transform: capitalize;
  display: block;
  margin-bottom: 5px;
}
.single-blog-page-area .single-blog-details-content h3 {
  letter-spacing: 3px;
  text-transform: capitalize;
  font-size: 20px;
  margin-bottom: 5px;
}
.single-blog-page-area .single-blog-details-content h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.single-blog-page-area .single-blog-details-content h3 a:hover {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.single-blog-page-area .single-blog-details-content .comments-info {
  padding-bottom: 20px;
  border-bottom: 1px solid #b6b6b6;
  margin-bottom: 22px;
}
.single-blog-page-area .single-blog-details-content .comments-info li {
  display: inline-block;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
  border-right: 1px solid #111111;
  margin-right: 15px;
  padding-right: 15px;
  line-height: 1;
  letter-spacing: 3px;
  color: #111111;
}
.single-blog-page-area
  .single-blog-details-content
  .comments-info
  li:last-child {
  border-right: 0;
}
.single-blog-page-area .single-blog-details-content .comments-info li span {
  color: #111111;
  text-transform: lowercase;
}
.single-blog-page-area .single-blog-details-content p span {
  position: relative;
  padding-left: 20px;
  margin: 5px 0 8px 45px;
  display: inline-block;
  font-size: 18px;
}
.single-blog-page-area .single-blog-details-content p span:after {
  content: "";
  background: #111111;
  height: 76px;
  width: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.single-blog-page-area .single-blog-details-tags {
  padding-bottom: 50px;
}
.single-blog-page-area .single-blog-details-tags h3 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #212121;
}
.single-blog-page-area .single-blog-details-tags .single-blog-social {
  margin-bottom: 40px;
}
.single-blog-page-area .single-blog-details-tags .single-blog-social li {
  display: inline-block;
  margin-right: 5px;
}
.single-blog-page-area .single-blog-details-tags .single-blog-social li a {
  width: 29px;
  height: 27px;
  border: 1px solid #b6b6b6;
  display: block;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.single-blog-page-area .single-blog-details-tags .single-blog-social li a i {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.single-blog-page-area .single-blog-details-tags .single-blog-social li:hover {
  background: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.single-blog-page-area
  .single-blog-details-tags
  .single-blog-social
  li:hover
  a {
  border: 1px solid #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.single-blog-page-area
  .single-blog-details-tags
  .single-blog-social
  li:hover
  a
  i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.single-blog-page-area .single-blog-details-comments h3 {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #212121;
  margin-bottom: 40px;
}
.single-blog-page-area .single-blog-details-comments .media a img {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.single-blog-page-area .single-blog-details-comments .media a img:hover {
  opacity: 0.5;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.single-blog-page-area .single-blog-details-comments .media .media-body {
  position: relative;
  padding-left: 30px;
}
.single-blog-page-area .single-blog-details-comments .media .media-body > h3 {
  font-size: 16px;
  margin-bottom: 8px;
}
.single-blog-page-area .single-blog-details-comments .media .media-body > h3 a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.single-blog-page-area
  .single-blog-details-comments
  .media
  .media-body
  > h3
  a:hover {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.single-blog-page-area .single-blog-details-comments .media .media-body p {
  color: #111111;
  margin-bottom: 15px;
}
.single-blog-page-area .single-blog-details-comments .media .media-body p span {
  color: #727272;
  font-size: 15px;
  font-weight: 500;
}
.single-blog-page-area
  .single-blog-details-comments
  .media
  .media-body
  .replay-area {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.single-blog-page-area
  .single-blog-details-comments
  .media
  .media-body
  .replay-area
  a {
  color: #7a7a7a;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.single-blog-page-area
  .single-blog-details-comments
  .media
  .media-body
  .replay-area
  a:hover {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.single-blog-page-area
  .single-blog-details-comments
  .media
  .media-body
  .replay-area
  a
  i:before {
  font-size: 16px;
  color: #111111;
  margin-right: 5px;
}
.single-blog-page-area .single-blog-details-leave-comments h3 {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #212121;
  margin-bottom: 40px;
}
.single-blog-page-area .single-blog-details-leave-comments .form-group {
  margin-bottom: 20px;
}
.single-blog-page-area
  .single-blog-details-leave-comments
  .form-group
  .form-control {
  border-radius: 0;
  background: #f9f9f9;
  box-shadow: none;
  border: none;
}
.single-blog-page-area .single-blog-details-leave-comments .form-group input {
  height: 45px;
  color: #212121;
  font-weight: 500;
}
/*======================================
17. Portfolio Page Area
========================================*/
/*----------------------------------------
17.1 Portfolio1 Page Area styles
----------------------------------------*/
.portfolio1-page-area {
  padding: 100px 0 62px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .portfolio1-page-area {
    padding: 80px 0 42px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .portfolio1-page-area {
    padding: 70px 0 32px;
  }
}
@media only screen and (max-width: 479px) {
  .portfolio1-page-area {
    padding: 60px 0 22px;
  }
}
@media only screen and (max-width: 479px) {
  .portfolio1-page-area .col-xs-6 {
    width: 100%;
  }
}
.portfolio1-box {
  margin-bottom: 30px;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.portfolio1-box .portfolio1-social {
  opacity: 0;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 30%;
  z-index: 2;
  -moz-transform: scale(1) translateX(-250px);
  -webkit-transform: scale(1) translateX(-250px);
  -o-transform: scale(1) translateX(-250px);
  -ms-transform: scale(1) translateX(-250px);
  transform: scale(1) translateX(-250px);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (min-width: 768px) and (max-width: 991px) {
  .portfolio1-box .portfolio1-social {
    top: 23%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .portfolio1-box .portfolio1-social {
    top: 30%;
  }
}
.portfolio1-box .portfolio1-social li {
  border-right: 1px solid #b6b6b6;
  display: inline;
  margin-right: 15px;
  padding-right: 15px;
  height: 40px;
  width: 40px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.portfolio1-box .portfolio1-social li a i {
  font-size: 18px;
  line-height: 40px;
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.portfolio1-box .portfolio1-social li:hover i {
  color: #727272;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.portfolio1-box .portfolio1-social li:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.portfolio1-box .portfolio1-img-holder {
  display: inline-block;
  margin-bottom: 25px;
  position: relative;
}
@media (min-width: 480px) and (max-width: 991px) {
  .portfolio1-box .portfolio1-img-holder {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 479px) {
  .portfolio1-box .portfolio1-img-holder {
    margin-bottom: 15px;
  }
}
.portfolio1-box .portfolio1-img-holder:after {
  opacity: 0;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(7, 7, 7, 0.7);
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.portfolio1-box .portfolio1-content-holder h3 {
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 3px;
  font-weight: 600;
  margin-bottom: 10px;
}
@media only screen and (max-width: 991px) {
  .portfolio1-box .portfolio1-content-holder h3 {
    margin-bottom: 5px;
    font-size: 14px;
    letter-spacing: 1px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .portfolio1-box .portfolio1-content-holder h3 {
    margin-bottom: 5px;
    font-size: 16px;
    letter-spacing: 2px;
  }
}
.portfolio1-box .portfolio1-content-holder h3 a {
  color: #212121;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.portfolio1-box .portfolio1-content-holder h3 a:hover {
  color: #111111;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.portfolio1-box .portfolio1-content-holder ul li {
  display: inline;
  font-size: 13px;
  font-weight: 500;
  color: #727272;
  text-transform: capitalize;
  letter-spacing: 3px;
  border-right: 1px solid #b6b6b6;
  padding-right: 10px;
  margin-right: 10px;
}
.portfolio1-box .portfolio1-content-holder ul li:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.portfolio1-box:hover .portfolio1-social {
  opacity: 1;
  -moz-transform: scale(1) translateY(0px);
  -webkit-transform: scale(1) translateY(0px);
  -o-transform: scale(1) translateY(0px);
  -ms-transform: scale(1) translateY(0px);
  transform: scale(1) translateY(0px);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.portfolio1-box:hover .portfolio1-img-holder:after {
  opacity: 1;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
/*----------------------------------------
17.2 Portfolio2 Page Area styles
----------------------------------------*/
.portfolio2-page-area {
  padding: 100px 0 70px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .portfolio2-page-area {
    padding: 80px 0 50px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .portfolio2-page-area {
    padding: 70px 0 40px;
  }
}
@media only screen and (max-width: 479px) {
  .portfolio2-page-area {
    padding: 60px 0 30px;
  }
  .portfolio2-page-area .col-xs-6 {
    width: 100%;
  }
}
.portfolio2-box {
  margin-bottom: 30px;
  text-align: center;
}
.portfolio2-box .portfolio2-img-holder {
  overflow: hidden;
  position: relative;
}
.portfolio2-box .portfolio2-img-holder img {
  width: 100%;
}
.portfolio2-box .portfolio2-img-holder .portfolio2-content-holder {
  opacity: 0;
  text-align: left;
  padding: 20px 0 0 20px;
  position: absolute;
  content: "";
  height: 30%;
  width: 100%;
  background-color: rgba(7, 7, 7, 0.8);
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  -moz-transform: scale(1) translateY(250px);
  -webkit-transform: scale(1) translateY(250px);
  -o-transform: scale(1) translateY(250px);
  -ms-transform: scale(1) translateY(250px);
  transform: scale(1) translateY(250px);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .portfolio2-box .portfolio2-img-holder .portfolio2-content-holder {
    height: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio2-box .portfolio2-img-holder .portfolio2-content-holder {
    height: 40%;
  }
}
@media (min-width: 480px) and (max-width: 991px) {
  .portfolio2-box .portfolio2-img-holder .portfolio2-content-holder {
    height: 45%;
    text-align: center;
    padding: 20px 0 0;
  }
}
@media (min-width: 320px) and (max-width: 479px) {
  .portfolio2-box .portfolio2-img-holder .portfolio2-content-holder {
    height: 32%;
    text-align: center;
    padding: 20px 0 0;
  }
}
@media only screen and (max-width: 320px) {
  .portfolio2-box .portfolio2-img-holder .portfolio2-content-holder {
    height: 35%;
  }
}
.portfolio2-box .portfolio2-img-holder .portfolio2-content-holder h3 {
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 3px;
  font-weight: 600;
  margin-bottom: 5px;
}
@media only screen and (max-width: 991px) {
  .portfolio2-box .portfolio2-img-holder .portfolio2-content-holder h3 {
    letter-spacing: 1px;
  }
}
@media only screen and (max-width: 479px) {
  .portfolio2-box .portfolio2-img-holder .portfolio2-content-holder h3 {
    font-size: 14px;
  }
}
.portfolio2-box .portfolio2-img-holder .portfolio2-content-holder h3 a {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.portfolio2-box .portfolio2-img-holder .portfolio2-content-holder ul li {
  display: inline;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 3px;
  border-right: 1px solid #b6b6b6;
  padding-right: 10px;
  margin-right: 10px;
}
.portfolio2-box
  .portfolio2-img-holder
  .portfolio2-content-holder
  ul
  li:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.portfolio2-box:hover .portfolio2-content-holder {
  opacity: 1;
  -moz-transform: scale(1) translateY(0px);
  -webkit-transform: scale(1) translateY(0px);
  -o-transform: scale(1) translateY(0px);
  -ms-transform: scale(1) translateY(0px);
  transform: scale(1) translateY(0px);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
/*======================================
18. Featured Area 
========================================*/
/*----------------------------------------
18.1 Featured Area styles
----------------------------------------*/
.featured-products-area {
  padding: 90px 0 93px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featured-products-area {
    padding: 70px 0 73px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .featured-products-area {
    padding: 60px 0 63px;
  }
}
@media only screen and (max-width: 479px) {
  .featured-products-area {
    padding: 50px 0 53px;
  }
}
.featured-products-area .title-carousel:after {
  width: 60%;
}
@media only screen and (max-width: 479px) {
  .featured-products-area .owl-nav .owl-prev {
    left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .featured-products-area .owl-nav .owl-next {
    right: 0;
  }
}
/*----------------------------------------
18.2 Featured 2 Area styles
----------------------------------------*/
.featured-products-area2 .title-carousel:after {
  width: 47% !important;
  right: 12% !important;
}
@media only screen and (max-width: 479px) {
  .featured-products-area2 .owl-nav .owl-prev {
    left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .featured-products-area2 .owl-nav .owl-next {
    right: 0;
  }
}
/*======================================
19. Shop Area
========================================*/
/*---------------------------------------
19.1 Shop Common styles 
---------------------------------------*/
.shop-page-area {
  padding: 100px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .shop-page-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .shop-page-area {
    padding: 70px 0;
  }
}
@media only screen and (max-width: 479px) {
  .shop-page-area {
    padding: 60px 0;
  }
}
.shop-page2-area {
  padding: 80px 0 70px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .shop-page2-area {
    padding: 60px 0 50px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .shop-page2-area {
    padding: 60px 0 40px;
  }
}
@media only screen and (max-width: 479px) {
  .shop-page2-area {
    padding: 60px 0 30px;
  }
  .shop-page2-area .col-xs-4 {
    width: 50%;
  }
}
.inner-shop-top-left {
  padding-top: 10px;
  text-align: left;
}
.inner-shop-top-left .dropdown {
  border: 1px solid #b6b6b6;
  display: inline;
  padding: 12px 20px;
}
.inner-shop-top-left .dropdown button:active {
  box-shadow: none;
  outline: none;
}
@media only screen and (max-width: 479px) {
  .inner-shop-top-left .dropdown {
    padding: 12px;
  }
}
.inner-shop-top-left .dropdown button {
  text-transform: uppercase;
  padding: 0;
  background: transparent;
}
.inner-shop-top-left .dropdown button span {
  margin-left: 15px;
}
.inner-shop-top-left .dropdown .dropdown-menu {
  border-radius: 0;
  left: -2px;
  min-width: 195px;
}
@media only screen and (max-width: 479px) {
  .inner-shop-top-left .dropdown .dropdown-menu {
    min-width: 180px;
  }
}
.inner-shop-top-left .dropdown .dropdown-menu > li {
  border: none;
  padding: 0;
  display: inherit;
}
.inner-shop-top-right {
  float: right;
}
.inner-shop-top-right ul > li {
  border: 1px solid #e1e1e1;
  display: inline-block;
}
.inner-shop-top-right ul > li:hover a i {
  color: #111111;
}
.inner-shop-top-right ul > li > a {
  padding: 8px 17px;
  display: block;
}
@media only screen and (max-width: 479px) {
  .inner-shop-top-right ul > li > a {
    padding: 8px 10px;
  }
}
.inner-shop-top-right ul > li > a > i {
  color: #989898;
}
.inner-shop-top-right ul > .active i {
  color: #111111;
}
/*---------------------------------------
19.2 Shop Product Box1 styles 
---------------------------------------*/
.product-box1 {
  text-align: center;
  margin-bottom: 30px;
}
.product-box1 .product-social {
  opacity: 0;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 33%;
  z-index: 2;
  -moz-transform: scale(1) translateY(-150px);
  -webkit-transform: scale(1) translateY(-150px);
  -o-transform: scale(1) translateY(-150px);
  -ms-transform: scale(1) translateY(-150px);
  transform: scale(1) translateY(-150px);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-social {
    top: 27%;
    -moz-transform: scale(1) translateY(-50px);
    -webkit-transform: scale(1) translateY(-50px);
    -o-transform: scale(1) translateY(-50px);
    -ms-transform: scale(1) translateY(-50px);
    transform: scale(1) translateY(-50px);
  }
}
.product-box1 .product-social li {
  display: inline-block;
  height: 40px;
  width: 42px;
  border: 1px solid #ffffff;
  background: #ffffff;
  margin: 0 1px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-social li {
    height: 30px;
    width: 32px;
  }
}
.product-box1 .product-social li a {
  display: block;
}
.product-box1 .product-social li a i {
  line-height: 40px;
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-social li a i {
    line-height: 30px;
  }
}
.product-box1 .product-social li:hover {
  background: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box1 .product-social li:hover i {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box1 .product-social-round {
  opacity: 0;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 33%;
  z-index: 2;
  -moz-transform: scale(1) translateY(-150px);
  -webkit-transform: scale(1) translateY(-150px);
  -o-transform: scale(1) translateY(-150px);
  -ms-transform: scale(1) translateY(-150px);
  transform: scale(1) translateY(-150px);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-social-round {
    top: 27%;
    -moz-transform: scale(1) translateY(-50px);
    -webkit-transform: scale(1) translateY(-50px);
    -o-transform: scale(1) translateY(-50px);
    -ms-transform: scale(1) translateY(-50px);
    transform: scale(1) translateY(-50px);
  }
}
.product-box1 .product-social-round li {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  width: 42px;
  border: 1px solid #ffffff;
  background: #ffffff;
  margin: 0 1px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-social-round li {
    height: 30px;
    width: 32px;
  }
}
.product-box1 .product-social-round li a {
  display: block;
}
.product-box1 .product-social-round li a i {
  line-height: 40px;
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-social-round li a i {
    line-height: 30px;
  }
}
.product-box1 .product-social-round li:hover {
  background: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box1 .product-social-round li:hover i {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box1 .product-img-holder {
  position: relative;
}
.product-box1 .product-img-holder a img {
  width: 100%;
  /* width: 263px !important; */
  height: 182px !important;
}
.product-box1 .product-img-holder:after {
  opacity: 0;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(33, 33, 33, 0.5);
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box1 .product-img-holder .hot-sale {
  top: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 60px solid #111111;
  border-right: 60px solid transparent;
  z-index: 3;
}
.product-box1 .product-img-holder .hot-sale span {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  bottom: 26px;
  letter-spacing: 2px;
  position: absolute;
  -webkit-transform: rotate(316deg);
  -moz-transform: rotate(316deg);
  -o-transform: rotate(316deg);
}
.product-box1 .product-img-holder .sale {
  left: 20px;
  top: 20px;
  position: absolute;
  width: 62px;
  height: 30px;
  border-radius: 45px;
  z-index: 3;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  background-color: #212121;
  line-height: 2.2;
  -webkit-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  -moz-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
}
.product-box1 .product-img-holder .sale.bg-coral-red {
  left: 20px;
  top: 20px;
  position: absolute;
  width: 62px;
  height: 30px;
  border-radius: 45px;
  z-index: 3;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  background-color: #f93940;
  line-height: 2.2;
  -webkit-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  -moz-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
}
.product-box1 .product-img-holder .sale.bg-dodger-blue {
  left: 20px;
  top: 20px;
  position: absolute;
  width: 62px;
  height: 30px;
  border-radius: 45px;
  z-index: 3;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  background-color: #2962ff;
  line-height: 2.2;
  -webkit-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  -moz-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
}
.product-box1 .product-img-holder .sale.malachite {
  left: 20px;
  top: 20px;
  position: absolute;
  width: 62px;
  height: 30px;
  border-radius: 45px;
  z-index: 3;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  background-color: #00c853;
  line-height: 2.2;
  -webkit-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  -moz-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
}
.product-box1 .product-content-holder {
  margin-top: 20px;
}
.product-box1 .product-content-holder h3 {
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-content-holder h3 {
    letter-spacing: 0;
  }
}
.product-box1 .product-content-holder h3 a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box1 .product-content-holder h3 a:hover {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box1 .product-content-holder span {
  color: #111111;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-content-holder span {
    letter-spacing: 0;
  }
}
.product-box1 .product-content-holder span span {
  margin-right: 15px;
  color: #727272;
  text-decoration: line-through;
}
.product-box1:hover .product-social {
  opacity: 1;
  -moz-transform: scale(1) translateY(0px);
  -webkit-transform: scale(1) translateY(0px);
  -o-transform: scale(1) translateY(0px);
  -ms-transform: scale(1) translateY(0px);
  transform: scale(1) translateY(0px);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box1:hover .product-social-round {
  opacity: 1;
  -moz-transform: scale(1) translateY(0px);
  -webkit-transform: scale(1) translateY(0px);
  -o-transform: scale(1) translateY(0px);
  -ms-transform: scale(1) translateY(0px);
  transform: scale(1) translateY(0px);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box1:hover .product-img-holder:after {
  opacity: 1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
/*---------------------------------------
19.3 Shop Product Box2 styles 
---------------------------------------*/
.product-box2 {
  margin-bottom: 30px;
}
@media only screen and (max-width: 479px) {
  .product-box2 {
    text-align: center;
    margin-bottom: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-box2 a {
    float: none !important;
  }
}
@media only screen and (max-width: 479px) {
  .product-box2 a {
    float: none !important;
  }
}
.product-box2 a img {
  width: 100%;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box2 a img {
    display: inline-block;
    width: auto;
    margin-bottom: 15px;
  }
}
.product-box2 a img:hover {
  opacity: 0.5;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box2 .media-body {
  text-align: left;
  padding: 8px 30px 0 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-box2 .media-body {
    padding: 0;
  }
}
@media only screen and (max-width: 479px) {
  .product-box2 .media-body {
    padding: 8px 10px 0 10px;
  }
}
@media only screen and (max-width: 479px) {
  .product-box2 .media-body .product-box2-content {
    text-align: center;
  }
}
.product-box2 .media-body .product-box2-content h3 {
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.product-box2 .media-body .product-box2-content h3 a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box2 .media-body .product-box2-content h3 a:hover {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box2 .media-body .product-box2-content span {
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
  color: #111111;
  font-weight: 600;
}
.product-box2 .media-body .product-box2-content p {
  color: #727272;
}
.product-box2 .media-body .product-box2-cart {
  margin-top: 80px;
}
@media only screen and (max-width: 1199px) {
  .product-box2 .media-body .product-box2-cart {
    margin-top: 54px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-box2 .media-body .product-box2-cart {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .product-box2 .media-body .product-box2-cart {
    margin-top: 20px;
    text-align: center;
  }
}
.product-box2 .media-body .product-box2-cart li {
  margin: 0 2px 0 0;
  display: inline-block;
  border: 1px solid #111111;
}
.product-box2 .media-body .product-box2-cart li a {
  line-height: 42px;
  text-align: center;
  height: 42px;
  padding: 0 20px;
  background: transparent;
  display: block;
  color: #111111;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-box2 .media-body .product-box2-cart li a {
    letter-spacing: 1px;
    padding: 0 11px;
  }
}
@media only screen and (max-width: 320px) {
  .product-box2 .media-body .product-box2-cart li a {
    padding: 0 15px;
  }
}
.product-box2 .media-body .product-box2-cart li a i {
  line-height: 42px;
  font-size: 14px;
}
.product-box2 .media-body .product-box2-cart li a:hover {
  background: #111111;
  color: #ffffff;
}
/*---------------------------------------
19.4 Shop Product Box3 styles 
---------------------------------------*/
.product-box3 {
  margin-bottom: 30px;
  overflow: hidden;
  text-align: center;
  position: relative;
}
@media (min-width: 480px) and (max-width: 767px) {
  .product-box3 {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 479px) {
  .product-box3 {
    margin-bottom: 15px;
  }
}
.product-box3 .product-content-holder {
  opacity: 0;
  -moz-transform: scale(1) translateY(398px);
  -webkit-transform: scale(1) translateY(398px);
  -o-transform: scale(1) translateY(398px);
  -ms-transform: scale(1) translateY(398px);
  transform: scale(1) translateY(398px);
  position: absolute;
  text-align: center;
  z-index: 2;
  left: 0;
  right: 0;
  top: 25%;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 1274px) {
  .product-box3 .product-content-holder {
    top: 16%;
  }
}
@media only screen and (max-width: 1199px) {
  .product-box3 .product-content-holder {
    top: 17%;
  }
}
@media only screen and (max-width: 767px) {
  .product-box3 .product-content-holder {
    top: 30%;
  }
}
@media only screen and (max-width: 510px) {
  .product-box3 .product-content-holder {
    top: 20%;
  }
}
@media only screen and (max-width: 479px) {
  .product-box3 .product-content-holder {
    top: 10%;
  }
}
.product-box3 .product-content-holder h3 {
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
@media only screen and (max-width: 991px) {
  .product-box3 .product-content-holder h3 {
    letter-spacing: 1px;
  }
}
@media only screen and (max-width: 510px) {
  .product-box3 .product-content-holder h3 {
    font-size: 12px;
  }
}
.product-box3 .product-content-holder h3 a {
  color: #ffffff;
}
.product-box3 .product-content-holder span {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 15px;
}
@media only screen and (max-width: 479px) {
  .product-box3 .product-content-holder span {
    margin-bottom: 0;
  }
}
.product-box3 .product-content-holder ul {
  margin-bottom: 30px;
}
@media only screen and (max-width: 479px) {
  .product-box3 .product-content-holder ul {
    margin-bottom: 5px;
  }
}
.product-box3 .product-content-holder ul li {
  display: inline-block;
}
.product-box3 .product-content-holder ul li i {
  color: #ffffff;
  font-size: 14px;
}
.product-box3 .product-img-holder {
  display: inline-block;
  position: relative;
}
.product-box3 .product-img-holder:after {
  opacity: 0;
  -moz-transform: scale(1) translateY(-398px);
  -webkit-transform: scale(1) translateY(-398px);
  -o-transform: scale(1) translateY(-398px);
  -ms-transform: scale(1) translateY(-398px);
  transform: scale(1) translateY(-398px);
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(7, 7, 7, 0.6);
  top: 0;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box3:hover .product-content-holder {
  opacity: 1;
  -moz-transform: scale(1) translateY(0);
  -webkit-transform: scale(1) translateY(0);
  -o-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.product-box3:hover .product-img-holder:after {
  opacity: 1;
  -moz-transform: scale(1) translateY(0);
  -webkit-transform: scale(1) translateY(0);
  -o-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
/*---------------------------------------
19.5 Shop Product Box4 styles 
---------------------------------------*/
.product-box4 {
  margin-bottom: 30px;
  overflow: hidden;
  text-align: center;
  position: relative;
}
.product-box4 .product-content-holder2 {
  -moz-transform: scale(1) translateY(280px);
  -webkit-transform: scale(1) translateY(280px);
  -o-transform: scale(1) translateY(280px);
  -ms-transform: scale(1) translateY(280px);
  transform: scale(1) translateY(280px);
  position: absolute;
  text-align: center;
  z-index: 2;
  left: 0;
  right: 0;
  top: 28%;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-box4 .product-content-holder2 {
    -moz-transform: scale(1) translateY(210px);
    -webkit-transform: scale(1) translateY(210px);
    -o-transform: scale(1) translateY(210px);
    -ms-transform: scale(1) translateY(210px);
    transform: scale(1) translateY(210px);
    top: 32%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-box4 .product-content-holder2 {
    -moz-transform: scale(1) translateY(175px);
    -webkit-transform: scale(1) translateY(175px);
    -o-transform: scale(1) translateY(175px);
    -ms-transform: scale(1) translateY(175px);
    transform: scale(1) translateY(175px);
    top: 24%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .product-box4 .product-content-holder2 {
    -moz-transform: scale(1) translateY(175px);
    -webkit-transform: scale(1) translateY(175px);
    -o-transform: scale(1) translateY(175px);
    -ms-transform: scale(1) translateY(175px);
    transform: scale(1) translateY(175px);
    top: 39%;
  }
}
@media only screen and (max-width: 479px) {
  .product-box4 .product-content-holder2 {
    -moz-transform: scale(1) translateY(175px);
    -webkit-transform: scale(1) translateY(175px);
    -o-transform: scale(1) translateY(175px);
    -ms-transform: scale(1) translateY(175px);
    transform: scale(1) translateY(175px);
    top: 33%;
  }
}
@media only screen and (max-width: 320px) {
  .product-box4 .product-content-holder2 {
    -moz-transform: scale(1) translateY(230px);
    -webkit-transform: scale(1) translateY(230px);
    -o-transform: scale(1) translateY(230px);
    -ms-transform: scale(1) translateY(230px);
    transform: scale(1) translateY(230px);
  }
}
.product-box4 .product-content-holder2 h3 {
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .product-box4 .product-content-holder2 h3 {
    letter-spacing: 0;
    line-height: 1.2;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 479px) {
  .product-box4 .product-content-holder2 h3 {
    font-size: 16px;
    letter-spacing: 0;
  }
}
.product-box4 .product-content-holder2 h3 a {
  color: #ffffff;
}
.product-box4 .product-content-holder2 span {
  opacity: 0;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 15px;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
@media only screen and (max-width: 767px) {
  .product-box4 .product-content-holder2 span {
    margin-bottom: 3px;
    display: block;
  }
}
.product-box4 .product-content-holder2 ul {
  opacity: 0;
  margin-bottom: 30px;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
@media (min-width: 480px) and (max-width: 767px) {
  .product-box4 .product-content-holder2 ul {
    display: none;
  }
}
@media only screen and (max-width: 479px) {
  .product-box4 .product-content-holder2 ul {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 320px) {
  .product-box4 .product-content-holder2 ul {
    display: none;
  }
}
.product-box4 .product-content-holder2 ul li {
  display: inline-block;
}
.product-box4 .product-content-holder2 ul li i {
  color: #ffffff;
  font-size: 14px;
}
.product-box4 .product-img-holder2 {
  display: inline-block;
  position: relative;
}
.product-box4 .product-img-holder2:after {
  -moz-transform: scale(1) translateY(378px);
  -webkit-transform: scale(1) translateY(378px);
  -o-transform: scale(1) translateY(378px);
  -ms-transform: scale(1) translateY(378px);
  transform: scale(1) translateY(378px);
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(7, 7, 7, 0.6);
  top: 0;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-box4 .product-img-holder2:after {
    -moz-transform: scale(1) translateY(302px);
    -webkit-transform: scale(1) translateY(302px);
    -o-transform: scale(1) translateY(302px);
    -ms-transform: scale(1) translateY(302px);
    transform: scale(1) translateY(302px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-box4 .product-img-holder2:after {
    -moz-transform: scale(1) translateY(224px);
    -webkit-transform: scale(1) translateY(224px);
    -o-transform: scale(1) translateY(224px);
    -ms-transform: scale(1) translateY(224px);
    transform: scale(1) translateY(224px);
  }
}
@media only screen and (max-width: 320px) {
  .product-box4 .product-img-holder2:after {
    -moz-transform: scale(1) translateY(314px);
    -webkit-transform: scale(1) translateY(314px);
    -o-transform: scale(1) translateY(314px);
    -ms-transform: scale(1) translateY(314px);
    transform: scale(1) translateY(314px);
  }
}
.product-box4 .product-img-holder2 .hot-sale {
  top: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 60px solid #070707;
  border-right: 60px solid transparent;
  z-index: 3;
}
.product-box4 .product-img-holder2 .hot-sale span {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  bottom: 26px;
  letter-spacing: 2px;
  position: absolute;
  -webkit-transform: rotate(316deg);
  -moz-transform: rotate(316deg);
  -o-transform: rotate(316deg);
}
.product-box4:hover .product-content-holder2 {
  -moz-transform: scale(1) translateY(0px);
  -webkit-transform: scale(1) translateY(0px);
  -o-transform: scale(1) translateY(0px);
  -ms-transform: scale(1) translateY(0px);
  transform: scale(1) translateY(0px);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.product-box4:hover .product-content-holder2 span {
  opacity: 1;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.product-box4:hover .product-content-holder2 ul {
  opacity: 1;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.product-box4:hover .product-img-holder2:after {
  -moz-transform: scale(1) translateY(0px);
  -webkit-transform: scale(1) translateY(0px);
  -o-transform: scale(1) translateY(0px);
  -ms-transform: scale(1) translateY(0px);
  transform: scale(1) translateY(0px);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
/*---------------------------------------
19.6 Shop Product Box5 styles 
---------------------------------------*/
.product-box5 {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}
.product-box5 .product-img-holder {
  position: relative;
}
.product-box5 .product-img-holder a img {
  width: 100%;
}
@media only screen and (max-width: 479px) {
  .product-box5 .product-img-holder a img {
    width: auto;
  }
}
.product-box5 .product-img-holder img {
  width: 100%;
}
.product-box5 .product-img-holder:after {
  opacity: 0;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(33, 33, 33, 0.5);
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box5 .product-img-holder .hot-sale {
  top: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 60px solid #111111;
  border-right: 60px solid transparent;
  z-index: 3;
}
.product-box5 .product-img-holder .hot-sale span {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  bottom: 26px;
  letter-spacing: 2px;
  position: absolute;
  -webkit-transform: rotate(316deg);
  -moz-transform: rotate(316deg);
  -o-transform: rotate(316deg);
}
.product-box5 .product-img-holder .sale {
  left: 20px;
  top: 20px;
  position: absolute;
  width: 62px;
  height: 30px;
  border-radius: 45px;
  z-index: 3;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  background-color: #212121;
  line-height: 2.2;
  -webkit-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  -moz-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
}
.product-box5 .product-img-holder .sale.bg-coral-red {
  left: 20px;
  top: 20px;
  position: absolute;
  width: 62px;
  height: 30px;
  border-radius: 45px;
  z-index: 3;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  background-color: #f93940;
  line-height: 2.2;
  -webkit-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  -moz-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
}
.product-box5 .product-img-holder .sale.bg-dodger-blue {
  left: 20px;
  top: 20px;
  position: absolute;
  width: 62px;
  height: 30px;
  border-radius: 45px;
  z-index: 3;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  background-color: #2962ff;
  line-height: 2.2;
  -webkit-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  -moz-box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
  box-shadow: 0 4px 10px 0px rgba(101, 101, 101, 0.75);
}
.product-box5 .product-img-holder .product-social-round {
  opacity: 0;
  position: absolute;
  text-align: center;
  right: 30px;
  z-index: 2;
  top: 40%;
  -moz-transform: translateY(-50px);
  -webkit-transform: translateY(-50px);
  -o-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box5 .product-img-holder .product-social-round li {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  height: 40px;
  width: 42px;
  border: 1px solid #ffffff;
  background: #ffffff;
  margin: 10px 0;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box5 .product-img-holder .product-social-round li {
    height: 30px;
    width: 32px;
  }
}
.product-box5 .product-img-holder .product-social-round li a {
  display: block;
}
.product-box5 .product-img-holder .product-social-round li a i {
  line-height: 40px;
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box5 .product-img-holder .product-social-round li a i {
    line-height: 30px;
  }
}
.product-box5 .product-img-holder .product-social-round li:hover {
  background: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box5 .product-img-holder .product-social-round li:hover i {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box5 .product-content-holder {
  margin-top: 20px;
}
.product-box5 .product-content-holder h3 {
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 479px) {
  .product-box5 .product-content-holder h3 {
    letter-spacing: 0;
  }
}
.product-box5 .product-content-holder h3 a {
  color: #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box5 .product-content-holder h3 a:hover {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-box5 .product-content-holder span {
  color: #111111;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
}
@media only screen and (max-width: 479px) {
  .product-box5 .product-content-holder span {
    letter-spacing: 0;
  }
}
.product-box5 .product-content-holder span span {
  margin-right: 15px;
  color: #727272;
  text-decoration: line-through;
}
.product-box5:hover .product-social-round {
  opacity: 1;
  visibility: visible;
}
.product-box5:hover .product-img-holder:after {
  opacity: 1;
}
.product-content-holder.hover-color-coral-red h3 a:hover {
  color: #f93940;
}
.product-content-holder.hover-color-amber h3 a:hover {
  color: #ffc400;
}
.product-content-holder.hover-color-malachite h3 a:hover {
  color: #00c853;
}
.product-content-holder.hover-color-dodger-blue h3 a:hover {
  color: #2962ff;
}
.product-content-holder.hover-color-buddha-gold h3 a:hover {
  color: #cf9900;
}
.product-content-holder.hover-color-blaze-orange h3 a:hover {
  color: #ff6600;
}
/*=======================================
20. Product Details Page Area
========================================*/
/*----------------------------------------
20.1 Product Details1 Page Area styles
----------------------------------------*/
.product-details1-area {
  padding: 100px 0 93px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-details1-area {
    padding: 80px 0 73px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .product-details1-area {
    padding: 70px 0 63px;
  }
}
@media only screen and (max-width: 479px) {
  .product-details1-area {
    padding: 60px 0 53px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details1-area .inner-product-details-left {
    text-align: center;
  }
}
.product-details1-area .inner-product-details-left .tab-content {
  border: 1px solid #111111;
  margin-top: 5px;
}
@media only screen and (max-width: 767px) {
  .product-details1-area .inner-product-details-left .tab-content {
    display: inline-block;
  }
}
.product-details1-area .inner-product-details-left .tab-content a {
  display: block;
}
.product-details1-area .inner-product-details-left .tab-content a img {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .product-details1-area .inner-product-details-left ul {
    margin-bottom: 30px;
  }
}
.product-details1-area .inner-product-details-left ul li {
  border: 1px solid transparent;
  width: 29%;
  display: inline-block;
  margin: 16px 17px 0 0;
}
.product-details1-area .inner-product-details-left ul li a img {
  width: 100%;
  opacity: 0.5;
}
.product-details1-area .inner-product-details-left ul li a img:hover {
  opacity: 1;
}
.product-details1-area .inner-product-details-left ul li:last-child {
  margin: 16px 0 0 0;
}
.product-details1-area .inner-product-details-left ul li:hover {
  border: 1px solid #111111;
}
.product-details1-area .inner-product-details-left ul .active {
  border: 1px solid #111111;
}
.product-details1-area .inner-product-details-left ul .active a img {
  opacity: 1;
}
.product-details1-area .inner-product-details-right h3 {
  color: #212121;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-size: 28px;
  line-height: 1;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-details1-area .inner-product-details-right h3 {
    margin-bottom: 15px;
  }
}
.product-details1-area .inner-product-details-right > ul {
  margin: 0 0 15px;
}
.product-details1-area .inner-product-details-right > ul li {
  display: inline-block;
}
.product-details1-area .inner-product-details-right > ul li > i {
  color: #eebe2c;
}
.product-details1-area .inner-product-details-right .more-option li {
  display: block;
}
.product-details1-area
  .inner-product-details-right
  .more-option
  .select2-container {
  max-width: 390px;
}
.product-details1-area
  .inner-product-details-right
  .more-option
  .select2-container--classic
  .select2-selection--single
  .select2-selection__arrow {
  background-image: none;
  height: 43px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-left: none;
  background-color: transparent;
}
.product-details1-area
  .inner-product-details-right
  .more-option
  .select2-container--classic
  .select2-selection--single {
  height: 45px;
  background-image: none;
  background-color: transparent;
  border-radius: 0;
}
.product-details1-area
  .inner-product-details-right
  .more-option
  .select2-container--classic
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 38px;
  color: #212121 !important;
}
.product-details1-area
  .inner-product-details-right
  .more-option
  .select2-container--classic
  .select2-selection--single:focus {
  border: 1px solid #b6b6b6;
  outline: none;
}
.product-details1-area
  .inner-product-details-right
  .more-option
  .select2-container--classic.select2-container--open
  .select2-selection--single {
  border: 1px solid #b6b6b6;
}
.product-details1-area .inner-product-details-right .price {
  font-size: 22px;
  font-weight: 600;
  color: #111111;
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-details1-area .inner-product-details-right .price {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-details1-area .inner-product-details-right .price {
    margin-bottom: 30px;
  }
}
.product-details1-area .inner-product-details-right .product-details-content {
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-details1-area .inner-product-details-right .product-details-content {
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 991px) {
  .product-details1-area .inner-product-details-right .product-details-content {
    margin-bottom: 12px;
  }
}
.product-details1-area
  .inner-product-details-right
  .product-details-content
  > p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #727272;
}
.product-details1-area
  .inner-product-details-right
  .product-details-content
  > p
  > span {
  color: #212121;
  font-weight: 600;
  text-transform: uppercase;
}
.product-details1-area .inner-product-details-right .product-details-social {
  margin-bottom: 0;
}
.product-details1-area .inner-product-details-right .product-details-social li {
  display: inline;
  color: #212121;
  font-weight: 600;
  margin-right: 15px;
}
.product-details1-area
  .inner-product-details-right
  .product-details-social
  li
  a {
  padding: 0 10px;
}
.product-details1-area
  .inner-product-details-right
  .product-details-social
  li
  a
  i {
  color: #727272;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details1-area
  .inner-product-details-right
  .product-details-social
  li
  a:hover
  i {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart {
  margin-bottom: 30px;
}
@media only screen and (max-width: 479px) {
  .product-details1-area
    .inner-product-details-right
    .inner-product-details-cart {
    text-align: center;
  }
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li {
  vertical-align: top;
  margin: 0 2px 0 0;
  display: inline-block;
  border: 1px solid #b6b6b6;
}
@media only screen and (max-width: 479px) {
  .product-details1-area
    .inner-product-details-right
    .inner-product-details-cart
    li {
    margin-bottom: 5px;
  }
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li:hover {
  border: 1px solid #111111;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li:hover:nth-child(1) {
  border: 1px solid #b6b6b6;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  a {
  line-height: 52px;
  text-align: center;
  height: 52px;
  padding: 0 20px;
  background: transparent;
  display: block;
  color: #212121;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .product-details1-area
    .inner-product-details-right
    .inner-product-details-cart
    li
    a {
    padding: 0 13px;
  }
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  a
  i {
  line-height: 54px;
  font-size: 14px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  a:hover {
  background: #111111;
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  a:hover
  i {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group {
  position: relative;
  box-shadow: none !important;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .form-control {
  box-shadow: none;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  input {
  border: none;
  height: 52px;
  width: 100px;
  padding-right: 47px;
  font-size: 20px;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .product-details1-area
    .inner-product-details-right
    .inner-product-details-cart
    li
    .input-group
    input {
    width: 81px;
    padding-left: 5px;
    padding-right: 40px;
  }
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .input-group-btn-vertical {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .input-group-btn-vertical
  .btn-default {
  border: 0;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .input-group-btn-vertical
  button {
  display: block;
  border-left: 1px solid #b6b6b6 !important;
  border-radius: 0 !important;
  background: transparent;
  padding: 4px 12px;
  line-height: 1.3;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .input-group-btn-vertical
  button:last-child {
  border-top: 1px solid #b6b6b6;
}
.product-details1-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .input-group-btn-vertical
  button
  i {
  line-height: 1;
}
.product-details1-area .product-details-tab-area {
  margin: 60px 0;
  border: 1px solid #b6b6b6;
  overflow: hidden;
}
.product-details1-area .product-details-tab-area ul > li {
  margin: -1px;
  display: inline-block;
  padding: 10px 0;
  background: #070707;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-details1-area .product-details-tab-area ul > li {
    display: block !important;
    padding: 0;
  }
}
.product-details1-area .product-details-tab-area ul > li a {
  color: #ffffff;
  padding: 12px 30px;
  text-transform: uppercase;
  font-weight: 500;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-details1-area .product-details-tab-area ul > li a {
    display: block !important;
  }
}
.product-details1-area .product-details-tab-area ul > li:hover {
  background: transparent;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details1-area .product-details-tab-area ul > li:hover a {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details1-area .product-details-tab-area ul .active {
  background: transparent;
}
.product-details1-area .product-details-tab-area ul .active a {
  color: #111111;
  text-decoration: none;
}
.product-details1-area .product-details-tab-area .tab-content {
  padding: 35px 35px 15px 35px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-details1-area .product-tags {
    display: none;
  }
}
@media only screen and (max-width: 479px) {
  .product-details1-area .owl-nav {
    position: inherit;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .product-details1-area .owl-nav .owl-prev {
    position: absolute;
    left: -30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    background: #676767 !important;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .product-details1-area .owl-nav .owl-next {
    position: absolute;
    right: -30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    background: #676767 !important;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .product-details1-area .owl-nav {
    opacity: 1;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .product-details1-area .owl-nav .owl-prev {
    left: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .product-details1-area .owl-nav .owl-next {
    right: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}
/*----------------------------------------
20.2 Product Details2 Page Area styles
----------------------------------------*/
.product-details2-area {
  padding: 30px 0 93px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-details2-area {
    padding: 30px 0 73px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .product-details2-area {
    padding: 30px 0 63px;
  }
}
@media only screen and (max-width: 479px) {
  .product-details2-area {
    padding: 30px 0 53px;
  }
}
@media only screen and (max-width: 991px) {
  .product-details2-area .inner-product-details-left {
    text-align: center;
  }
}
.product-details2-area .inner-product-details-left .tab-content {
  border: 1px solid #111111;
}
@media only screen and (max-width: 991px) {
  .product-details2-area .inner-product-details-left .tab-content {
    display: inline-block;
  }
}
.product-details2-area .inner-product-details-left .tab-content a {
  display: block;
}
.product-details2-area .inner-product-details-left .tab-content a img {
  width: 100%;
  height: 297px;
}
@media only screen and (max-width: 991px) {
  .product-details2-area .inner-product-details-left ul {
    margin-bottom: 30px;
  }
}
.product-details2-area .inner-product-details-left ul li {
  border: 1px solid transparent;
  width: 22%;
  display: inline-block;
  margin: 16px 17px 0 0;
}
@media (min-width: 480px) and (max-width: 1199px) {
  .product-details2-area .inner-product-details-left ul li {
    margin: 16px 14px 0 0;
  }
}
@media only screen and (max-width: 479px) {
  .product-details2-area .inner-product-details-left ul li {
    margin: 16px 8px 0 0;
  }
}
.product-details2-area .inner-product-details-left ul li a img {
  width: 100%;
  opacity: 0.5;
}
.product-details2-area .inner-product-details-left ul li a img:hover {
  opacity: 1;
}
.product-details2-area .inner-product-details-left ul li:last-child {
  margin: 16px 0 0 0;
}
.product-details2-area .inner-product-details-left ul li:hover {
  border: 1px solid #111111;
}
.product-details2-area .inner-product-details-left ul .active {
  border: 1px solid #111111;
}
.product-details2-area .inner-product-details-left ul .active a img {
  opacity: 1;
}
.product-details2-area .inner-product-details-right h3 {
  color: #4154f1;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-bottom: 12px;
  font-size: 28px;
}
.product-details2-area .inner-product-details-right > ul {
  margin: 0 0 15px;
}
.product-details2-area .inner-product-details-right > ul li {
  display: inline-block;
}
.product-details2-area .inner-product-details-right > ul li > i {
  color: #eebe2c;
}
.product-details2-area .inner-product-details-right .more-option li {
  display: block;
}
.product-details2-area
  .inner-product-details-right
  .more-option
  .select2-container {
  max-width: 390px;
}
.product-details2-area
  .inner-product-details-right
  .more-option
  .select2-container--classic
  .select2-selection--single
  .select2-selection__arrow {
  background-image: none;
  height: 43px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-left: none;
  background-color: transparent;
}
.product-details2-area
  .inner-product-details-right
  .more-option
  .select2-container--classic
  .select2-selection--single {
  height: 45px;
  background-image: none;
  background-color: transparent;
  border-radius: 0;
}
.product-details2-area
  .inner-product-details-right
  .more-option
  .select2-container--classic
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 38px;
  color: #212121 !important;
}
.product-details2-area
  .inner-product-details-right
  .more-option
  .select2-container--classic
  .select2-selection--single:focus {
  border: 1px solid #b6b6b6;
  outline: none;
}
.product-details2-area
  .inner-product-details-right
  .more-option
  .select2-container--classic.select2-container--open
  .select2-selection--single {
  border: 1px solid #b6b6b6;
}
.product-details2-area .inner-product-details-right .price {
  font-size: 22px;
  font-weight: 600;
  color: #111111;
  /* margin-bottom: 45px; */
}
/* @media only screen and (max-width: 1199px) {
  .product-details2-area .inner-product-details-right .price {
    margin-bottom: 30px;
  }
} */
.product-details2-area .inner-product-details-right .product-details-content {
  display: inline-block;
}
@media only screen and (max-width: 1199px) {
  .product-details2-area .inner-product-details-right .product-details-content {
    margin: 0;
  }
}
.product-details2-area
  .inner-product-details-right
  .product-details-content
  > p {
  margin-bottom: 25px;
  font-size: 14px;
  color: #727272;
}
@media only screen and (max-width: 1199px) {
  .product-details2-area
    .inner-product-details-right
    .product-details-content
    > p {
    margin-bottom: 20px;
  }
}
.product-details2-area
  .inner-product-details-right
  .product-details-content
  > p
  > span {
  color: #212121;
  font-weight: 600;
  text-transform: uppercase;
}
@media only screen and (max-width: 1199px) {
  .product-details2-area .inner-product-details-right .product-details-social {
    margin-bottom: 27px;
  }
}
.product-details2-area .inner-product-details-right .product-details-social li {
  display: inline;
  color: #212121;
  font-weight: 600;
  margin-right: 15px;
}
.product-details2-area
  .inner-product-details-right
  .product-details-social
  li
  a {
  padding: 0 10px;
}
.product-details2-area
  .inner-product-details-right
  .product-details-social
  li
  a
  i {
  color: #727272;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details2-area
  .inner-product-details-right
  .product-details-social
  li
  a:hover
  i {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart {
  margin-bottom: 30px;
}
@media only screen and (max-width: 479px) {
  .product-details2-area
    .inner-product-details-right
    .inner-product-details-cart {
    text-align: center;
  }
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li {
  vertical-align: top;
  margin: 0 2px 0 0;
  display: inline-block;
  /* border: 1px solid #B6B6B6; */
}
@media only screen and (max-width: 479px) {
  .product-details2-area
    .inner-product-details-right
    .inner-product-details-cart
    li {
    margin-bottom: 5px;
  }
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li:hover {
  /* border: 1px solid #111111; */
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li:hover:nth-child(2) {
  border: 1px solid #b6b6b6;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  a {
  line-height: 52px;
  text-align: center;
  height: 52px;
  padding: 0 20px;
  background: transparent;
  display: block;
  /* color: #212121; */
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  background: #4154f1;
  /* font-size: 14px; */
  /* text-transform: uppercase; */
  /* font-weight: 600; */
  color: #ffffff;
  /* letter-spacing: 2px; */
  /* display: inline-block; */
  /* padding: 12px 40px; */
  border: 1px solid #4154f1;
  transition: all 0.3s ease-out;
  border-radius: 45px;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  a
  i {
  line-height: 54px;
  font-size: 14px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  a:hover {
  /* background: #070707; */
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  a:hover
  i {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group {
  position: relative;
  box-shadow: none !important;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .form-control {
  box-shadow: none;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  input {
  border: none;
  height: 52px;
  width: 100px;
  padding-right: 47px;
  font-size: 20px;
  text-align: center;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .input-group-btn-vertical {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .input-group-btn-vertical
  .btn-default {
  border: 0;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .input-group-btn-vertical
  button {
  display: block;
  border-left: 1px solid #b6b6b6 !important;
  border-radius: 0 !important;
  background: transparent;
  padding: 4px 12px;
  line-height: 1.3;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .input-group-btn-vertical
  button:last-child {
  border-top: 1px solid #b6b6b6;
}
.product-details2-area
  .inner-product-details-right
  .inner-product-details-cart
  li
  .input-group
  .input-group-btn-vertical
  button
  i {
  line-height: 1;
}
.product-details2-area .product-details-tab-area {
  margin: 10px 0;
  border: 1px solid #b6b6b6;
  overflow: hidden;
}
@media only screen and (max-width: 479px) {
  .product-details2-area .product-details-tab-area {
    margin: 20px 0;
  }
}
.product-details2-area .product-details-tab-area ul > li {
  border-radius: 5px;
  margin: -1px;
  display: inline-block;
  padding: 10px 0;
}
@media only screen and (max-width: 479px) {
  .product-details2-area .product-details-tab-area ul > li {
    border-radius: 5px;
    display: block !important;
    padding: 0;
  }
}
.product-details2-area .product-details-tab-area ul > li a {
  color: #111111;
  padding: 12px 30px;
  text-transform: uppercase;
  font-weight: 500;
}
@media only screen and (max-width: 479px) {
  .product-details2-area .product-details-tab-area ul > li a {
    display: block !important;
  }
}
.product-details2-area .product-details-tab-area ul > li:hover {
  background: transparent;
  /* background: #070707; */
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details2-area .product-details-tab-area ul > li:hover a {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-details2-area .product-details-tab-area ul li.active {
  background: #070707;
  /* background: transparent; */
}
.product-details2-area .product-details-tab-area ul li.active a {
  color: #ffffff;
  text-decoration: none;
}
.product-details2-area .product-details-tab-area .tab-content {
  padding: 35px 35px 15px 35px;
}
/*======================================
21. Sidebar Area 
========================================*/
/*----------------------------------------
21.1 Sidebar Banner Area styles
----------------------------------------*/
.sidebar-banner {
  position: relative;
  text-align: center;
}
.sidebar-banner .sidebar-banner-button {
  position: absolute;
  bottom: 30px;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.sidebar-banner .sidebar-banner-button a:hover {
  color: #ffffff;
  border: 1px solid #ffffff;
  background: #070707;
}
/*----------------------------------------
21.2 Sidebar Search Area styles
----------------------------------------*/
.sidebar-search-area .stylish-input-group {
  padding: 5px 10px;
}
/*----------------------------------------
21.3 Sidebar Archives Area styles
----------------------------------------*/
.archives ul li {
  font-weight: 500;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.archives ul li:last-child {
  margin-bottom: 0;
}
.archives ul li a {
  color: #727272;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.archives ul li a:hover {
  color: #070707;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.archives ul li a i {
  margin-right: 10px;
  font-size: 12px;
}
/*----------------------------------------
21.4 Sidebar Recent Posts Area styles
----------------------------------------*/
.recent-posts .media a {
  padding-right: 0;
  margin-right: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .recent-posts .media a {
    float: inherit !important;
  }
}
.recent-posts .media a img {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.recent-posts .media a img:hover {
  opacity: 0.7;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.recent-posts .media-body h3 {
  letter-spacing: 1px;
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 12px;
  line-height: 1.3;
}
.recent-posts .media-body h3 a {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.recent-posts .media-body h3 a:hover {
  color: #727272;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.recent-posts .media-body p {
  color: #727272;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 0;
}
/*----------------------------------------
21.5 Sidebar Best Products Area styles
----------------------------------------*/
.best-products .media a img {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.best-products .media a img:hover {
  opacity: 0.6;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.best-products h3 {
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.best-products h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.best-products h3 a:hover {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.best-products p {
  font-size: 15px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 20px;
}
.best-products p span {
  text-decoration: line-through;
  color: #a3a3a3;
  margin-right: 10px;
}
/*----------------------------------------
21.6 Sidebar Filter By Price Area styles
----------------------------------------*/
.filter-by-price span {
  width: 100%;
  height: 10px;
  background: #212121;
  display: block;
  position: relative;
}
.filter-by-price span:before {
  content: "";
  background: #111111;
  height: 20px;
  width: 20px;
  position: absolute;
  top: -5px;
  left: 0;
  z-index: 1;
}
.filter-by-price span:after {
  content: "";
  background: #111111;
  height: 20px;
  width: 20px;
  position: absolute;
  top: -5px;
  right: 0;
  z-index: 1;
}
.filter-by-price ul {
  margin-top: 30px;
  border: 1px solid #d7d7d7;
  text-align: center;
  padding: 8px;
}
.filter-by-price ul li {
  display: inline-block;
  border-right: 1px solid #d7d7d7;
  margin-right: 10px;
  padding-right: 10px;
  font-size: 18px;
  color: #727272;
  line-height: 1;
}
.filter-by-price ul li:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.price-range-wrapper {
  text-align: center;
  padding-top: 10px;
  margin-bottom: 30px;
}
.price-range-wrapper:after {
  clear: both;
  display: block;
  content: "";
}
.price-range-wrapper .price-range-select {
  margin: 20px 0;
  text-align: center;
}
.price-range-wrapper .price-range-select:after {
  clear: both;
  display: block;
  content: "";
}
.price-range-wrapper .price-range-select .price-range {
  font-size: 24px;
  color: #111111;
  display: inline-block;
  margin: 0 10px;
}
.price-range-wrapper .noUi-connect {
  background: #111111;
}
.price-range-wrapper .noUi-horizontal {
  height: 12px;
}
.price-range-wrapper .noUi-target {
  border-radius: 0;
}
.price-range-wrapper .noUi-horizontal .noUi-handle {
  top: -10px;
  background: #f1f1f1;
  border: none;
  box-shadow: none;
}
.price-range-wrapper .noUi-handle::after,
.price-range-wrapper .noUi-handle::before {
  left: 16px;
  top: 7px;
  background: #ffffff;
}
.price-range-wrapper .noUi-handle::after {
  left: 19px;
}
/*----------------------------------------
21.7 Sidebar Product Tags Area styles
----------------------------------------*/
.product-tags li {
  display: inline-block;
  margin-bottom: 3px;
  border: 1px solid #111111;
  background: transparent;
  font-size: 12px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-tags li a {
  color: #111111;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  padding: 5px 16px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 991px) {
  .product-tags li a {
    padding: 5px 14px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .product-tags li a {
    padding: 5px 8px;
  }
}
.product-tags li:hover {
  border: 1px solid #111111;
  background: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.product-tags li:hover a {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
/*======================================
22. Login Registration Page Area styles
========================================*/
.login-registration-page-area {
  padding: 30px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .login-registration-page-area {
    padding: 30px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .login-registration-page-area {
    padding: 30px 0;
  }
}
@media only screen and (max-width: 479px) {
  .login-registration-page-area {
    padding: 30px 0;
  }
}
.login-registration-page-area .login-registration-field {
  border: 1px solid #b6b6b6;
  padding: 30px;
}
@media only screen and (max-width: 767px) {
  .login-registration-page-area .login-registration-field {
    margin-bottom: 15px;
  }
}
.login-registration-page-area .login-registration-field label {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}
.login-registration-page-area .login-registration-field input {
  height: 40px;
  padding: 0 10px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #b6b6b6;
  margin-bottom: 15px;
}
.login-registration-page-area .login-registration-field span {
  margin-left: 15px;
  color: #111111;
}
@media (min-width: 768px) and (max-width: 991px) {
  .login-registration-page-area .login-registration-field span {
    font-size: 12px;
  }
}
@media only screen and (max-width: 479px) {
  .login-registration-page-area .login-registration-field span {
    display: inline-block;
    margin-left: 0;
    margin-top: 15px;
  }
}
.login-registration-page-area .login-registration-field span input {
  width: inherit;
  height: inherit;
  margin-right: 10px;
  padding: 30px;
}
/*======================================
23. Checkout Page Area styles
========================================*/
.checkout-page-area {
  padding: 20px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .checkout-page-area {
    padding: 30px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .checkout-page-area {
    padding: 30px 0;
  }
}
@media only screen and (max-width: 479px) {
  .checkout-page-area {
    padding: 30px 0;
  }
}
.checkout-page-area .checkout-page-top {
  padding: 15px;
  border: 1px solid #b6b6b6;
  margin-bottom: 20px;
}
.checkout-page-area .checkout-page-top p {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.checkout-page-area .checkout-page-top p a {
  color: #727272;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.checkout-page-area .checkout-page-top p a:hover {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.checkout-page-area .checkout-page-top p i {
  margin-right: 5px;
  font-size: 16px;
  color: #111111;
}
.checkout-page-area .billing-details-area {
  margin-bottom: 30px;
}
.checkout-page-area .billing-details-area label {
  letter-spacing: 1px;
  color: #333333;
  font-weight: 400;
  margin-bottom: 10px;
}
.checkout-page-area .billing-details-area input {
  height: 40px;
  border: 1px solid #b6b6b6;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 10px;
}
.checkout-page-area .billing-details-area span {
  color: #333333;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.checkout-page-area .billing-details-area span input {
  height: inherit;
  margin-right: 10px;
}
.checkout-page-area .billing-details-area .custom-select select {
  width: 100%;
  height: 40px;
}
@media only screen and (max-width: 991px) {
  .checkout-page-area .ship-to-another-area {
    margin-bottom: 30px;
  }
}
.checkout-page-area .ship-to-another-area h2 span {
  margin-left: 15px;
}
.checkout-page-area .ship-to-another-area label {
  letter-spacing: 2px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 10px;
}
.checkout-page-area .ship-to-another-area textarea {
  box-shadow: none;
  border-radius: 0;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 2px;
}
.checkout-page-area .ship-to-another-area textarea:focus {
  border-color: #d7d7d7 !important;
}
.checkout-page-area .order-sheet {
  margin-bottom: 45px;
  padding: 30px 35px;
  border: 1px solid #4154f1;
}
.checkout-page-area .order-sheet h2 {
  text-transform: uppercase;
  font-size: 20px;
  color: #4154f1;
  margin-bottom: 15px;
}
.checkout-page-area .order-sheet ul li {
  border-bottom: 1px solid #4154f1;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #4154f1;
}
.checkout-page-area .order-sheet ul li span {
  float: right;
}
.checkout-page-area .order-sheet h3 {
  text-transform: uppercase;
  font-size: 18px;
  color: #4154f1;
  letter-spacing: 2px;
  border-bottom: 1px solid #4154f1;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.checkout-page-area .order-sheet h3:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.checkout-page-area .order-sheet h3 span {
  float: right;
}
.checkout-page-area .payment-option {
  border: 1px solid #4154f1;
  padding-top: 30px;
}
.checkout-page-area .payment-option .form-group {
  border-bottom: 1px solid #b6b6b6;
  padding-bottom: 15px;
}
.checkout-page-area .payment-option .form-group:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.checkout-page-area .payment-option span {
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 600;
  padding-left: 30px;
}
.checkout-page-area .payment-option span input {
  margin-right: 20px;
}
.checkout-page-area .payment-option p {
  padding: 0 64px;
  margin-bottom: 0;
}
.checkout-page-area .pLace-order {
  text-align: right;
  margin-top: 20px;
}
.checkout-page-area .select2-container .select2-selection--single {
  height: 40px;
}
.checkout-page-area .select2-container--classic .select2-selection--single {
  background-color: transparent;
  background-image: none;
  border-radius: 0;
  border: 1px solid #b6b6b6;
}
.checkout-page-area
  .select2-container--classic
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 40px;
}
.checkout-page-area
  .select2-container--classic
  .select2-selection--single
  .select2-selection__arrow {
  top: 7px;
  background-color: transparent;
  background-image: none;
  border-style: none;
  right: 5px;
}
.select2-container--classic .select2-dropdown {
  border: 1px solid #111111 !important;
}
.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #111111;
  color: #ffffff;
  font-weight: 600;
}
/*======================================
24. Cart Page Area styles
========================================*/
.cart-page-area {
  padding: 100px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .cart-page-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .cart-page-area {
    padding: 70px 0;
  }
}
@media only screen and (max-width: 479px) {
  .cart-page-area {
    padding: 60px 0;
  }
}
.cart-page-area .cart-page-top {
  margin-bottom: 45px;
  border: 1px solid #b6b6b6;
  padding: 0 0 10px;
}
.cart-page-area .cart-page-top table {
  margin-bottom: 10px;
  border-bottom: 1px solid #b6b6b6;
}
.cart-page-area .cart-page-top table .cart-form-heading {
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 20px;
}
.cart-page-area .cart-page-top table tr td {
  vertical-align: middle !important;
  border-right: 1px solid #b6b6b6;
}
.cart-page-area .cart-page-top table tr td:last-child {
  border-right: 0;
}
.cart-page-area .cart-page-top table tr td h3 {
  letter-spacing: 2px;
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 0;
  margin-left: 15px;
}
.cart-page-area .cart-page-top table tr td h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.cart-page-area .cart-page-top table tr td h3 a:hover {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.cart-page-area .cart-page-top table tr td a img {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.cart-page-area .cart-page-top table tr td a img:hover {
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.cart-page-area .cart-page-top table tr .cart-img-holder {
  width: 12%;
}
.cart-page-area .cart-page-top table tr .amount {
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 16px;
  color: #212121;
  font-weight: 600;
}
.cart-page-area .cart-page-top table tr .quantity .quantity-holder {
  margin: 0 auto;
  position: relative;
  width: 130px;
  top: -15px;
  z-index: 2;
}
.cart-page-area .cart-page-top table tr .quantity .quantity-holder input {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 48px;
  border-radius: 0;
}
.cart-page-area
  .cart-page-top
  table
  tr
  .quantity
  .quantity-holder
  .input-group-btn-vertical
  button {
  border-radius: 0;
  position: absolute;
  z-index: 3;
}
.cart-page-area
  .cart-page-top
  table
  tr
  .quantity
  .quantity-holder
  .input-group-btn-vertical
  .quantity-plus {
  left: 0;
}
.cart-page-area
  .cart-page-top
  table
  tr
  .quantity
  .quantity-holder
  .input-group-btn-vertical
  .quantity-minus {
  right: 0;
}
.cart-page-area .cart-page-top table tr .dismiss {
  text-align: center;
}
.cart-page-area .cart-page-top table tr .dismiss a i {
  color: #111111;
  font-size: 18px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.cart-page-area .cart-page-top table tr .dismiss a:hover i {
  color: #ff0000;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.cart-page-area .cart-page-top .update-button {
  text-align: right;
  padding-right: 10px;
}
.cart-page-area .cart-page-bottom-left {
  border: 1px solid #b6b6b6;
  padding: 30px;
}
@media (min-width: 480px) and (max-width: 767px) {
  .cart-page-area .cart-page-bottom-left {
    text-align: center;
    padding: 30px 10px;
  }
}
@media only screen and (max-width: 479px) {
  .cart-page-area .cart-page-bottom-left {
    padding: 30px;
    text-align: center;
  }
}
.cart-page-area .cart-page-bottom-left h2 {
  color: #212121;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 20px;
}
.cart-page-area .cart-page-bottom-left input {
  width: 64%;
  height: 50px;
  padding: 5px 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .cart-page-area .cart-page-bottom-left input {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cart-page-area .cart-page-bottom-left input {
    width: 73%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .cart-page-area .cart-page-bottom-left input {
    width: 50%;
  }
}
@media only screen and (max-width: 479px) {
  .cart-page-area .cart-page-bottom-left input {
    width: 100%;
    margin-bottom: 15px;
  }
}
.cart-page-area .cart-page-bottom-left button {
  margin-left: -5px;
}
.cart-page-area .cart-page-bottom-right {
  border: 1px solid #b6b6b6;
  padding: 30px;
}
.cart-page-area .cart-page-bottom-right h2 {
  color: #212121;
  text-transform: capitalize;
  letter-spacing: 2px;
  font-size: 20px;
}
.cart-page-area .cart-page-bottom-right h3 {
  color: #212121;
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 2px;
  border-bottom: 1px solid #b6b6b6;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.cart-page-area .cart-page-bottom-right h3 span {
  float: right;
}
.cart-page-area .cart-page-bottom-right .proceed-button {
  text-align: right;
  margin-top: 20px;
}
/*======================================
25. Order History Page Area styles
========================================*/
.order-history-page-area {
  padding: 100px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .order-history-page-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .order-history-page-area {
    padding: 70px 0;
  }
}
@media only screen and (max-width: 479px) {
  .order-history-page-area {
    padding: 60px 0;
  }
}
.order-history-page-top {
  border-top: 1px solid #b6b6b6;
  border-right: 1px solid #b6b6b6;
  border-left: 1px solid #b6b6b6;
}
.order-history-page-top table {
  margin-bottom: 0;
  border-bottom: 1px solid #b6b6b6;
}
.order-history-page-top table .order-history-form-heading {
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 20px;
  font-size: 18px;
}
.order-history-page-top table tbody tr td {
  font-size: 15px;
  text-transform: capitalize;
  text-align: left;
  padding: 10px 20px;
  vertical-align: middle !important;
  border-right: 1px solid #b6b6b6;
}
.order-history-page-top table tbody tr td:last-child {
  border-right: 0;
}
.order-history-page-top table tbody tr td:first-child {
  padding: 0;
}
.order-history-page-top table tbody tr td:first-child a {
  color: #212121;
  display: block;
  padding: 10px 20px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.order-history-page-top table tbody tr td:first-child a i {
  margin-left: 15px;
}
.order-history-page-top table tbody tr td:first-child a:hover {
  color: #3af;
}
.order-history-page-top table tbody tr .pending {
  color: #7d7d7d;
}
.order-history-page-top table tbody tr .complete {
  color: #55b047;
}
.order-history-page-top table tbody tr .processing {
  color: #f4c505;
}
.order-history-page-top table tbody tr .canceled {
  color: #f22c21;
}
/*======================================
26. Order Details Page Area styles
========================================*/
.order-details-page-area {
  padding: 90px 0 100px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .order-details-page-area {
    padding: 70px 0 80px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .order-details-page-area {
    padding: 60px 0 70px;
  }
}
@media only screen and (max-width: 479px) {
  .order-details-page-area {
    padding: 50px 0 60px;
  }
}
.order-details-page-title {
  margin-bottom: 50px;
}
.order-details-summery {
  margin-bottom: 50px;
  width: 100%;
  background-color: #f8f8f8;
  padding: 30px;
}
.order-details-summery li {
  display: inline-block;
  border-right: 1px solid #dddddd;
  margin-right: 30px;
  padding-right: 30px;
  color: #212121;
  text-transform: uppercase;
}
.order-details-summery li:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.order-details-summery li span {
  margin-top: 5px;
  display: block;
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
}
.order-details-page-top {
  margin-bottom: 50px;
  border-top: 1px solid #b6b6b6;
  border-right: 1px solid #b6b6b6;
  border-left: 1px solid #b6b6b6;
}
.order-details-page-top table {
  margin-bottom: 0;
  border-bottom: 1px solid #b6b6b6;
}
.order-details-page-top table .order-details-form-heading {
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 20px;
  font-size: 18px;
}
.order-details-page-top table tr td {
  padding: 10px 20px;
  vertical-align: middle !important;
  border-right: 1px solid #b6b6b6;
}
.order-details-page-top table tr td:last-child {
  border-right: 0;
}
.order-details-page-top thead tr {
  background-color: #f6f6f6;
}
.order-details-page-bottom {
  border: 1px solid #b6b6b6;
  padding: 30px;
}
.order-details-page-bottom ul li {
  margin-bottom: 8px;
}
.order-details-page-bottom ul li:last-child {
  margin-bottom: 0;
}
/*======================================
27. My Account Page Area styles 
========================================*/
.my-account-page-area {
  padding: 50px 0 100px 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .my-account-page-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .my-account-page-area {
    padding: 70px 0;
  }
}
@media only screen and (max-width: 479px) {
  .my-account-page-area {
    padding: 60px 0;
  }
}
.woocommerce-MyAccount-navigation {
  border: 1px solid #d7d7d7;
  background: #ffffff;
}
.woocommerce-MyAccount-navigation li {
  border-bottom: 1px solid #d7d7d7;
}
.woocommerce-MyAccount-navigation li:last-child {
  border-bottom: 0;
}
.woocommerce-MyAccount-navigation li a {
  padding: 10px 10px 10px 30px;
  position: relative;
  display: block;
  color: #707070;
  font-size: 14px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.woocommerce-MyAccount-navigation li a:before {
  content: "\f105";
  font-family: FontAwesome;
  font-size: 14px;
  color: #444444;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.woocommerce-MyAccount-navigation .active a {
  /* background: #070707; */
  /* linear-gradient(#4154f1, #1d33e6) */
  background: -webkit-linear-gradient(#4154f1, #1d33e6);
  background: -o-linear-gradient(#4154f1, #1d33e6);
  background: -moz-linear-gradient(#4154f1, #1d33e6);
  background: linear-gradient(#4154f1, #1d33e6);
  color: #ffffff;
}
.woocommerce-MyAccount-navigation .active a:before {
  color: #ffffff;
}
.woocommerce-MyAccount-navigation .active a:after {
  background: #111111;
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  z-index: 1;
  bottom: -1px;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 991px) {
  .woocommerce-MyAccount-navigation {
    margin-bottom: 50px;
  }
}
.woocommerce-MyAccount-content p a {
  font-weight: 600;
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.woocommerce-MyAccount-content p a:hover {
  color: #070707;
}
.woocommerce-MyAccount-content input.search-input:focus {
  outline-width: 0;
  outline: none;
}
.woocommerce-MyAccount-content input::-webkit-input-placeholder,
.woocommerce-MyAccount-content textarea::-webkit-input-placeholder {
  color: #212121;
}
.woocommerce-MyAccount-content input::-moz-placeholder,
.woocommerce-MyAccount-content textarea::-moz-placeholder {
  color: #212121;
}
.woocommerce-MyAccount-content input:-moz-placeholder,
.woocommerce-MyAccount-content textarea:-moz-placeholder {
  color: #212121;
}
.woocommerce-MyAccount-content input:-ms-input-placeholder,
.woocommerce-MyAccount-content textarea:-ms-input-placeholder {
  color: #212121;
}
.woocommerce-MyAccount-content legend {
  border-bottom: 0;
}
.woocommerce-MyAccount-content header {
  display: block !important;
}
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
  width: 100%;
  padding: 5px 10px;
  color: #212121;
}
.woocommerce form .form-row input.input-text:focus,
.woocommerce form .form-row textarea:focus {
  outline: none;
}
.metro-title {
  position: relative;
  font-weight: 600;
  margin-bottom: 30px;
}
.metro-title:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b6b6b6;
  border-top: 1px solid #b6b6b6;
  height: 4px;
  background: 0 0;
  width: 50%;
  right: 15px;
  top: 45%;
  z-index: 1;
}
@media only screen and (max-width: 479px) {
  .metro-title:after {
    display: none;
  }
}
.metro-title:before {
  display: none;
}
.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  padding: 1em 2em 1em 3.5em;
  margin: 0 0 2em;
  position: relative;
  background-color: #f7f6f7;
  color: #515151;
  border-top: 3px solid #111111;
  list-style: none outside;
  width: auto;
  word-wrap: break-word;
}
.woocommerce-error a,
.woocommerce-info a,
.woocommerce-message a {
  color: #212121;
  font-weight: 600;
  padding: 5px 20px;
  margin-left: 30px;
  border: 1px solid #212121;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.woocommerce-error a:hover,
.woocommerce-info a:hover,
.woocommerce-message a:hover {
  background-color: #212121;
  color: #ffffff;
}
.woocommerce-error .woocommerce-Button,
.woocommerce-info .woocommerce-Button,
.woocommerce-message .woocommerce-Button {
  position: absolute;
  top: 10px;
  right: 15px;
}
.woocommerce-message::before {
  content: "\f00c";
  color: #ffffff;
  font-family: fontawesome;
  display: inline-block;
  position: absolute;
  top: 1.5em;
  left: 1.5em;
  height: 20px;
  width: 20px;
  line-height: 22px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  font-size: 12px;
  background-color: #111111;
}
.woocommerce-info::before {
  content: "\f24d";
  color: #111111;
  font-family: fontawesome;
  display: inline-block;
  position: absolute;
  top: 1em;
  left: 1.5em;
  font-size: 15px;
}
.woocommerce-Address {
  margin-bottom: 45px;
}
.woocommerce-Address header .edit {
  position: relative;
  float: right;
  color: #111111;
}
.woocommerce-Address header .edit:before {
  margin-right: 5px;
  content: "\f040";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  font-size: 13px;
}
.woocommerce .woocommerce-MyAccount-content h3 {
  margin-top: 0px;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 5px;
  position: relative;
  color: #212121;
}
.woocommerce .woocommerce-MyAccount-content h3:before {
  content: "";
  position: absolute;
  border-bottom: 1px solid #b6b6b6;
  border-top: 1px solid #b6b6b6;
  height: 4px;
  background: 0 0;
  width: 50%;
  right: 0;
  top: 45%;
  z-index: 1;
}
@media only screen and (max-width: 479px) {
  .woocommerce .woocommerce-MyAccount-content h3:before {
    display: none;
  }
}
/*======================================
28. Contact Page Area styles 
========================================*/
.contact-us-page-area {
  padding: 100px 0 80px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .contact-us-page-area {
    padding: 80px 0 60px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .contact-us-page-area {
    padding: 70px 0 50px;
  }
}
@media only screen and (max-width: 479px) {
  .contact-us-page-area {
    padding: 60px 0 40px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-us-page-area .contact-us-left {
    margin-bottom: 30px;
  }
}
.contact-us-page-area .contact-us-left .google-map-area {
  margin-bottom: 50px;
}
.contact-us-page-area .contact-us-left h2 {
  font-size: 24px;
  color: #212121;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.contact-us-page-area .contact-us-left .contact-form .form-group {
  margin-bottom: 20px;
}
.contact-us-page-area .contact-us-left .contact-form .form-group .form-control {
  border-radius: 0;
  background: #f9f9f9;
  box-shadow: none;
  border: none;
}
.contact-us-page-area .contact-us-left .contact-form .form-group input {
  height: 45px;
}
.contact-us-page-area .contact-us-left .form-response .alert-info {
  background: transparent !important;
  border: none !important;
  color: #a8a7a7;
  letter-spacing: 10px;
}
.contact-us-page-area .contact-us-left .form-response .alert-success {
  background-color: transparent;
  color: #070707;
  letter-spacing: 8px;
  border-color: transparent;
  font-weight: 500;
}
@media only screen and (max-width: 479px) {
  .contact-us-page-area .contact-us-left .form-response .alert-success {
    letter-spacing: 1px;
  }
}
.contact-us-page-area .contact-us-left .form-response .alert {
  border-radius: 0 !important;
  padding: 15px 0;
}
.contact-us-page-area .contact-us-right ul li {
  padding-left: 35px;
  margin-bottom: 15px;
}
.contact-us-page-area .contact-us-right ul .con-address {
  position: relative;
}
.contact-us-page-area .contact-us-right ul .con-address:after {
  content: "\f041";
  font-family: fontawesome;
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 24px;
  color: #111111;
}
.contact-us-page-area .contact-us-right ul .con-envelope {
  position: relative;
}
.contact-us-page-area .contact-us-right ul .con-envelope:after {
  content: "\f003";
  font-family: fontawesome;
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #111111;
}
.contact-us-page-area .contact-us-right ul .con-phone {
  position: relative;
}
.contact-us-page-area .contact-us-right ul .con-phone:after {
  content: "\f095";
  font-family: fontawesome;
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #111111;
}
.contact-us-page-area .contact-us-right ul .con-fax {
  position: relative;
}
.contact-us-page-area .contact-us-right ul .con-fax:after {
  content: "\f1ac";
  font-family: fontawesome;
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #111111;
}
/*======================================
29. 404 Error Page Area styles
========================================*/
.page-error-area {
  padding: 100px 0;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .page-error-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .page-error-area {
    padding: 70px 0;
  }
}
@media only screen and (max-width: 479px) {
  .page-error-area {
    padding: 60px 0;
  }
}
.page-error-area .page-error-top {
  background: #111111;
  padding: 60px 0 120px 0;
}
@media only screen and (max-width: 479px) {
  .page-error-area .page-error-top {
    padding: 0 0 30px 0;
  }
}
.page-error-area .page-error-top span {
  font-size: 300px;
  color: #ffffff;
  margin-bottom: 35px;
  line-height: 300px;
  font-weight: 600;
  display: inline-block;
}
@media (min-width: 480px) and (max-width: 767px) {
  .page-error-area .page-error-top span {
    font-size: 240px;
  }
}
@media only screen and (max-width: 479px) {
  .page-error-area .page-error-top span {
    font-size: 150px;
    margin-bottom: 0;
  }
}
.page-error-area .page-error-top p {
  letter-spacing: 3px;
  font-weight: 600;
  color: #ffffff;
  font-size: 30px;
  line-height: 1.5;
}
.page-error-area .page-error-bottom {
  margin-top: 45px;
}
.page-error-area .page-error-bottom p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
  color: #7a7a7a;
}
/*======================================
30. Footer Area styles 
========================================*/
.footer-area {
  padding: 93px 0 0;
  /* background: #eaeaea; */
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer-area {
    padding: 73px 0 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .footer-area {
    padding: 63px 0 0;
  }
}
@media only screen and (max-width: 479px) {
  .footer-area {
    padding: 53px 0 0;
  }
}
.footer-area .footer-area-top {
  padding-bottom: 60px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer-area .footer-area-top {
    padding-bottom: 40px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .footer-area .footer-area-top {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 479px) {
  .footer-area .footer-area-top {
    padding-bottom: 20px;
  }
}
.footer-area .footer-area-top .footer-box {
  padding-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .footer-area .footer-area-top .footer-box {
    margin-bottom: 30px;
  }
}
.footer-area .footer-area-top .footer-box h3 {
  color: #4154f1;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 27px;
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  .footer-area .footer-area-top .footer-box h3 {
    margin-bottom: 15px;
  }
}
.footer-area .footer-area-top .footer-box p {
  margin-bottom: 15px;
}
.footer-area .footer-area-top .footer-box .info-list {
  padding-top: 5px;
}
.footer-area .footer-area-top .footer-box .info-list li {
  font-size: 14px;
  letter-spacing: 2px;
  margin-bottom: 20px;
  line-height: 1;
}
.footer-area .footer-area-top .footer-box .info-list li a {
  color: #333333;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.footer-area .footer-area-top .footer-box .info-list li a:hover {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.footer-area .footer-area-top .footer-box .info-list li:last-child {
  margin-bottom: 0;
}
.footer-area .footer-area-top .footer-box .tag-list {
  padding-top: 5px;
}
.footer-area .footer-area-top .footer-box .tag-list li {
  display: inline-block;
  margin-bottom: 7px;
  margin-right: 3px;
  border: 1px solid #727272;
  background: transparent;
  font-size: 14px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.footer-area .footer-area-top .footer-box .tag-list li a {
  color: #333333;
  display: block;
  padding: 5px 17px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.footer-area .footer-area-top .footer-box .tag-list li:hover {
  border: 1px solid #111111;
  background: #111111;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.footer-area .footer-area-top .footer-box .tag-list li:hover a {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.footer-area .footer-area-top .footer-box .footer-social {
  margin-bottom: 24px;
}
.footer-area .footer-area-top .footer-box .footer-social li {
  display: inline-block;
  margin-right: 5px;
}
.footer-area .footer-area-top .footer-box .footer-social li a {
  width: 32px;
  line-height: 32px;
  border-radius: 50%;
  height: 32px;
  border: 1px solid #111111;
  display: block;
  text-align: center;
  background: transparent;
}
.footer-area .footer-area-top .footer-box .footer-social li a i {
  color: #111111;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.footer-area .footer-area-top .footer-box .footer-social li a:hover {
  border: 1px solid #111111;
  background: #111111;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.footer-area .footer-area-top .footer-box .footer-social li a:hover i {
  color: #ffffff;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.footer-area .footer-area-top .footer-box .newsletter-area h3 {
  margin-bottom: 15px;
}
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  .stylish-input-group {
  border: 1px solid #111111 !important;
  border: none;
  padding: 0 !important;
}
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  .stylish-input-group
  .form-control {
  border: none;
  box-shadow: 0 0 0;
  border-radius: 0;
  background: #ffffff;
  text-transform: uppercase;
  color: #ffffff;
  height: 34px;
  border-top: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
}
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  .stylish-input-group
  .input-group-addon {
  background: #ffffff !important;
  border: none;
  border-radius: 0;
  padding: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  .stylish-input-group
  .input-group-addon
  button {
  border: 0;
  background: #111111;
  padding: 10px 17px;
}
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  .stylish-input-group
  .input-group-addon
  button
  i {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  .stylish-input-group
  .input-group-addon
  button:hover {
  background: transparent !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  .stylish-input-group
  .input-group-addon
  button:hover
  i {
  color: #111111;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  input.form-control::-webkit-input-placeholder,
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  textarea.form-control::-webkit-input-placeholder {
  color: #000000;
}
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  input.form-control::-moz-placeholder,
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  textarea.form-control::-moz-placeholder {
  color: #000000;
}
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  input.form-control:-moz-placeholder,
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  textarea.form-control:-moz-placeholder {
  color: #000000;
}
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  input.form-control:-ms-input-placeholder,
.footer-area
  .footer-area-top
  .footer-box
  .newsletter-area
  textarea.form-control:-ms-input-placeholder {
  color: #000000;
}
.footer-area .footer-area-bottom {
  padding: 30px 0 0;
  text-transform: capitalize;
  letter-spacing: 2px;
  font-size: 13px;
  /* background: #4154f1; */
  background: #f1f1f1;
}
@media only screen and (max-width: 991px) {
  .footer-area .footer-area-bottom {
    text-align: center;
  }
}
.footer-area .footer-area-bottom p {
  color: #212121;
}
.footer-area .footer-area-bottom p a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.footer-area .footer-area-bottom p a:hover {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.footer-area .footer-area-bottom .payment-method {
  text-align: right;
}
@media only screen and (max-width: 991px) {
  .footer-area .footer-area-bottom .payment-method {
    text-align: center;
    margin-bottom: 30px;
  }
}
.footer-area .footer-area-bottom .payment-method li {
  display: inline-block;
}
.footer-area.dark-footer {
  background-color: #111111;
}
.footer-area.dark-footer .footer-box h3 {
  color: #ffffff;
}
.footer-area.dark-footer .footer-box p {
  color: #9c9c9c;
}
.footer-area.dark-footer .footer-box .info-list li a {
  color: #9c9c9c;
}
.footer-area.dark-footer .footer-box .info-list li a:hover {
  color: #ffffff;
}
.footer-area.dark-footer .footer-box .tag-list li {
  background-color: transparent;
}
.footer-area.dark-footer .footer-box .tag-list li a {
  color: #9c9c9c;
}
.footer-area.dark-footer .footer-box .tag-list li:hover {
  border: 1px solid #ffffff;
  background: #ffffff;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.footer-area.dark-footer .footer-box .tag-list li:hover a {
  color: #212121;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}
.footer-area.dark-footer .footer-box .footer-social {
  margin-bottom: 24px;
}
.footer-area.dark-footer .footer-box .footer-social li {
  display: inline-block;
  margin-right: 5px;
}
.footer-area.dark-footer .footer-box .footer-social li a {
  width: 32px;
  line-height: 32px;
  border-radius: 0;
  height: 32px;
  border: 1px solid #292f33;
  display: block;
  text-align: center;
  background: #15191c;
}
.footer-area.dark-footer .footer-box .footer-social li a i {
  color: #b5b5b5;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.footer-area.dark-footer .footer-box .footer-social li a:hover {
  border: 1px solid #292f33;
}
.footer-area.dark-footer .footer-box .footer-social li a:hover i {
  color: #ffffff;
}
.footer-area.dark-footer
  .footer-box
  .newsletter-area
  .stylish-input-group
  .form-control {
  background: #262626;
  color: #ffffff;
  height: 44px;
  border: none;
}
.footer-area.dark-footer
  .footer-box
  .newsletter-area
  .stylish-input-group
  .input-group-addon
  button {
  background: #ffffff;
  padding: 15px 17px;
}
.footer-area.dark-footer
  .footer-box
  .newsletter-area
  .stylish-input-group
  .input-group-addon
  button
  i {
  color: #212121;
}
.footer-area.dark-footer
  .footer-box
  .newsletter-area
  input.form-control::-webkit-input-placeholder,
.footer-area.dark-footer
  .footer-box
  .newsletter-area
  textarea.form-control::-webkit-input-placeholder {
  color: #9c9c9c;
}
.footer-area.dark-footer
  .footer-box
  .newsletter-area
  input.form-control::-moz-placeholder,
.footer-area.dark-footer
  .footer-box
  .newsletter-area
  textarea.form-control::-moz-placeholder {
  color: #9c9c9c;
}
.footer-area.dark-footer
  .footer-box
  .newsletter-area
  input.form-control:-moz-placeholder,
.footer-area.dark-footer
  .footer-box
  .newsletter-area
  textarea.form-control:-moz-placeholder {
  color: #9c9c9c;
}
.footer-area.dark-footer
  .footer-box
  .newsletter-area
  input.form-control:-ms-input-placeholder,
.footer-area.dark-footer
  .footer-box
  .newsletter-area
  textarea.form-control:-ms-input-placeholder {
  color: #9c9c9c;
}
.footer-area.dark-footer .footer-area-bottom {
  border-top: 1px solid #464646;
  background: #111111;
}
.footer-area.dark-footer .footer-area-bottom p {
  color: #6f6f6f;
}
.footer-area.dark-footer .footer-area-bottom p a {
  color: #c4c4c4;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.footer-area.dark-footer .footer-area-bottom p a:hover {
  color: #ffffff;
}
/*======================================
31. Modal Dialog Area styles 
========================================*/
.modal-dialog {
  background: #ffffff;
  width: 50%;
}
@media (max-width: 768px) and (max-width: 1199px) {
  .modal-dialog {
    width: 73%;
  }
}
@media (max-width: 480px) and (max-width: 767px) {
  .modal-dialog {
    width: 80%;
  }
}
@media only screen and (max-width: 479px) {
  .modal-dialog {
    width: 95%;
  }
}
.modal-dialog .modal-body .myclose {
  font-size: 40px;
  margin-right: 8px;
  font-weight: normal;
  line-height: 0.5;
}
.modal-dialog .product-details1-area {
  padding: 0;
}
.modal-dialog .product-details1-area .product-details-info-area {
  padding: 30px;
}
.modal-dialog .product-details1-area .product-details-social {
  margin-bottom: 50px;
}
.modal-dialog .inner-product-details-cart li:hover {
  border: 1px solid #111111 !important;
}
.modal-dialog .inner-product-details-cart li:hover:nth-child(2) {
  border: 1px solid #d7d7d7 !important;
}
.modal-dialog .inner-product-details-cart li a {
  background: #111111 !important;
  color: #ffffff !important;
}
.modal-dialog .inner-product-details-cart li a:hover {
  background: transparent !important;
  color: #111111 !important;
}
.modal-dialog .inner-product-details-cart li a:hover i {
  color: #111111 !important;
}
.modal-dialog .product-details1-area .inner-product-details-left ul li {
  margin: 16px 0 0 0;
}
/*======================================
32. Switch Styling Area styles 
========================================*/
.style-switch {
  background-color: #676767;
}
.style-switch ul .active a {
  color: #111111;
  font-weight: 400;
}
.style-switch .switch-button {
  background-color: #111111;
  color: #ffffff;
}
.services-box-layout1 {
  padding: 20px 8px 0 20px;
}
@media only screen and (max-width: 1440px) {
  .services-box-layout1 {
    padding: 8px 8px 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .services-box-layout1 {
    text-align: center;
    margin: 10px auto;
  }
}
@media (min-width: 479px) and (max-width: 767px) {
  .services-box-layout1 {
    margin: 10px auto;
  }
}
@media only screen and (max-width: 479px) {
  .services-box-layout1 {
    margin: 10px auto;
  }
}
@media only screen and (max-width: 1440px) {
  .services-box-layout1 .media {
    text-align: center;
  }
}
@media only screen and (max-width: 1440px) {
  .services-box-layout1 .media a {
    float: none !important;
  }
}
@media only screen and (max-width: 1440px) {
  .services-box-layout1 .media a img {
    display: inherit;
  }
}
.services-box-layout1 .media-body {
  padding-top: 20px;
}
.services-box-layout1 .media-body span {
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 30px;
  display: block;
}
.services-box-layout1 .media-body h3 {
  letter-spacing: 4px;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 0px;
}
@media only screen and (max-width: 1199px) {
  .services-box-layout1 .media-body h3 {
    font-size: 22px;
  }
}
.services-box-layout1 .media-body h3 a {
  color: #ffffff;
}
.services-box-layout1 .media-body p {
  letter-spacing: 9px;
  text-transform: uppercase;
  color: #212121;
  font-weight: 500;
}
@media (min-width: 768px) and (max-width: 991px) {
  .services-box-layout1 .media-body p {
    letter-spacing: 7px;
  }
}
.services-box-layout2 {
  padding: 20px 8px 20px 20px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1440px) {
  .services-box-layout2 .media {
    text-align: center;
  }
}
@media only screen and (max-width: 1440px) {
  .services-box-layout2 .media a {
    float: none !important;
  }
}
@media only screen and (max-width: 1440px) {
  .services-box-layout2 .media a img {
    display: inherit;
  }
}
.services-box-layout2 .media-body {
  padding-top: 20px;
}
.services-box-layout2 .media-body span {
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 30px;
  display: block;
}
.services-box-layout2 .media-body h3 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0px;
}
@media only screen and (max-width: 1199px) {
  .services-box-layout2 .media-body h3 {
    font-size: 24px;
  }
}
.services-box-layout2 .media-body h3 a {
  color: #212121;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.services-box-layout2 .media-body h3 a:hover {
  color: #ff6600;
}
.services-box-layout2 .media-body p {
  text-transform: capitalize;
  color: #212121;
  font-size: 14px;
}
/*======================================
33. Metro Banner Area styles 
========================================*/
.metro-banner-layout1 {
  position: relative;
  margin-bottom: 30px;
}
.metro-banner-layout1 img {
  width: 100%;
}
@media (min-width: 479px) and (max-width: 991px) {
  .metro-banner-layout1 {
    margin-top: 72px;
  }
}
@media only screen and (max-width: 767px) {
  .metro-banner-layout1 {
    text-align: center;
    margin-bottom: 50px;
  }
  .metro-banner-layout1 img {
    margin: 0 auto;
  }
}
.metro-banner-layout1 .mask-item {
  position: absolute;
  bottom: 30px;
  left: 30px;
}
@media only screen and (max-width: 767px) {
  .metro-banner-layout1 .mask-item {
    left: 0;
    right: 0;
  }
}
.metro-banner-layout2 {
  position: relative;
}
.metro-banner-layout2 .mask-item {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  z-index: 1;
}
.metro-banner-layout3 {
  position: relative;
  margin-bottom: 30px;
}
.metro-banner-layout3 img {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .metro-banner-layout3 {
    text-align: center;
    margin-bottom: 50px;
  }
  .metro-banner-layout3 img {
    margin: 0 auto;
  }
}
.metro-banner-layout3 .mask-item {
  position: absolute;
  bottom: 30px;
  left: 30px;
}
@media only screen and (max-width: 767px) {
  .metro-banner-layout3 .mask-item {
    left: 0;
    right: 0;
  }
}
@media only screen and (max-width: 991px) {
  .offer-banner-layout1 {
    opacity: 1;
    visibility: visible;
  }
}
.offer-banner-layout1 .offer-banner-col {
  position: relative;
}
.offer-banner-layout1 .offer-banner-col:nth-child(1n):after {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (max-width: 991px) {
  .offer-banner-layout1 .offer-banner-col:nth-child(1n):after {
    opacity: 1;
    visibility: visible;
  }
}
.offer-banner-layout1 .offer-banner-col:nth-child(2n):after {
  content: "";
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (max-width: 991px) {
  .offer-banner-layout1 .offer-banner-col:nth-child(2n):after {
    opacity: 1;
    visibility: visible;
  }
}
.offer-banner-layout1 .offer-banner-col .item-img img {
  width: 100%;
}
.offer-banner-layout1 .offer-banner-col .item-content {
  position: absolute;
  right: 60px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
}
@media only screen and (max-width: 767px) {
  .offer-banner-layout1 .offer-banner-col .item-content {
    right: 0;
    left: 0;
    text-align: center;
  }
}
.offer-banner-layout1 .offer-banner-col .item-content h2 {
  font-weight: 600;
  font-size: 60px;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 1.2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .offer-banner-layout1 .offer-banner-col .item-content h2 {
    font-size: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .offer-banner-layout1 .offer-banner-col .item-content h2 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 479px) {
  .offer-banner-layout1 .offer-banner-col .item-content h2 {
    font-size: 24px;
  }
}
.offer-banner-layout1 .offer-banner-col .item-content span {
  letter-spacing: 20px;
  margin-bottom: 30px;
  display: block;
}
@media only screen and (max-width: 575px) {
  .offer-banner-layout1 .offer-banner-col .item-content span {
    margin-bottom: 10px;
  }
}
.offer-banner-layout1 .offer-banner-col .item-content .title-light {
  color: #ffffff;
}
.offer-banner-layout1 .offer-banner-col .item-content .title-dark {
  color: #212121;
}
.offer-banner-layout1 .offer-banner-col .item-price {
  margin: 0 auto;
  font-weight: 600;
  line-height: 100px;
  text-align: center;
  font-size: 30px;
  height: 100px;
  width: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  color: #212121;
  position: absolute;
  top: 20%;
  left: 30px;
  z-index: 4;
}
@media only screen and (max-width: 767px) {
  .offer-banner-layout1 .offer-banner-col .item-price {
    display: none;
  }
}
.offer-banner-layout2 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 991px) {
  .offer-banner-layout2 {
    display: inherit;
  }
}
.offer-banner-layout2 .item-img .item-price {
  margin: 0 auto;
  font-weight: 600;
  line-height: 100px;
  text-align: center;
  font-size: 30px;
  height: 100px;
  width: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  color: #212121;
  position: absolute;
  top: 30%;
  right: 35%;
  z-index: 4;
}
@media only screen and (max-width: 767px) {
  .offer-banner-layout2 .item-img .item-price {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .offer-banner-layout2 .item-content {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .offer-banner-layout2 .item-content {
    padding-top: 50px;
  }
}
@media only screen and (max-width: 479px) {
  .offer-banner-layout2 .item-content {
    padding-top: 30px;
  }
}
.offer-banner-layout2 .item-content .sub-title {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
}
.offer-banner-layout2 .item-content .title {
  font-size: 60px;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.1;
}
@media only screen and (max-width: 767px) {
  .offer-banner-layout2 .item-content .title {
    font-size: 48px;
  }
}
@media only screen and (max-width: 479px) {
  .offer-banner-layout2 .item-content .title {
    font-size: 36px;
  }
}
.offer-banner-layout2 .item-content .title span {
  font-weight: 400;
}
.offer-banner-layout2 .item-content p {
  margin-bottom: 40px;
}
.offer-banner-layout3 .offer-banner-box {
  background-color: #ffffff;
}
.offer-banner-layout3 .offer-banner-box ul li {
  margin-right: 15px;
  display: inline-block;
}
.offer-banner-layout3 .offer-banner-box ul li:last-child {
  margin-right: 0;
}
.offer-banner-layout3 .offer-banner-box .left-banner {
  border: 1px solid #dedede;
  padding: 30px;
  border-right: 0;
}
.offer-banner-layout3 .offer-banner-box .left-banner h3 {
  font-weight: 700;
  font-size: 36px;
}
@media only screen and (max-width: 767px) {
  .offer-banner-layout3 .offer-banner-box .left-banner h3 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 479px) {
  .offer-banner-layout3 .offer-banner-box .left-banner h3 {
    font-size: 26px;
  }
}
.offer-banner-layout3 .offer-banner-box .left-banner p {
  font-size: 16px;
}
.offer-banner-layout3 .offer-banner-box .left-banner .pull-left {
  width: 60%;
  padding-right: 30px;
}
.offer-banner-layout3 .offer-banner-box .left-banner .countdown {
  text-align: left;
  margin-bottom: 10px;
}
.offer-banner-layout3 .offer-banner-box .left-banner .prev-price {
  text-decoration: line-through;
  color: #bababa;
  font-size: 20px;
}
.offer-banner-layout3 .offer-banner-box .left-banner .price {
  font-size: 30px;
  font-weight: 600;
  color: #ed1c24;
  margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  .offer-banner-layout3 .offer-banner-box .left-banner .media .pull-left {
    width: auto;
    padding: 0;
  }
  .offer-banner-layout3
    .offer-banner-box
    .left-banner
    .media
    .pull-left
    .countdown {
    text-align: center;
  }
}
.offer-banner-layout3 .offer-banner-box .left-banner .media .media-body {
  text-align: center;
}
.offer-banner-layout3 .offer-banner-box .left-banner .media .media-body img {
  margin: 0 auto;
}
.offer-banner-layout3 .offer-banner-box .right-banner {
  border: 1px solid #dedede;
  padding: 30px;
}
.offer-banner-layout3 .offer-banner-box .right-banner:first-child {
  border-bottom: 0;
}
.offer-banner-layout3 .offer-banner-box .right-banner h3 {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 5px;
}
.offer-banner-layout3 .offer-banner-box .right-banner p {
  font-size: 16px;
  margin-bottom: 10px;
}
.offer-banner-layout3 .offer-banner-box .right-banner .pull-left {
  width: 60%;
  padding-right: 30px;
}
.offer-banner-layout3 .offer-banner-box .right-banner .prev-price {
  text-decoration: line-through;
  color: #bababa;
  font-size: 16px;
}
.offer-banner-layout3 .offer-banner-box .right-banner .price {
  font-size: 18px;
  font-weight: 600;
  color: #ed1c24;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .offer-banner-layout3 .offer-banner-box .right-banner .media .pull-left {
    width: auto;
    padding: 0;
    margin-bottom: 30px;
    text-align: center;
  }
  .offer-banner-layout3
    .offer-banner-box
    .right-banner
    .media
    .pull-left
    .countdown {
    text-align: center;
  }
}
.offer-banner-layout3 .offer-banner-box .right-banner .media .media-body {
  text-align: center;
}
.offer-banner-layout3 .offer-banner-box .right-banner .media .media-body img {
  margin: 0 auto;
}
.banner-layout2 {
  padding-top: 50px;
  text-align: center;
  margin-bottom: 50px;
}
.banner-layout2 .sub-title {
  letter-spacing: 20px;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 20px;
  color: #212121;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .banner-layout2 .sub-title {
    letter-spacing: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .banner-layout2 .sub-title {
    letter-spacing: 5px;
  }
}
.banner-layout2 .title {
  font-weight: 300;
  font-size: 60px;
  text-transform: uppercase;
}
@media only screen and (max-width: 1199px) {
  .banner-layout2 .title {
    font-size: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-layout2 .title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 479px) {
  .banner-layout2 .title {
    font-size: 30px;
  }
}
.banner-layout2 .title span {
  font-weight: 700;
}
.banner-layout2 .item-img {
  position: relative;
}
.banner-layout2 .item-img img {
  margin: 0 auto;
  position: relative;
  bottom: -50px;
}
.banner-layout2 .item-img .item-price {
  margin: 0 auto;
  font-weight: 600;
  line-height: 100px;
  text-align: center;
  font-size: 30px;
  height: 100px;
  width: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 20%;
  z-index: 4;
  -webkit-box-shadow: 0 10px 20px 0 rgba(141, 147, 88, 0.75);
  -moz-box-shadow: 0 10px 20px 0 rgba(141, 147, 88, 0.75);
  box-shadow: 0 10px 20px 0 rgba(141, 147, 88, 0.75);
}
@media only screen and (max-width: 767px) {
  .banner-layout2 .item-img .item-price {
    display: none;
  }
}
/*======================================
34. Countdown Area styles 
========================================*/
.countdown-layout1 .countdown {
  margin: 0 auto;
  top: 40%;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .countdown-layout1 .countdown {
    top: 38%;
  }
}
@media only screen and (max-width: 767px) {
  .countdown-layout1 .countdown {
    top: 37%;
  }
}
@media only screen and (max-width: 479px) {
  .countdown-layout1 .countdown {
    top: 40%;
  }
}
@media only screen and (max-width: 320px) {
  .countdown-layout1 .countdown {
    top: 43%;
  }
}
.countdown-layout1 .countdown .countdown-section {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
.countdown-layout1 .countdown .countdown-section:last-child {
  margin-right: 0;
}
.countdown-layout1 .countdown .countdown-section h3 {
  height: 40px;
  width: 44px;
  line-height: 38px;
  font-size: 18px;
  color: #212121;
  margin-bottom: 0;
  background: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
}
.countdown-layout1 .countdown .countdown-section p {
  color: #a5a5a5;
  text-transform: uppercase;
  font-size: 13px;
}
.countdown-layout2 .countdown {
  margin: 0 auto;
  top: 40%;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .countdown-layout2 .countdown {
    top: 38%;
  }
}
@media only screen and (max-width: 767px) {
  .countdown-layout2 .countdown {
    top: 37%;
  }
}
@media only screen and (max-width: 479px) {
  .countdown-layout2 .countdown {
    top: 40%;
  }
}
@media only screen and (max-width: 320px) {
  .countdown-layout2 .countdown {
    top: 43%;
  }
}
.countdown-layout2 .countdown .countdown-section {
  border-right: 1px solid #c9c9c9;
  height: 75px;
  width: 75px;
  background: #ffffff;
  display: inline-block;
  text-align: center;
}
@media only screen and (max-width: 479px) {
  .countdown-layout2 .countdown .countdown-section {
    width: 70px;
  }
}
.countdown-layout2 .countdown .countdown-section:first-child {
  -webkit-border-radius: 45px 0 0 45px;
  -moz-border-radius: 45px 0 0 45px;
  -ms-border-radius: 45px 0 0 45px;
  -o-border-radius: 45px 0 0 45px;
  border-radius: 45px 0 0 45px;
}
.countdown-layout2 .countdown .countdown-section:last-child {
  border-right: 0;
  -webkit-border-radius: 0 45px 45px 0;
  -moz-border-radius: 0 45px 45px 0;
  -ms-border-radius: 0 45px 45px 0;
  -o-border-radius: 0 45px 45px 0;
  border-radius: 0 45px 45px 0;
}
.countdown-layout2 .countdown .countdown-section h3 {
  margin-top: 10px;
  font-size: 22px;
  color: #212121;
  margin-bottom: 0;
}
.countdown-layout2 .countdown .countdown-section p {
  color: #a5a5a5;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.1;
}
/*======================================
35. Section Space Area styles 
========================================*/
.section-space-equal {
  padding: 90px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .section-space-equal {
    padding: 80px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-space-equal {
    padding: 70px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .section-space-equal {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 479px) {
  .section-space-equal {
    padding: 50px 0;
  }
}
.section-space-less30 {
  padding: 90px 0 60px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .section-space-less30 {
    padding: 80px 0 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-space-less30 {
    padding: 70px 0 40px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .section-space-less30 {
    padding: 60px 0 30px;
  }
}
@media only screen and (max-width: 479px) {
  .section-space-less30 {
    padding: 50px 0 20px;
  }
}
.section-space-custom {
  padding: 240px 0 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .section-space-custom {
    padding: 230px 0 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-space-custom {
    padding: 220px 0 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .section-space-custom {
    padding: 0;
  }
}
@media only screen and (max-width: 479px) {
  .section-space-custom {
    padding: 0;
  }
}
.section-space-default-less30 {
  padding: 80px 0 60px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .section-space-default-less30 {
    padding: 70px 0 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-space-default-less30 {
    padding: 60px 0 40px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .section-space-default-less30 {
    padding: 50px 0 30px;
  }
}
@media only screen and (max-width: 479px) {
  .section-space-default-less30 {
    padding: 40px 0 20px;
  }
}
/*=======================================================================
[12] Get Quote Area Style
=========================================================================*/
.get-quote-section-space {
  padding: 60px 0;
}
.get-quote {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.get-quote .item-title h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #ffffff;
}
.get-quote .item-title p {
  color: #fffefe;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0;
}
button {
  /* z-index: 2; */
  border: none;
  outline: none;
  color: #ffffff;
  line-height: 1;
  /* font-size: 16px; */
  /* overflow: hidden; */
  padding: 19px 50px;
  padding-bottom: 20px;
  text-align: center;
  /* position: relative; */
  border-radius: 45px;
  -webkit-transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.error {
  border: 1px solid #f65f7c !important;
}
.success-text {
  color: #4154f1 !important;
  font-size: 17px;
  margin-top: 20px !important;
  text-align: left;
}
.invalid-text {
  color: #f65f7c !important;
  font-size: 14px;
  /* margin-top: 10px; */
  text-align: center;
  margin-bottom: 0px !important;
}
.align-right {
  text-align: right !important;
}
.capitalize {
  text-transform: capitalize;
}
.product-price {
  margin-right: 10px;
  text-decoration: line-through;
}
.blu-color {
  color: #4154f1;
}
.time-remaining {
  font-size: 22px;
  margin-top: 40px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.lbl-review {
  display: flex;
}
.input-review {
  padding: 10px;
  width: 100%;
}
.bold {
  font-weight: bold;
}
.product-details2-area .product-details-tab-area ul.product-reviews {
  /* display: inline-block; */
  /* margin-left: 10px; */
}
.product-details2-area .product-details-tab-area ul.product-reviews > li {
  background: none;
  color: #eebe2c;
}
.review-text {
  font-style: italic;
  /* background: #f1f1f1;
  padding: 10px 9px;
  border-radius: 5px; */
}
.review-panel {
  background: #f1f1f1;
  padding: 20px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.p-0 {
  padding: 0;
}
.border-none {
  border: none !important;
}
.text-center {
  text-align: center;
}
.w-20 {
  width: 20% !important;
}
.red {
  color: #f65f7c;
}
.mt10 {
  margin-top: 10px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt42{
  margin-top: 42px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt100 {
  margin-top: 100px !important;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.display-flex {
  display: inline-flex !important;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}
.custom-dropdown {
  height: 40px;
  padding: 5px;
  width: 182px;
}
.license-panel {
  background: #f1f1f1;
  padding: 15px 20px;
}
.display-inline {
  display: inline;
}
.blue {
  color: #4154f1;
}
.little-text {
  color: #4154f1;
  font-size: 13px;
  margin-top: 7px;
  margin-bottom: 9px;
}
.dashboard-heading {
  /* color: #4154f1; */
  font-weight: 700;
  border: 1px solid #d7d7d7;
  padding: 10px 10px;
  background-color: #d7d7d7;
  height: 45px;
  border-radius: 4px;
}
.dashboarder .media-body {
  padding: 0px 0px 0 0px;
}
.dashboarder h3 {
  font-size: 16px !important;
}
.dashboarder p {
  font-size: 13px !important;
}
/* .dashboard .product-box2 .media-body .product-box2-cart{
  margin-top: 20px !important;
} */
.dashboarder .product-box2 {
  /* margin-bottom: 50px; */
  border: 1px solid #d7d7d7;
  padding: 14px !important;
}
.dashboarder .product-box2 .media-body .product-box2-cart li {
  border: none !important;
}
.dashboarder .product-box2 .media-body .product-box2-cart li a {
  line-height: 30px;
  text-align: center;
  height: 30px;
  padding: 0 20px;
  background: #d7d7d7;
  display: block;
  color: #111111;
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 600;
  transition: all 0.5s ease-out;
  border-radius: 9px;
}
.dashboarder .product-box2 .media-body .product-box2-cart li {
  margin: 0 5px 0 0 !important;
}
.dashboarder
  .product-box2
  .media-body
  .product-box2-cart
  li
  .action-buttons-red {
  background-color: #ed5e3e !important;
  color: #fffefe !important;
}
.dashboarder
  .product-box2
  .media-body
  .product-box2-cart
  li
  .action-buttons-mint {
  background-color: #7a929e !important;
  color: #fffefe !important;
}
.dashboarder
  .product-box2
  .media-body
  .product-box2-cart
  li
  .action-buttons-yellow {
  background-color: #ffab00 !important;
  /* color: #fffefe !important; */
}

.react-custom-modal-mobile{
    background-color: #fff;
    border-radius: 12px;
    padding: 19px;
    position: fixed;
    top: 3%;
    right: 3%;
    left: 3%;
    width: auto;
    margin: 0;
    /* bottom: 38%; */
}
.react-custom-modal {
  position: absolute;
  background-color: #fff;
  border-radius: 12px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000000;
  padding-left: 60px;
  width: 75%;
  /* height: 50%; */
  min-width: 750px;
  min-height:230px;
  /* height: 535px; */
}

.react-custom-youtube-modal {
  position: absolute;
  background-color: rgba(22, 22, 49, 0.7);
  /* border-radius: 12px; */
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1000000;
  /* padding-left: 60px; */
  width: 75%;
  height: 75%;
  /* height: 50%; */
  /* min-width: 750px; */
  /* min-height:230px; */
  /* height: 400px; */
}
/* @media only screen and (max-width: 479px) {
  .react-custom-modal {
    width: 85% !important;
  }
}
@media only screen and (max-width: 767px) {
  .react-custom-modal {
    width: 80% !important;
  }
}
@media only screen and (max-width: 991px) {
  .react-custom-modal {
    width: 60% !important;
  }
} */

.react-custom-overlay {
  z-index: 999999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(22, 22, 49, 0.7);
  /* opacity: .7; */
}
.signup-banner {
  height: 535px;
  width: 276px;
  border-radius: 13px;
}
.login-registration-field input {
  margin-bottom: 20px !important;
}
.display-inline-flex {
  display: inline-flex;
}
.w50 {
  width: 50%;
}
.watch-video{
  margin-top: 55px;
  margin-right: 10px;
  color: #337ab7;
  margin-bottom: 0;
  font-size: 19px;
  color: #e41e44 !important;
}
.cursor{
  cursor: pointer;
}
.dont-leave{
  color: red;
  text-decoration: underline;
}
.btn-add-cart{
  line-height: 42px;
  text-align: center;
  height: 42px;
  padding: 0 20px;
  background: transparent;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  transition: all .5s ease-out;
  background: #4154f1;
  color: #fff;
  border: 1px solid #4154f1;
  transition: all .3s ease-out;
  border-radius: 12px;
}
/* .btn-youtube-video{
  font-size: 41px;
  color: red;
  position: absolute;
  left: 190
}
.btn-youtube-video:hover{
  font-size: 41px;
  color: red;
  position: absolute;
  left: 190
} */
.content-header-height{
  min-height: 180px !important;
}

.youtube-wrapper{
  background: rgba(0,0,0,0.55);
  width: 46px;
  height: 27px;
  border-radius: 5px;
  padding: 0px 20px;
  position: absolute;
  /* margin: 0 auto; */
  /* top: 50%; */
  transform: translateY(-50%);
  left: 63%;
  margin-top: 51px;
  cursor: pointer;
}
.youtube-wrapper:hover{
  background: #cd201f;
}
.youtube-icon{
  width: 0;
  /* height: 0; */
  border-style: solid;
  border-width: 7px 0 7px 14px;
  border-color: transparent transparent transparent #ffffff;
}
.footer-area-catgegory{
  background-color: #ddd !important;
}
.post-ad-heading{
  font-size: 60px;
  letter-spacing: 2px;
  color: #212121;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 1.3;
}
.mb-40{
  margin-bottom: 40px;
}